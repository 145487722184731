import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { DocumentFormHeader } from '../models/document-form-header';

@Injectable({
  providedIn: 'root'
})
export class EvoDocumentFormHeaderService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<DocumentFormHeader[]>{
    return this.httpClient.get<DocumentFormHeader[]>(this.baseApiUrl+'GetDocumentFormHeaderList');
  }
  get (id: number):Observable <DocumentFormHeader> {
    return this.httpClient.get<DocumentFormHeader>(this.baseApiUrl+'GetDocumentFormHeader/'+id);
  }
  getListByFormId (id: number):Observable <DocumentFormHeader> {
    return this.httpClient.get<DocumentFormHeader>(this.baseApiUrl+'GetDocumentFormHeadersByFormId/'+id);
  }
  add(department:DocumentFormHeader):Observable<DocumentFormHeader>{
    return this.httpClient.post<DocumentFormHeader>(this.baseApiUrl+'AddDocumentFormHeader/',department);
  }
  addInBulk(department:DocumentFormHeader[]):Observable<DocumentFormHeader[]>{
    return this.httpClient.post<DocumentFormHeader[]>(this.baseApiUrl+'AddDocumentFormHeaderInBulk/',department);
  }
  update(department:DocumentFormHeader):Observable<DocumentFormHeader>{
    return this.httpClient.post<DocumentFormHeader>(this.baseApiUrl+"UpdateDocumentFormHeader/",department);
  }
  delete(department:DocumentFormHeader):Observable<DocumentFormHeader>{
    return this.httpClient.post<DocumentFormHeader>(this.baseApiUrl+"DeleteDocumentFormHeader/",department);
  }
  
  getHeaderNames(id:number):Observable<DocumentFormHeader>{
    return this.httpClient.get<DocumentFormHeader>(this.baseApiUrl+"GetDocumentFormHeaderNamesByFormId/"+id);
  }
}
