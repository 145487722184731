import { DocumentFormRevisionDetails } from "./document-form-revision-details";

export class DocumentFormRevisionRequest{
    id:number=0;
    documentFormId:number=0;
    revisionCause:string|null=null;
    approvalCause:string|null=null;
    approvalStatus:number=0;
    approverId:number=null;
    approvalDate:Date=null;
    revisionDetails:DocumentFormRevisionDetails[]=[];
    createdDate:Date=null;
    approverName:string=null;
    documentName:string=null;
}