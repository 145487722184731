<div class="container-new">
    <div *ngIf="loading" class="spinner-overlay">
      <div class="spinner-container">
        <p-progressSpinner strokeWidth="4"></p-progressSpinner>
      </div>
    </div>
  
    <div class="content-wrapper container-xxl p-0">
        <div class="content-body">
          <section id="ngx-datatable-kitchen-sink">
            <core-card-snippet>
              <h4 class="card-title">Akış Listesi</h4>
    
              <div class="row">
                <div class="col-12 mb-1 ml-1">
                    <button class="btn btn-primary btn-sm ml-1 mr-1"  rippleEffect
                    title="Yeni Aktivite" data-bs-toggle="modal" data-bs-target="#addactivity" (click)="reset()">
                    <i data-feather="plus" class="mr-0"></i><span class="d-none d-sm-inline-block"> Yeni Akış Ekle</span>
                    </button>
                </div>
              </div>
    
                <div class="mt-2 mb-2 ml-2">
                    Yıl: <span class="ml-1">
                        <p-calendar
                        
                        [(ngModel)]="selectedYear"
                        view="year" 
                        dateFormat="yy"
                        (ngModelChange)="getYearlyData();initializeDates(selectedYear,selectedMonth)"></p-calendar>
    
                    </span>
                    <span class="ml-2" *ngIf="userRole=='Admin'">
                        <p-multiSelect 
                        [options]="consultantActivityFilters" 
                        [(ngModel)]="selectedConsultantActivityFilter"
                        (ngModelChange)="populateUserFilters();filterData('activity')"
                        placeholder="Danışman Aktiflik Durumu Seç"
                        optionLabel="label"
                        [maxSelectedLabels]="2" 
                        [selectedItemsLabel]="'...'" 
                        display="chip" 
                        [showClear]="false"></p-multiSelect>                
                    </span>
    
                    <span class="ml-2" *ngIf="userRole=='Admin'">
                        <p-multiSelect 
                        [options]="consultantTypeFilters" 
                        [(ngModel)]="selectedConsultantTypeFilter"
                        (ngModelChange)="filterUserType();filterData('activity')"
                        placeholder="Danışman Tipini Seç"
                        optionLabel="label"
                        [maxSelectedLabels]="2" 
                        [selectedItemsLabel]="'...'" 
                        display="chip" 
                        [showClear]="false"></p-multiSelect>                
                    </span>

                    <span class="ml-2" *ngIf="userRole=='Admin'">
                        <p-multiSelect 
                        [options]="consultantFilters" 
                        [(ngModel)]="selectedConsultantFilter"
                        (ngModelChange)="filterData('consultant');handleSelectionChange($event);"
                        placeholder="Danışman Seç"
                        optionLabel="label"
                        [maxSelectedLabels]="maxSelectedLabels" [selectedItemsLabel]="'...'" 
                        [display]="selectedConsultantFilter.length > maxSelectedLabels ? 'comma' : 'chip'"
                        [showClear]="false"></p-multiSelect>                
                    </span>
                </div>
    
                <div class="months" style="text-align:center;">
                    <ng-container *ngFor="let month of months; let i = index">
                        <span (click)="selectMonth(month.index)" [class.selected]="month.index === selectedMonth">
                            {{ month.name }}
                        </span>
                        <span *ngIf="i < months.length - 1">|</span>
                    </ng-container>            
                </div>
    
                <div class="ml-2 mr-2">
                    <p-tabView #tabView [scrollable]="true" [(activeIndex)]="activeIndex" (onChange)="selectDate()">
                        <p-tabPanel *ngFor="let date of dates; let i = index">
                            <ng-template pTemplate="header">
                                <i [ngClass]="getIconClass(i)"></i>
                                {{ date | date: 'dd.MM.yyyy' }}
                            </ng-template>

                            <div class="mb-2" *ngIf="userRole=='Admin'">
                                <p-dropdown [options]="allRowCounts" 
                                [(ngModel)]="pageSize" 
                                optionValue="id"
                                optionLabel="label" 
                                >
                                </p-dropdown>               
                            </div>

                            <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
                                <thead>
                                    <tr>
                                        <td>Danışman Adı</td>
                                        <td>Aktivite Tipi</td>
                                        <td>Çalışma Tipi</td>
                                        <td>Aktivite Tarihi</td>
                                        <td>Aktivite Süresi</td>
                                        <td>Aktivite Açıklaması</td>
                                        <td>Modül Adı</td>
                                        <td>Cari Adı</td>
                                        <td>Proje Adı</td>
                                        <td>Talep Takip Numarası</td>
                                        <td>Faturalama Bilgisi</td>
                                        <td>Kullanıcı Aktiflik Durumu</td>
    
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of activityInquiryList">
                                        <td>{{item.userName}}</td>
                                        <td>{{item.activityTypeName}}</td>
                                        <td>{{item.locationTypeName}}</td>
                                        <td [innerHTML]="item.activityDate | date:'dd.MM.yyyy HH:mm:ss'"></td>
                                        <td>{{item.activityHours}}</td>
                                        <td>{{item.description}}</td>
                                        <td>{{item.moduleName}}</td>
                                        <td>{{item.partnerName}}</td>
                                        <td>{{item.projectName}}</td>
                                        <td>{{item.ticketId}}</td>
                                        <td>{{item.billTypeName}}</td>
                                        <td>{{item.isActiveUser}}</td>
    
                                    </tr>
                                </tbody>
                            </table>
                            <ngx-datatable [rows]="consultantFilteredInquiryList | paginate: { id:'mainPagination' ,itemsPerPage: pageSize, currentPage: currentPage, totalItems: mainListSize }" [rowHeight]="58"
                                class="bootstrap core-bootstrap" [limit]="10" [headerHeight]="40" [footerHeight]="50"
                                [scrollbarH]="true" [limit]="basicSelectedOption" [virtualScroll]="true" 
                                >
                                <ngx-datatable-column name="Danışman" prop="userName" [width]="200">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.userName}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column *ngIf="userRole=='Admin'" name="D. Aktiflik" prop="isActiveUser" [width]="200">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.isActiveUser}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Aktivite Tipi" prop="activityTypeName" [width]="200">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.activityTypeName}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Çalışma Tipi" prop="locationTypeName" [width]="200">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.locationTypeName}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Tarih" prop="activityDate" [width]="200">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.activityDate | date:'dd.MM.yyyy'}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Süre" prop="activityHours" [width]="200">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.activityHours}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Açıklama" prop="description" [width]="200">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        <div 
                                        class="description-container" 
                                        (mouseenter)="onMouseEnter($event, row.description)" 
                                        (mouseleave)="onMouseLeave()"
                                        (mousemove)="onMouseMove($event)">
                                        
                                        <div class="description-text" [innerHTML]="row.description"></div>
                                        </div> 
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Modül" prop="moduleName" [width]="200">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.moduleName}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Partner" prop="partnerName" [width]="200">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.partnerName}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Proje" prop="projectName" [width]="200">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.projectName}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Takip No" prop="ticketId" [width]="200">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        <div *ngIf="row.ticketId !=null">
                                            <a class="badge badge-pill badge-light-info font-small-3 font-weight-bold line-height-2 mb-25"
                                            [routerLink]="'/home/details/' + row.ticketId" target="_blank">#00000{{ row.ticketId }}</a>
    
                                        </div>
                                        <div *ngIf="row.ticketId==null">
                                            Girilmemiş
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Faturalama" prop="billTypeName" [width]="200">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        <div class="d-flex align-items-center">
                                            <div class="badge badge-pill badge-light-danger mr-1"
                                                *ngIf="row.billTypeId==4">Faturalandırılmadı</div>
                                            <div class="badge badge-pill badge-light-primary mr-1"
                                                *ngIf="row.billTypeId==5">Faturalandı</div>
                                            <div class="badge badge-pill badge-light-secondary mr-1"
                                                *ngIf="row.billTypeId==6">Feragat Edildi</div>
                                        </div>                                
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        <div class="d-flex align-items-center">
                                            <div ngbDropdown container="body">
                                                <div *ngIf="row.activityTypeName != 'İzin'">
                                                    <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                                    id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                                                    </a>
                                                    
                                                    <div *ngIf="row.ticketId==null && row.activityTypeName != 'İzin'">
        
                                                        <div ngbDropdownMenu class="dropdown-menu-right"
                                                        aria-labelledby="dropdownBrowserState">
                                                            <button ngbDropdownItem (click)="get(row.id)"
                                                                data-bs-toggle="modal" data-bs-target="#addactivity">
                                                                <i data-feather="edit-2" class="mr-50"></i><span>Güncelle</span>
                                                            </button>                                                
                                                            <button ngbDropdownItem (click)="delete(row.id)">
                                                                <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="row.ticketId!=null && row.activityTypeName=='Destek'">
                                                            <div ngbDropdownMenu class="dropdown-menu-right"
                                                            aria-labelledby="dropdownBrowserState">
                                                                <button ngbDropdownItem [routerLink]="'/home/details/'+ row.ticketId">
                                                                <i data-feather="edit-2" class="mr-50"></i><span>Talebi Aç</span>
                                                                </button> 
                                                            </div>
                                                    </div>                                                
                                                
                                                </div>

                                            </div>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-footer>
                                    <ng-template ngx-datatable-footer-template>
                              
                                      <div class="d-flex justify-content-center">
                                        {{mainListSize }} aktivitenin {{ (currentPage - 1) * pageSize + 1 }} - {{ currentPage * pageSize }} aralığı gösteriliyor. 
    
                                        <pagination-controls
                                            id = "mainPagination"
    
                                            [directionLinks]="true" 
                                            previousLabel="" 
                                            nextLabel="" 
                                            (pageChange)="pageChange($event)">
                                        </pagination-controls>
                                      </div>
                                    </ng-template>
                                </ngx-datatable-footer>
                            </ngx-datatable>
                            <div style="display: inline-block;">
    
                                <h4 class="mb-2">Akış Özeti</h4>
                                <ngx-datatable
                                class="bootstrap core-bootstrap"                                    
                                [rows]="summaryRows  | paginate: { id:'summaryPagination',itemsPerPage: pageSize, currentPage : currentPageSummary, totalItems: summaryListSize }"
                                [headerHeight]="40"
                                [footerHeight]="50"
                                [rowHeight]="50">
    
                                <ngx-datatable-column name="Danışman Adı" prop="userName" [width]="200">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.userName}}
                                    </ng-template>
                                </ngx-datatable-column>
    
                                <ngx-datatable-column name="Aktivite Tipi" prop="activityTypeName" [width]="200">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.activityTypeName}}
                                    </ng-template>
                                </ngx-datatable-column>
    
                                <ngx-datatable-column name="Toplam Süre" prop="totalHours" [width]="200">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.totalHours}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-footer>
                                    <ng-template ngx-datatable-footer-template>
                              
                                      <div class="d-flex justify-content-center">
                                        {{summaryListSize }} özetin {{ (currentPageSummary - 1) * pageSize + 1 }} - {{ currentPageSummary * pageSize }} aralığı gösteriliyor. 
    
                                        <pagination-controls
                                            id = "summaryPagination"
                                            [directionLinks]="true" 
                                            previousLabel="" 
                                            nextLabel="" 
                                            (pageChange)="pageChangeSummary($event)">
                                        </pagination-controls>
                                      </div>
                                    </ng-template>
                                </ngx-datatable-footer>
    
                              </ngx-datatable>
    
                            </div>
                        </p-tabPanel>
                    </p-tabView>
                </div>
    
              
            </core-card-snippet>
          </section>
        </div>
      </div>


</div>





  <div class="modal fade" id="addactivity" tabindex="-1" aria-labelledby="staticBackdropLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Aktivite Ekle</h1>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
            class="mr-50"></i>Çıkış</button>
      </div>
      <div class="modal-body">
        <form class="form form-vertical" #activityInquiryForm="ngForm">
            <div class="row">
              
                <div class="col-12">
                    <div *ngIf="userRole=='Admin'">
                            <div class="form-group">
                                <label for="usrSelect">Kullanıcı Seç</label>
                                <select class="form-control" ngModel required name="slcUser" [(ngModel)]="activityInquiry.userId"
                                #slcUser="ngModel">
                                <option *ngFor="let item of users" value="{{item.id}}">{{item.firstName}} {{item.lastName}}</option>
                                </select>
                                <small class="text-danger" *ngIf="slcUser.invalid&& slcUser.touched">Bir Kullanıcı
                                seçmeniz gerekiyor!</small>
                            </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group">
                        <label for="activityTypeSelect">Aktivite Tipini Seçin</label>
                        <select class="form-control" ngModel required name="slcActivityType" [(ngModel)]="activityInquiry.activityTypeId"
                            #slcActivityType="ngModel">
                            <option *ngFor="let item of activityTypes" value="{{item.id}}">{{item.name}}</option>
                        </select>
                        <small class="text-danger" *ngIf="slcActivityType.invalid&& slcActivityType.touched">Bir Aktivite Tipi
                            seçmeniz gerekiyor!</small>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group">
                        <label for="locationTypeSelect">Çalışma Tipini Seçin</label>
                        <select class="form-control" ngModel required name="slcLocationType" [(ngModel)]="activityInquiry.locationTypeId"
                            #slcLocationType="ngModel">
                            <option *ngFor="let item of locationTypes" value="{{item.id}}">{{item.name}}</option>
                        </select>
                        <small class="text-danger" *ngIf="slcLocationType.invalid&& slcLocationType.touched">Bir Çalışma Tipi
                            seçmeniz gerekiyor!</small>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group">
                      <label for="subject-vertical">Aktivite Tarihi Seçin</label>
                      <input type="date" ngModel id="txtActivityDate" class="form-control" name="txtActivityDate"
                        #txtActivityDate="ngModel" [(ngModel)]="activityInquiry.activityDate" required/>
                      <small class="text-danger" *ngIf="txtActivityDate.invalid&& txtActivityDate.touched">Bir Aktivite Tarihi
                        seçmeniz gerekiyor!</small>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                      <label for="subject-vertical">Aktivite Süresi Girin (Saat,  1 gün = 8 saat )</label>
                      <input type="number" ngModel id="txtActivityHours" class="form-control" name="txtActivityHours"
                        #txtActivityHours="ngModel" [(ngModel)]="activityInquiry.activityHours" required/>
                      <small class="text-danger" *ngIf="txtActivityHours.invalid&& txtActivityHours.touched">Aktivite Süresi
                        girmelisiniz!</small>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group">
                      <label for="subject-vertical">Aktivite Açıklaması Girin (En az 30 karakter)</label>
                      <textarea minlength="30" ngModel id="txtActivityDescription" class="form-control" name="txtActivityDescription"
                      #txtActivityDescription="ngModel" [(ngModel)]="activityInquiry.description" required rows="4"></textarea>
                      <small class="text-danger" *ngIf="txtActivityDescription.invalid&& txtActivityDescription.touched">Aktivite Açıklaması
                        girmelisiniz!</small>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group">
                    <div class="row">
                        <div class="col-12 mr-0 ">
                        <label for="moduleSelect">Modül Seçiniz</label>
                        <select class="form-control" required name="slcModule" [(ngModel)]="activityInquiry.moduleId"
                            #slcModule="ngModel">
                            <option *ngFor="let item of modules" value="{{item.id}}">{{item.name}}</option>
                        </select>
                        <small class="text-danger" *ngIf="slcModule.invalid && slcModule.touched">Bir Modül
                            seçmeniz gerekiyor!</small>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group">
                        <label for="name-vertical">Cari Seçiniz</label>
                        <select class="form-control" required name="slcPartner" [(ngModel)]="activityInquiry.partnerId"
                            #slcPartner="ngModel" (ngModelChange)="changePartner()">
                            <option *ngFor="let item of partners" value="{{item.id}}">{{item.name}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-12 mr-0 ">
                                <label for="departmentSelect">Proje Seçiniz</label>
                                <select class="form-control" required name="slcProject" [(ngModel)]="activityInquiry.projectId"
                                    #slcProject="ngModel">
                                    <option *ngFor="let item of projects" value="{{item.id}}">
                                    {{item.title}}
                                    {{item.partnerName}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

              <div class="col-12" style="margin-top:90px; ">
                <button type="button" (click)="processPending=true;saveInquiry()" rippleEffect class="btn btn-success mr-1 cursor-not-allowed"
                  [disabled]="!activityInquiryForm.valid || processPending==true">Kaydet</button>
              </div>
            </div>
          </form>      
        </div>
    </div>
  </div>
</div>

<div *ngIf="isOverlayVisible" 
     class="description-overlay" 
     [innerHTML]="overlayContent"
     [ngStyle]="{ top: overlayPosition.top + 'px', left: overlayPosition.left + 'px' }">
</div>