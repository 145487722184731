import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UserServerAccessPastTableList } from '../models/user-server-access-past-table-list';
import { UserServerAccessTable } from '../models/user-server-access-table';
import { UserServerAccessTableList } from '../models/user-server-access-table-list';

@Injectable({
  providedIn: 'root'
})
export class EvoUserServerAccessTableService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<UserServerAccessTableList[]>{
    return this.httpClient.get<UserServerAccessTableList[]>(this.baseApiUrl+'GetUserServerAccessTableList');
  }

  getPastList():Observable<UserServerAccessPastTableList[]>{
    return this.httpClient.get<UserServerAccessPastTableList[]>(this.baseApiUrl+'GetUserServerAccessPastTableList');
  }

  get (id: number):Observable <UserServerAccessTable> {
    return this.httpClient.get<UserServerAccessTable>(this.baseApiUrl+'GetUserServerAccessTable/'+id);
  }
  add(department:UserServerAccessTable):Observable<UserServerAccessTable>{
    return this.httpClient.post<UserServerAccessTable>(this.baseApiUrl+'AddUserServerAccessTable/',department);
  }
  update(department:UserServerAccessTable):Observable<UserServerAccessTable>{
    return this.httpClient.post<UserServerAccessTable>(this.baseApiUrl+"UpdateUserServerAccessTable/",department);
  }
  delete(department:UserServerAccessTable):Observable<UserServerAccessTable>{
    return this.httpClient.post<UserServerAccessTable>(this.baseApiUrl+"DeleteUserServerAccessTable",department);
  }
}
