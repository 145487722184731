import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { DocumentForm } from 'app/evo/models/document-form';
import { DocumentFormApprovalOperation } from 'app/evo/models/document-form-approval-operation';
import { DocumentFormApprovalOperationList } from 'app/evo/models/document-form-approval-operation-list';
import { DocumentFormHeader } from 'app/evo/models/document-form-header';
import { DocumentFormRevisionChanges } from 'app/evo/models/document-form-revision-changes';
import { DocumentFormRevisionDetails } from 'app/evo/models/document-form-revision-details';
import { DocumentFormRevisionRequest } from 'app/evo/models/document-form-revision-request';
import { PastDocumentFormApprovalList } from 'app/evo/models/past-document-form-approval-list';
import { User } from 'app/evo/models/user';

import { FilterPipe } from 'app/evo/pipe/filter.pipe';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoDocumentFormApprovalOperationsService } from 'app/evo/services/evo-document-form-approval-operations.service';
import { EvoDocumentFormHeaderItemService } from 'app/evo/services/evo-document-form-header-items.service';
import { EvoDocumentFormHeaderService } from 'app/evo/services/evo-document-form-header.service';
import { EvoDocumentFormRevisionRequestService } from 'app/evo/services/evo-document-form-revision.service';
import { EvoDocumentFormService } from 'app/evo/services/evo-document-form.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';

declare var window: any;
declare var bootstrap:any;
const themes = {
    light: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css',
    dark: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-dark-blue/theme.css'
  };

@Component({
  selector: 'app-evo-document-form-approval-list',
  templateUrl: './evo-document-form-approval-list.component.html',
  styleUrls: ['./evo-document-form-approval-list.component.scss'
  ],
  providers:[FilterPipe]
})
export class EvoDocumentFormApprovalListComponent implements OnInit,AfterViewInit,OnDestroy {

  constructor(
    private documentFormService:EvoDocumentFormService,
    private documentFormApprovalOperationsService:EvoDocumentFormApprovalOperationsService,
    private documentFormHeaderService:EvoDocumentFormHeaderService,
    private documentFormHeaderItemService:EvoDocumentFormHeaderItemService,
    private userService:EvoUserService,
    private transactionService: EvoTransactionService,
    private errorService: ErrorService,
    private informationService: InformationService,
    private helperService: HelperService,
    private decodeService: DecodeService,
    private router: Router,
    public formatter: NgbDateParserFormatter,
    private cd:ChangeDetectorRef,
    private filterPipe: FilterPipe) { }

  public basicSelectedOption: number = 10;
  myId:number;

  filterText: string = "";
  deletedPlanId: number;

  documentFormHeaders:DocumentFormHeader[]=[];
  documentForms: DocumentForm[] =[];
  filteredDocumentForms:DocumentForm[]=[];


  selectedDocumentFormId:number=0;

  private themeLink: HTMLLinkElement;
  private primengLink: HTMLLinkElement;
  private primeiconsLink: HTMLLinkElement;

  backgroundColor:string="default";

  documentFormHeaderModalVisible:boolean=false;
  documentFormApprovalOperation:DocumentFormApprovalOperation = new DocumentFormApprovalOperation();

  documentFormApprovalOperationList: DocumentFormApprovalOperationList[] = [];
  filteredDocumentFormApprovalOperationList: DocumentFormApprovalOperationList[] = [];

  revisionCause:string=null;
  userRole:string="";

  overlayPosition = { top: 0, left: 0 };
  overlayContent: string = '';
  isOverlayVisible: boolean = false;

  headerList:string[]=[];
  headerData:any[]=[];
  filteredHeaderData:any[]=[];
  inputData:any[][]=[];
  initialInputData:any[][]=[];
  pastDocumentForms:PastDocumentFormApprovalList[]=[];

  approvalTypeFilters:any[]=[
    {id:1,name:'Onay Bekliyor'},
    {id:2,name:'Onaylandı'},
    {id:3,name:'Reddedildi'}
  ];

  selectedApprovalTypes:any[]=[];
  users:User[]=[];
  selectedUsers:any[]=[];

  

  approvalStatus:number=0;
  ngOnInit(): void {
    this.loadPrimeNGStyles();
    this.applyThemeChanges();
    this.listenForThemeChanges();

    let item = this.decodeService.getRole();
    this.userRole=item;
    this.myId = this.decodeService.getUserId();
    if (item != "Admin") {
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(0, 11, 5, this.myId).subscribe((res: any) => {
      });
    }
    else {
      this.list()
      this.requiredList();
    }
  }

  list() {
    this.documentFormApprovalOperationsService.getList().subscribe((res:any)=>{
      this.documentFormApprovalOperationList = res.data;
      this.tableByFiltersId();
      

    })
  }

  get(id:number){
    this.documentFormApprovalOperationsService.get(id).subscribe((res:any)=>{
      this.documentFormApprovalOperation = res.data;
      this.documentFormApprovalOperation.approvalStatus = this.approvalStatus;

    })
  }

  requiredList() {
    this.documentFormService.getPastList().subscribe(
      (items: any) => {
        // Replace <br> in ApprovalCause for each item in the array
        this.pastDocumentForms = items.data.map((item: any) => {
          item.approvalCause = item.approvalCause.replace(/<br>/g, ' '); // Replace all occurrences of <br>
          return item;
        });
  
        // Notify the user about the list retrieval
        this.informationService.list(items.message);
      },
      (err) => {
        // Handle errors
        this.errorService.errorHandler(err);
      }
    );

    this.userService.getList().subscribe((res: any) => {
      this.users = res.data.map(item => {
        return {
          ...item,  // Spread operator to include all existing properties of the item
          fullName: item.firstName + " " + item.lastName // Adding the fullName property
        };
      });    
    })
  }


  getExcelInfo(id:number){
    let element = document.getElementById("excel-table-2");
    this.documentFormService.get(id).subscribe((res:any)=>{
        this.documentFormHeaderItemService.getGroupedListByFormId(id).subscribe((res2:any)=>{
            this.documentFormHeaderService.getHeaderNames(id).subscribe((res3:any)=>{
                var documentFormInfo = res.data;
                this.headerList = res3.data;
                this.headerData = res2.data;
                this.inputData = this.headerData.map(group => [...group.valueDatas]);
                this.initialInputData = {...this.inputData};

                this.cd.detectChanges();
                console.log(this.headerData);
                setTimeout(()=>{
                    let title = documentFormInfo.documentNo + " " + documentFormInfo.documentName + " Doküman Raporu";
                    this.helperService.exportExcelForBGYS(element, title,this.headerList,documentFormInfo);
    
                },250)
    
            },(err:any)=>{},
            ()=>{

            })
    
          },(err:any)=>{})
    })


  }


  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    this.unloadPrimeNGStyles();
  }

  changeApprovalStatus(){
    
    this.documentFormApprovalOperation.approvalCause = this.documentFormApprovalOperation.approvalCause.replace(/\n/g, '<br>');
    this.documentFormService.changeDocumentFormApprovalStatus(this.documentFormApprovalOperation).subscribe((res:any)=>{
        this.informationService.add("Onay Durumu Değiştirildi.");
    },(err:any)=>{
        this.errorService.errorHandler(err)
    },()=>{
        setTimeout(()=>{
            this.list();
        },200);
    })
  }

  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Doküman Formu Geçmiş Onay Listesi";
    this.helperService.exportExcel(element, title);
  }

  tableByFiltersId(){
    let filteredItems = this.documentFormApprovalOperationList;

    if (this.filterText) {
      filteredItems = this.filterPipe.transform(filteredItems, this.filterText);
    }

    if (this.selectedUsers.length > 0) {
      filteredItems = filteredItems.filter(item => this.selectedUsers.includes(item.userId));
    }

    if (this.selectedApprovalTypes.length > 0) {
      filteredItems = filteredItems.filter(item => this.selectedApprovalTypes.includes(item.approvalStatus));
    }

    this.filteredDocumentFormApprovalOperationList = filteredItems;

  }


  loadPrimeNGStyles() {
    const head = document.getElementsByTagName('head')[0];

    // PrimeNG theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css';
    head.appendChild(this.themeLink);

    // PrimeNG core styles
    this.primengLink = document.createElement('link');
    this.primengLink.rel = 'stylesheet';
    this.primengLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/primeng.min.css';
    head.appendChild(this.primengLink);

    // PrimeIcons
    this.primeiconsLink = document.createElement('link');
    this.primeiconsLink.rel = 'stylesheet';
    this.primeiconsLink.href = 'https://cdn.jsdelivr.net/npm/primeicons/primeicons.css';
    head.appendChild(this.primeiconsLink);
  }

  unloadPrimeNGStyles() {
    if (this.themeLink) {
      this.themeLink.remove();
    }
    if (this.primengLink) {
      this.primengLink.remove();
    }
    if (this.primeiconsLink) {
      this.primeiconsLink.remove();
    }
  }

  applyThemeChanges() {
    const backgroundColor = localStorage.getItem("backgroundColor");
  
    // If a theme is already applied, remove it first
    if (this.themeLink) {
      this.themeLink.remove();
    }
  
    // Create a new link element and load the appropriate theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = backgroundColor === 'dark' ? themes.dark : themes.light;
    document.head.appendChild(this.themeLink);
  }

  listenForThemeChanges() {
    window.addEventListener('localStorageChange', () => {
      const backgroundColor = localStorage.getItem('backgroundColor');
      console.log('Background color changed:', backgroundColor);
      this.backgroundColor = backgroundColor;
      // Trigger Angular's change detection to update the view
      this.cd.detectChanges();
      this.applyThemeChanges(); // Apply the new theme instantly
    });
  }

  onMouseEnter(event: MouseEvent, description: string): void {
    if(description==null){
        description="Onay mesajı girilmemiş";
    }
    this.overlayContent = description; // Set the overlay content

    // Update the position of the overlay based on mouse coordinates
    this.updateOverlayPosition(event);
    this.isOverlayVisible = true;
  }

  // Update the overlay position relative to the mouse position
  onMouseMove(event: MouseEvent): void {
    if (this.isOverlayVisible) {
      this.updateOverlayPosition(event);
    }
  }

  // Update the position of the overlay based on mouse coordinates
  updateOverlayPosition(event: MouseEvent): void {

    const isMenuCollapsed = localStorage.getItem("sidebarCollapsed");

    var mouseX = event.clientX;
    var mouseY = event.clientY;

    if(isMenuCollapsed == "true"){
        mouseX = event.clientX-50;
  
    }
    else{
        mouseX = event.clientX-200;

    }    
    

    this.overlayPosition.left = mouseX + window.scrollX;
    this.overlayPosition.top = mouseY + window.scrollY;
    console.log(this.overlayPosition);
  }

  // Hide the overlay when mouse leaves the description part
  onMouseLeave(): void {
    this.isOverlayVisible = false;
  }



}
