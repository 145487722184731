<p-confirmDialog appendTo="body" [baseZIndex]="10000" #confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>

<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="ngx-datatable-kitchen-sink">
            <core-card-snippet>
                <h4 class="card-title">Revizyon Listesi</h4>
                <div class="row">
                    <div class="filter mt-1">

                    </div>
                </div>

                <p-table [value]="revisionRequestList" rowExpandMode="single" sortField="documentName" sortMode="single" dataKey="documentName" rowGroupMode="subheader" groupRowsBy="documentName">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Sebebi</th>
                            <th>İsteme Tarihi</th>
                            <th>Değişiklikler</th>
                            <th>Onaylama Durumu</th>
                            <th>Onaylayan Adı</th>
                            <th>Onaylama Tarihi</th>
                            <th>Onaylama Sebebi</th>
                            <th *ngIf="userRole=='Admin'">Onayla</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="groupheader" let-item let-rowIndex="rowIndex" let-expanded="expanded">
                      <tr>
                        <td colspan="8">
                            <div style="display: flex; align-items: center; justify-content: space-between;">
                              <div>
                                <button
                                  type="button"
                                  pButton
                                  pRipple
                                  [pRowToggler]="item"
                                  class="p-button-text p-button-rounded p-button-plain mr-2"
                                  [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                                ></button>
                                <img src="https://static.thenounproject.com/png/684885-200.png" width="32" style="vertical-align: middle" />
                                <span class="font-bold ml-2"><strong>{{ item.documentName }}</strong></span>
                              </div>
                              <div *ngIf="userRole=='Admin'">
                                <button (click)="approve(item,1);approvalType=2;" title="Onayla" data-bs-toggle="modal" data-bs-target="#approvalModal" class="btn btn-success btn-sm mr-1">
                                    <i class="pi pi-check"></i>
                                </button>                                          
                                <button (click)="approve(item,2);approvalType=2;" title="Reddet" data-bs-toggle="modal" data-bs-target="#approvalModal" class="btn btn-danger btn-sm">
                                    <i class="pi pi-times"></i>
                                </button>
                              </div>
                            </div>
                          </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-item>
                        <tr>
                            <td>
                                {{item.revisionCause}}
                            </td>
                            <td>
                                {{item.createdDate | date: 'dd.MM.yyyy HH:mm:ss'}}
                            </td>
                            <td>
                                <button (click)="getForm(item);" data-bs-toggle="modal" data-bs-target="#revisionChangeList" class="btn btn-info btn-sm mr-1">
                                    <i class="pi pi-file"></i>
                                </button>       
                            </td>
                            <td>
                                <div *ngIf="item.approvalStatus==0" style="color:gray;">
                                    Onaylanmamış
                                </div>
                                <div *ngIf="item.approvalStatus==1" style="color:green;">
                                    Onaylandı
                                </div>
                                <div *ngIf="item.approvalStatus==2" style="color:red">
                                    Reddedildi
                                </div>
                            </td>
                            <td>
                                {{item.approverName}}
                            </td>
                            <td>
                                {{item.approvalDate | date: 'dd.MM.yyyy HH:mm:ss'}}
                            </td>
                            <td>
                                {{item.approvalCause}}
                            </td>
                            <td *ngIf="userRole=='Admin'">
                                <div>
                                    <button (click)="approve(item,1);approvalType=1;" title="Onayla" data-bs-toggle="modal" data-bs-target="#approvalModal" class="btn btn-success btn-sm mr-1 mb-1">
                                        <i class="pi pi-check"></i>
                                    </button>                                          
                                    <button (click)="approve(item,2);approvalType=1;" title="Reddet" class="btn btn-danger btn-sm mb-1">
                                        <i class="pi pi-times"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </core-card-snippet>
        </section>
    </div>
</div>

<div class="modal fade" id="revisionChangeList" tabindex="-1" aria-labelledby="staticBackdropLabel"
aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Revizyon Değişiklikleri</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                    <div class="modal-body">
                        <div class="row">
                            <table class="table table-hover table-bordered" id="documentHeaderTable">
                                <thead>
                                    <th>Tablo Adı</th>
                                    <th *ngIf="userRole=='Admin'">Değişen Id</th>
                                    <th>Sütun Adı</th>
                                    <th>Operasyon Tipi</th>
                                    <th>Eski Değer</th>
                                    <th>Yeni Değer</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of selectedChangeList">
                                        <td>{{turkishColumnNames(selectedTable)}}</td>
                                        <td *ngIf="userRole=='Admin'">
                                            {{selectedRowId}}
                                        </td>
                                        <td>
                                            {{turkishColumnNames(item.affectedColumnName)}}
                                        </td>
                                        <td>
                                            {{turkishOperationNames(selectedOperationType)}}
                                        </td>
                                        <td>
                                            {{selectedOperationType != 'delete'? ((item.oldValue == null || item.oldValue=="null") ? "-" : (item.affectedColumnName == 'HeaderTypeId' ? getHeaderType(item.oldValue) : item.oldValue)):"-"}}                                        
                                        </td>
                                        <td>
                                            {{selectedOperationType != 'delete'? ((item.newValue == null || item.newValue=="null") ? "-" : (item.affectedColumnName == 'HeaderTypeId' ? getHeaderType(item.newValue) : item.newValue)):"-"}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade" id="approvalModal" tabindex="-1" aria-labelledby="staticBackdropLabel"
aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">{{revisionRequest.approvalStatus == 1 ? 'Onay' : 'Red'}} Sebebi Ekle</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
            </div>
            <form class="" #AddDocumentFormApprovalForm="ngForm">
            <div class="modal-body">
                <div class="form-group">
                    <div class="row mb-2">
                        <label for="name-vertical">{{revisionRequest.approvalStatus == 1 ? 'Onay' : 'Red'}} Sebebi</label>
                        <input type="text" [(ngModel)]="approvalCause" class="form-control" name="txtDocumentFormApprovalCause" required
                            #txtDocumentFormApprovalCause="ngModel" ngModel>
                        <small class="text-danger"
                            *ngIf="txtDocumentFormApprovalCause.invalid&& txtDocumentFormApprovalCause.touched">Onay sebebi boş olamaz.</small>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div>
                    <button type="button" rippleEffect class="btn btn-success mr-1" [disabled]="!AddDocumentFormApprovalForm.valid"
                    (click)="sendApproval()">Kaydet</button>
                </div>
            </div>   
            </form>     
        </div>
    </div>
</div>