import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { ActivityInquiryDate } from 'app/evo/models/activity-inquiry-date';
import { AnnualTerm } from 'app/evo/models/annualterm';
import { AnnualTermList } from 'app/evo/models/annualtermlist';
import { Competence } from 'app/evo/models/competence';
import { CompetenceModules } from 'app/evo/models/competencemodules';
import { Module } from 'app/evo/models/module';
import { User } from 'app/evo/models/user';
import { EvoAnnualTermService } from 'app/evo/services/annualterm.service';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoCompetenceService } from 'app/evo/services/evo-competence.service';
import { EvoModuleService } from 'app/evo/services/evo-module.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';

const themes = {
  light: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css',
  dark: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-dark-blue/theme.css'
};

@Component({
  selector: 'app-evo-annual-term-list',
  templateUrl: './evo-annual-term-list.component.html',
  styles: [
  ],
  providers:[ConfirmationService]
})
export class EvoAnnualTermListComponent implements OnInit,OnDestroy {

  constructor(
    private annualTermService:EvoAnnualTermService,
    private errorService: ErrorService,
    private helperService: HelperService,
    private informationService: InformationService,
    private decodeService: DecodeService,
    private confirmationService:ConfirmationService,
    private router:Router,
    private cd:ChangeDetectorRef,
  ) { }

  userRole: string = "";
  annualTermList:AnnualTermList[]=[];
  annualTerm:AnnualTerm = new AnnualTerm();

  selectedYear: Date = new Date();

  private themeLink: HTMLLinkElement;
  private primengLink: HTMLLinkElement;
  private primeiconsLink: HTMLLinkElement;
  backgroundColor:string="default";
  action:string="Ekle";

  ngOnDestroy(): void {
    this.unloadPrimeNGStyles();
  }

  ngOnInit(): void {
    this.loadPrimeNGStyles();
    this.applyThemeChanges();
    this.listenForThemeChanges();
    
    this.userRole = this.decodeService.getRole();

    if(this.userRole!='Admin'){
        this.informationService.warning("Bu sayfayı görmek için yetkiniz bulunmamaktadır.")
        this.router.navigate(['/home']);
      }

    this.list();

  }

  list() {
    this.annualTermService.getListDto().subscribe((res:any)=>{
        this.annualTermList = res.data;
        this.informationService.list("Dönem Listesi Başarıyla Getirildi.");

    })

  }


  get(id: number) {
    this.annualTermService.get(id).subscribe((res:any)=>{
        this.annualTerm = res.data;
    })
  }

  clear(){
    this.selectedYear = new Date();
  }

  addTerm(){
    var activityInquiry : ActivityInquiryDate = new ActivityInquiryDate();
    activityInquiry.activityDate = this.selectedYear;
    activityInquiry.activityDate.setHours(activityInquiry.activityDate.getHours() - activityInquiry.activityDate.getTimezoneOffset() / 60)

    this.annualTermService.addCompleteAnnualTerm(activityInquiry).subscribe((res:any)=>{
        this.informationService.add("Dönem eklendi.");
    },(err) => {
        this.errorService.errorHandler(err);
    },()=>{
        setTimeout(()=>{this.list()},150);
    });

  }

  updateTerm(item:any){
    this.annualTermService.update(item).subscribe((res:any)=>{
        this.informationService.update("Dönem aktifliği güncellendi.");

    },(err)=>{
        this.errorService.errorHandler(err);
    },()=>{
        setTimeout(()=>{this.list()},150);
    })
  }

  loadPrimeNGStyles() {
    const head = document.getElementsByTagName('head')[0];

    // PrimeNG theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css';
    head.appendChild(this.themeLink);

    // PrimeNG core styles
    this.primengLink = document.createElement('link');
    this.primengLink.rel = 'stylesheet';
    this.primengLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/primeng.min.css';
    head.appendChild(this.primengLink);

    // PrimeIcons
    this.primeiconsLink = document.createElement('link');
    this.primeiconsLink.rel = 'stylesheet';
    this.primeiconsLink.href = 'https://cdn.jsdelivr.net/npm/primeicons/primeicons.css';
    head.appendChild(this.primeiconsLink);
  }

  unloadPrimeNGStyles() {
    if (this.themeLink) {
      this.themeLink.remove();
    }
    if (this.primengLink) {
      this.primengLink.remove();
    }
    if (this.primeiconsLink) {
      this.primeiconsLink.remove();
    }
  }

  delete(id: number) {

    this.confirmationService.confirm({
      message: 'Bu yetkinliği silmek istediğinize emin misiniz?',
      header: 'Yetkinlik Silme',
      acceptButtonStyleClass: 'btn btn-success hide-duplicated-icon',
      rejectButtonStyleClass: 'btn btn-secondary mr-2',
      acceptLabel: 'Evet',
      rejectLabel: 'Hayır',
      accept: () => {

      },
      reject: (type: ConfirmEventType) => {
        switch(type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;      
        }
      }
    });

  }

  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Yetkinlikler";
    this.helperService.exportExcel(element, title);
  }


  applyThemeChanges() {
    const backgroundColor = localStorage.getItem("backgroundColor");
  
    // If a theme is already applied, remove it first
    if (this.themeLink) {
      this.themeLink.remove();
    }
  
    // Create a new link element and load the appropriate theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = backgroundColor === 'dark' ? themes.dark : themes.light;
    document.head.appendChild(this.themeLink);
}

listenForThemeChanges() {
    window.addEventListener('localStorageChange', () => {
      const backgroundColor = localStorage.getItem('backgroundColor');
      console.log('Background color changed:', backgroundColor);
      this.backgroundColor = backgroundColor;
      // Trigger Angular's change detection to update the view
      this.cd.detectChanges();
      this.applyThemeChanges(); // Apply the new theme instantly
    });
  }

}
