import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private toastr: ToastrService
  ) { }

  errorHandler(err: any){
    if (err.status == 400) {
      this.toastr.error(err.error);
    }
    else if(err.status == 0){
      this.toastr.error("Bağlantı hatası. Lütfen daha sonra tekrar deneyin");
    }
    else if(err.status == 405){
      this.toastr.error("Bilinmeyen hata!");
    }
    else if(err.status==500){
      this.toastr.error("Bu İşlemi Yapmak İçin Gereken Yetkiye Sahip Değilsiniz");
    }
    else if(err.status==409){
      this.toastr.error("Bu veri başka biri tarafından değiştirilmiş. Lütfen sayfayı yenileyin.");
    }
    else if(err==550){
      this.toastr.error("Mesajınız gönderilemedi. Lütfen boyutunu kontrol edip tekrar deneyiniz.");
    }
    else{
      this.toastr.error("İşlem Başarısız");
    }
  }
}
