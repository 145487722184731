import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { DocumentFormHeaderItemGroupedData } from '../models/document-form-grouped-item-data';
import { DocumentFormHeaderItem } from '../models/document-form-header-item';

@Injectable({
  providedIn: 'root'
})
export class EvoDocumentFormHeaderItemService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<DocumentFormHeaderItem[]>{
    return this.httpClient.get<DocumentFormHeaderItem[]>(this.baseApiUrl+'GetDocumentFormHeaderItemList');
  }
  get (id: number):Observable <DocumentFormHeaderItem> {
    return this.httpClient.get<DocumentFormHeaderItem>(this.baseApiUrl+'GetDocumentFormHeaderItem/'+id);
  }
  getListByFormId (id: number):Observable <DocumentFormHeaderItem> {
    return this.httpClient.get<DocumentFormHeaderItem>(this.baseApiUrl+'GetDocumentFormHeaderItemListByFormId/'+id);
  }

  getGroupedListByFormId (id: number):Observable <DocumentFormHeaderItem> {
    return this.httpClient.get<DocumentFormHeaderItem>(this.baseApiUrl+'GetGroupedDocumentFormHeaderItemList/'+id);
  }
  add(department:DocumentFormHeaderItem):Observable<DocumentFormHeaderItem>{
    return this.httpClient.post<DocumentFormHeaderItem>(this.baseApiUrl+'AddDocumentFormHeaderItem/',department);
  }

  addRow(formId:number, department:string[]):Observable<DocumentFormHeaderItem>{
    return this.httpClient.post<DocumentFormHeaderItem>(this.baseApiUrl+'AddDocumentFormHeaderItemRow/'+formId,department);
  }
  update(department:DocumentFormHeaderItem):Observable<DocumentFormHeaderItem>{
    return this.httpClient.post<DocumentFormHeaderItem>(this.baseApiUrl+"UpdateDocumentFormHeaderItem/",department);
  }
  updateRow(department:DocumentFormHeaderItemGroupedData):Observable<DocumentFormHeaderItem>{
    return this.httpClient.post<DocumentFormHeaderItem>(this.baseApiUrl+"UpdateDocumentFormHeaderItemRow",department);
  }
  delete(department:DocumentFormHeaderItem):Observable<DocumentFormHeaderItem>{
    return this.httpClient.post<DocumentFormHeaderItem>(this.baseApiUrl+"DeleteDocumentFormHeaderItem/",department);
  }

  deleteRow(formId:number,rowId:number,rowVersion:string[]):Observable<DocumentFormHeaderItem>{
    return this.httpClient.post<DocumentFormHeaderItem>(this.baseApiUrl+"DeleteDocumentFormHeaderItemRow/" + formId + "/" + rowId,rowVersion);
  }
}
