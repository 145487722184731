export class DocumentFormApprovalOperation{
    id:number=0;
    userId:number=0;
    documentFormId:number=0;
    rowId:number=0;
    oldFormValues:string[]=[];
    newFormValues: string[]=[];
    operationName:string="";
    approvalStatus:number=0;
    approvalCause:string;
    approvalDate:Date|null=null;
    approverId:number;
}