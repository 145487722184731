import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { EffortStatus } from 'app/evo/models/effortstatus';
import { Partner } from 'app/evo/models/partner';
import { TicketActivity } from 'app/evo/models/ticketactivity';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoTicketActivityService } from 'app/evo/services/evo-ticket-activity.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { EvoPlanService } from 'app/evo/services/evo-plan.service';
import { Plan } from 'app/evo/models/plan';
import { PlanType } from 'app/evo/models/plantype';
import { BillType } from 'app/evo/models/billtype';
import { LocationType } from 'app/evo/models/locationtype';
import { EvoModuleService } from 'app/evo/services/evo-module.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { Transaction } from 'app/evo/models/transaction';
import { DatePipe } from '@angular/common';
import { EvoContentService } from 'app/evo/services/evo-content.service';
import { Content } from 'app/evo/models/content';
import { Priority } from 'app/evo/models/priority';
import { TicketType } from 'app/evo/models/tickettype';
import { AnswerType } from 'app/evo/models/answertype';
import { Status } from 'app/evo/models/status';
import { EvoDepartmentService } from 'app/evo/services/evo-department.service';
import { Department } from 'app/evo/models/department';
import { EvoProjectService } from 'app/evo/services/evo-project.service';
import { EvoOperationClaimService } from 'app/evo/services/evo-operationclaim.service';
import { EvoPermissionService } from 'app/evo/services/evo-permission.service';
import { Project } from 'app/evo/models/project';
import { Module } from 'app/evo/models/module';
import { OperationClaim } from 'app/evo/models/operationclaim';
import { Permission } from 'app/evo/models/permission';
import { TicketUserType } from 'app/evo/models/ticketusertype';
import { AnimationDriver } from '@angular/animations/browser';
import { Ticket } from 'app/evo/models/ticket';
import { EvoTicketService } from 'app/evo/services/evo-ticket.service';
import { ProjectStatus } from 'app/evo/models/projectstatus';
import * as snippet from 'app/evo/components/layout/datatables.snippetcode';

@Component({
  selector: 'app-evo-transaction-list',
  templateUrl: './evo-transaction-list.component.html',
  styles: [
  ]
})
export class EvoTransactionListComponent implements OnInit {
  public today = this.calendar.getToday();
  public showReportBasic = true;
  public gridView = false;
  public _snippetCodeRowDetails = snippet.snippetCodeRowDetails;

  fromDate: Date;
  toDate: Date;
  fromDateForPlan: Date;
  toDateForPlan: Date;
  fromDateForTransaction: Date;
  toDateForTransaction: Date;

  public hoveredDate: NgbDate | null = null;
  public fromDateExample: NgbDate | null;
  public toDateExample: NgbDate | null;

  public hoveredDateForPlan: NgbDate | null = null;
  public fromDateExampleForPlan: NgbDate | null;
  public toDateExampleForPlan: NgbDate | null;

  public hoveredDateForTransaction: NgbDate | null = null;
  public fromDateExampleForTransaction: NgbDate | null;
  public toDateExampleForTransaction: NgbDate | null;

  public basicSelectedOption: number = 10;
  public basicSelectedOptionForPlans: number = 10;
  public basicSelectedOptionForTransaction: number = 10;

  constructor(
    private ticketActivityService: EvoTicketActivityService,
    private partnerService: EvoPartnerService,
    private userService: EvoUserService,
    private planService: EvoPlanService,
    private projectService: EvoProjectService,
    private operationcliamService: EvoOperationClaimService,
    private permissionService: EvoPermissionService,
    private ticketSerrvice: EvoTicketService,

    private moduleService: EvoModuleService,
    private transactionService: EvoTransactionService,
    private contentService: EvoContentService,

    private departmentService: EvoDepartmentService,
    private calendar: NgbCalendar,
    private router: Router,
    private informationService: InformationService,
    private errorService: ErrorService,
    private decodeService: DecodeService,
    private helperService: HelperService,
    public formatter: NgbDateParserFormatter
  ) { }

  departments: Department[] = [];
  projects: Project[] = [];
  operationClaims: OperationClaim[] = [];
  permissions: Permission[] = [];

  filterText: string = "";
  filterTexrForPlans: string = "";
  filterTextForTransaction: string = "";
  userRole: string;

  plans: Plan[];
  users: User[] = [];
  modules: Module[] = [];
  partners: Partner[] = [];
  contents: Content[] = [];
  transactions: Transaction[] = [];
  tickets: Ticket[] = [];

  selectedTypes: any[] = [];
  deletedPlanId: number;
  selectedUsers: User[] = [];
  selectedPartners: Partner[] = [];
  selectedUsersForPlan: User[] = [];
  transactionsForGrid: Transaction[] = [];
  ticketActivities: TicketActivity[] = [];
  selectedUsersForTransaction: User[] = [];
  selectedTypesForTransaction: any[] = [];
  selectedStatusesForTransaction: any[] = [];

  pageNumber: number = 1;
  pageSize: number = 5;

  plan: Plan = new Plan();
  type: PlanType = new PlanType();
  content: Content = new Content();
  transaction: Transaction = new Transaction();
  ticketActivity: TicketActivity = new TicketActivity();


  projectStatuses: ProjectStatus[] = [
    { id: 1, name: "Devam Ediyor" },
    { id: 2, name: "Tamamlandı" }
  ];
  priorities: Priority[] = [
    { id: 1, name: "Yüksek" },
    { id: 2, name: "Normal" },
    { id: 3, name: "Düşük" },
  ];
  typeForFilter: PlanType[] = [
    { id: 1, name: "Destek Planı" },
    { id: 2, name: "Proje Planı" },
    { id: 3, name: "İzin" },
    { id: 4, name: "Resmi Tatiller" },
  ];
  genders: any[] = [
    { id: 1, name: "Erkek" },
    { id: 2, name: "Kadın" },
  ];
  locationType: LocationType = new LocationType()
  locationTypes: LocationType[] = [
    { id: 3, name: "Yerinde" },
    { id: 4, name: "Uzaktan" },
  ];

  billTypes: BillType[] = [
    { id: 4, name: "Faturalanmadı" },
    { id: 5, name: "Faturalandı" },
    { id: 6, name: "Feragat Edildi" }
  ];
  statuses: Status[] = [
    { id: 1, name: "Yeni Oluşturuldu" },
    { id: 2, name: "Danışmandan Cevap Bekleniyor" },
    { id: 8, name: "Müşteriden Cevap Bekleniyor" },
    { id: 22, name: "Efor Onayı Bekleniyor" },
    { id: 10, name: "Test Sürecinde" },
    { id: 4, name: "Tamamlandı" },
    { id: 5, name: "İptal Edildi" },

  ];
  ticketTypes: TicketType[] = [
    { id: 1, name: "Destek Talebi" },
    { id: 2, name: "Müşteri Planı" },
    { id: 3, name: "Proje Planı" },
  ];
  ticketUserTypes: any[] = [
    { id: 6, name: "Sorumlu" },
    { id: 9, name: "Takipçi" },
  ];
  answerTypes: AnswerType[] = [
    { id: 1, name: "Çözüm Önerildi" },
    { id: 2, name: "Cevap Bekleniyor" },
    { id: 3, name: "Mesaj Gönder" },
  ];
  types: any[] = [
    {id:1, name:'Talep'},
    {id:2, name:'Talep Kullanıcısı'},
    {id:3, name:'Proje'},
    {id:4, name:'Sözleşme'},
    {id:5, name:'Sözleşme Detayı'},
    {id:6, name:'Efor'},
    {id:7, name:'Modül'},
    {id:8, name:'Cari'},
    {id:9, name:'Cari Kullanıcısı'},
    {id:10, name:'Kullanıcı İzinleri'},
    {id:11, name:'Plan'},
    {id:12, name:'Aktivite'},
    {id:13, name:'Kullanıcı'},
    {id:14, name:'Kullanıcının Yetkileri'},
  ];

  statusesForTransaction:any[]=[
    {id:1, name:'Ekleme'},
    {id:2,name: 'Güncelleme'},
    {id:3, name:'Silme'},
    {id:4, name:'Hata'},
    {id:5, name:'Yetkisiz Giriş'},

  ]
  

  ngOnInit(): void {
    this.userRole = this.decodeService.getRole();
    if (this.userRole != "Admin" && this.userRole != "Consultant") {
      this.router.navigate(['/home']);
    }
    else {
      this.list();
    }
  }

  transactionList() {
    if (this.userRole == "Admin") {
      if (this.gridView) {
        this.transactionService.getList().subscribe((items: any) => {
          this.transactionsForGrid = items.data;
        });
        // this.transactionService.getListForTimeline(this.pageNumber, this.basicSelectedOptionForTransaction).subscribe((items: any) => {
        //   this.transactionsForGrid = items.data;
        // });
      }
      else {
        this.transactionService.getListForTimeline(1, this.pageSize).subscribe((items: any) => {
          this.transactions = items.data;
        });
      }
    }
  }


  list() {
    this.ticketActivityService.getDeletedTicketActivities().subscribe((item: any) => {
      this.ticketActivities = item.data;
      this.informationService.list(item.message)
      this.planService.getPlansWithoutActivities().subscribe((items: any) => {
        this.plans = items.data;
      }, (err) => {
        this.errorService.errorHandler(err);
      });
    });

    this.transactionList();

    this.partnerService.getList().subscribe((items: any) => {
      this.partners = items.data;
    });
    this.userService.getListForFilter().subscribe((items: any) => {
      this.users = items.data;
    });
    this.departmentService.getList().subscribe((items: any) => {
      this.departments = items.data;
    });
    this.projectService.getList().subscribe((items: any) => {
      this.projects = items.data;
    });
    this.operationcliamService.getList().subscribe((items: any) => {
      this.operationClaims = items.data;
    });
    this.permissionService.getList().subscribe((items: any) => {
      this.permissions = items.data;
    });
    this.moduleService.getList().subscribe((items: any) => {
      this.modules = items.data;
    });
  }
  getTicketActivityforPreview(id: number) {
    this.ticketActivityService.get(id).subscribe((res: any) => {
      this.ticketActivity = res.data;

      this.userService.get(this.ticketActivity.userId).subscribe((res: any) => {
        this.ticketActivity.userName = res.data.firstName + " " + res.data.lastName;
      });
      this.moduleService.get(this.ticketActivity.moduleId).subscribe((res: any) => {
        this.ticketActivity.moduleName = res.data.name;
      });
      this.locationTypes.forEach((item: LocationType) => {
        if (item.id == this.ticketActivity.locationTypeId) {
          this.ticketActivity.locationTypeName = item.name;
        }

      });
      if (this.ticketActivity.description == null || this.ticketActivity.description == "") {
        this.ticketActivity.description = "Açıklama Boş Bırakıldı.."
      }
      this.ticketActivity.partnerName = res.data.title;
    }, (err) => {
      this.errorService.errorHandler(err);
    })
  }
  getPlanforPreview(id: number) {
    this.planService.get(id).subscribe((res: any) => {
      this.plan = res.data;

      this.userService.get(this.plan.userId).subscribe((res: any) => {
        this.plan.userName = res.data.firstName + " " + res.data.lastName;
      });
      this.typeForFilter.forEach((item: PlanType) => {
        if (item.id == this.plan.typeId) {
          this.plan.planTypeName = item.name;
        }
      });
      this.locationTypes.forEach((item: LocationType) => {
        if (item.id == this.plan.locationTypeId) {
          this.plan.locationTypeName = item.name;
        }
      });
      this.partnerService.get(this.plan.partnerId).subscribe((res: any) => {
        this.plan.partnerName = res.data.title;
      });
      if (this.plan.description == null || this.plan.description == "") {
        this.plan.description = "Açıklama Boş Bırakıldı.."
      }
    }, (err) => {
      this.errorService.errorHandler(err);
    })
  }

  nextPage(pageNumber: number) {
    this.pageNumber = pageNumber + 1;
    this.listTransactionGrid(this.pageNumber);
  }
  previousPage(pageNumber: number) {
    if (pageNumber > 1) {
      this.pageNumber = pageNumber - 1;
      this.listTransactionGrid(this.pageNumber);
    }
  }
  listTransactionGrid(pageNumber: number) {
    this.transactionService.getListForTimeline(pageNumber, this.basicSelectedOptionForTransaction).subscribe((items: any) => {
      this.transactionsForGrid = items.data;
    });
  }

  delete(id: number) {
    this.planService.get(id).subscribe((item: any) => {
      item.data.deletedBy = this.decodeService.getUserId();
      item.data.updatedBy = 0;
      this.planService.delete(item.data).subscribe((item: any) => {
        this.informationService.delete(item.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.list();
        }, 1500);
      });
    });
  }

  tableByFiltersId() {
    let filterFunction = (filteredItems: any) => {
      if (this.selectedUsers.length > 0) {
        filteredItems = filteredItems.filter(item => this.selectedUsers.includes(item.userId));
      }

      if (this.selectedPartners.length > 0) {
        filteredItems = filteredItems.filter(item => this.selectedPartners.includes(item.partnerId));
      }

      if (this.fromDate || this.toDate) {
        filteredItems = filteredItems.filter(item =>
          new Date(item.startDate) >= new Date(this.fromDate) &&
          new Date(item.startDate) <= new Date(this.toDate)
        );
      }
      this.ticketActivities = filteredItems;
    };

    if (this.userRole === "Admin" || this.userRole === "Consultant") {
      this.ticketActivityService.getDeletedTicketActivities().subscribe((items: any) => {
        let filteredItems = items.data;
        filterFunction(filteredItems);
      });
    }

  }


  checkType(value: any): value is NgbDate {
    return value instanceof NgbDate;
  }

  tableByDates() {
    var tempDate: any = {};
    var datePipe = new DatePipe("en-US");

    if (this.fromDateExample instanceof NgbDate) {
      tempDate = { day: this.fromDateExample.day, month: this.fromDateExample.month - 1, year: this.fromDateExample.year };
    }
    else {
      var fromDateTransformed = new Date(datePipe.transform(this.fromDateExample, 'yyyy-MM-dd'));
      tempDate = { day: fromDateTransformed.getDate(), month: fromDateTransformed.getMonth(), year: fromDateTransformed.getFullYear() }
    }

    this.fromDate = new Date(tempDate.year, tempDate.month, tempDate.day, 0, 0, 0);

    if (this.toDateExample == null)
      this.toDate = new Date(tempDate.year, tempDate.month, tempDate.day, 23, 59, 59);
    else
      var tempToDate: any = {};

    if (this.toDateExample instanceof NgbDate) {
      tempToDate = { day: this.toDateExample.day, month: this.toDateExample.month - 1, year: this.toDateExample.year };
    }
    else {
      var toDateTransformed = new Date(datePipe.transform(this.toDateExample, 'yyyy-MM-dd'));
      tempToDate = { day: toDateTransformed.getDate(), month: toDateTransformed.getMonth(), year: toDateTransformed.getFullYear() }
    }

    this.toDate = new Date(tempToDate.year, tempToDate.month, tempToDate.day, 23, 59, 59);

    if (this.fromDate && this.toDate) {
      let filterFunction = (items: any) => {
        this.ticketActivities = items.filter(item =>
          new Date(item.startDate) >= this.fromDate &&
          new Date(item.startDate) <= this.toDate
        );
      };

      if (this.userRole == "Admin" || this.userRole == "Consultant") {
        this.ticketActivityService.getDeletedTicketActivities().subscribe((items: any) => {
          filterFunction(items.data);
        });
      }

    }

    this.tableByFiltersId();
  }
  tableByFiltersIdForTransaction(){
    this.transactionService.getList().subscribe((items: any) => {
      let filteredItems = items.data;
      if (this.selectedUsersForTransaction.length > 0) {
        filteredItems = filteredItems.filter(item => this.selectedUsersForTransaction.includes(item.createdBy));
      }
      if (this.selectedTypesForTransaction.length > 0) {
        filteredItems = filteredItems.filter(item => this.selectedTypesForTransaction.includes(item.typeId));
      }
      if (this.selectedStatusesForTransaction.length > 0) {
        filteredItems = filteredItems.filter(item => this.selectedStatusesForTransaction.includes(item.statusId));
      }
      if (this.fromDateForTransaction && this.toDateForTransaction) {
        filteredItems = filteredItems.filter(item =>
          new Date(item.createdDate)  >= this.fromDateForTransaction &&
          new Date(item.createdDate)  <= this.toDateForTransaction
        );
      }
      this.transactionsForGrid = filteredItems;
    });
  }


  tableByFiltersIdForPlan() {
    this.planService.getPlansWithoutActivities().subscribe((items: any) => {
      let filteredItems = items.data;

      if (this.selectedTypes.length > 0) {
        filteredItems = filteredItems.filter(item => this.selectedTypes.includes(item.typeId));
      }
      if (this.selectedUsersForPlan.length > 0) {
        filteredItems = filteredItems.filter(item => this.selectedUsersForPlan.includes(item.userId));
      }
      if (this.fromDateForPlan && this.toDateForPlan) {
        filteredItems = filteredItems.filter(item =>
          new Date(item.startDate) >= this.fromDateForPlan &&
          new Date(item.startDate) <= this.toDateForPlan ||
          new Date(item.endDate) >= this.fromDateForPlan &&
          new Date(item.endDate) <= this.toDateForPlan
        );
      }
      this.plans = filteredItems;
    });
  }tableByDatesForTransaction() {
    var tempDate: any = {};
    var datePipe = new DatePipe("en-US");
  
    if (this.fromDateExampleForTransaction instanceof NgbDate) {
      tempDate = { day: this.fromDateExampleForTransaction.day, month: this.fromDateExampleForTransaction.month - 1, year: this.fromDateExampleForTransaction.year };
    } else {
      var fromDateTransformed = new Date(datePipe.transform(this.fromDateExampleForTransaction, 'yyyy-MM-dd'));
      tempDate = { day: fromDateTransformed.getDate(), month: fromDateTransformed.getMonth(), year: fromDateTransformed.getFullYear() }
    }
  
    this.fromDateForTransaction = new Date(tempDate.year, tempDate.month, tempDate.day, 0, 0, 0);
  
    if (this.toDateExampleForTransaction == null) {
      this.toDateForTransaction = new Date(tempDate.year, tempDate.month, tempDate.day, 23, 59, 59);
    } else {
      var tempToDate: any = {};
  
      if (this.toDateExampleForTransaction instanceof NgbDate) {
        tempToDate = { day: this.toDateExampleForTransaction.day, month: this.toDateExampleForTransaction.month - 1, year: this.toDateExampleForTransaction.year };
      } else {
        var toDateTransformed = new Date(datePipe.transform(this.toDateExampleForTransaction, 'yyyy-MM-dd'));
        tempToDate = { day: toDateTransformed.getDate(), month: toDateTransformed.getMonth(), year: toDateTransformed.getFullYear() }
      }
  
      this.toDateForTransaction = new Date(tempToDate.year, tempToDate.month, tempToDate.day, 23, 59, 59);
    }
  
    if (this.fromDateForTransaction && this.toDateForTransaction) {
      this.transactionService.getList().subscribe((items: any) => {
        this.transactionsForGrid = items.data.filter(item =>
          new Date(item.createdDate) >= this.fromDateForTransaction &&
          new Date(item.createdDate) <= this.toDateForTransaction
        );
      });
    }
  
    this.tableByFiltersIdForTransaction();
  }
  

  tableByDatesForPlan() {
    var tempDate: any = {};
    var datePipe = new DatePipe("en-US");

    if (this.fromDateExampleForPlan instanceof NgbDate) {
      tempDate = this.fromDateExampleForPlan;
    }
    else {
      var fromDateTransformed = new Date(datePipe.transform(this.fromDateExampleForPlan, 'yyyy-MM-dd'));
      tempDate = { day: fromDateTransformed.getDate(), month: fromDateTransformed.getMonth(), year: fromDateTransformed.getFullYear() }
    }

    this.fromDateForPlan = new Date(tempDate.year, tempDate.month, tempDate.day, 0, 0, 0);

    if (this.toDateExampleForPlan == null)
      this.toDateForPlan = new Date(tempDate.year, tempDate.month, tempDate.day, 23, 59, 59);
    else
      var tempToDate: any = {};

    if (this.toDateExampleForPlan instanceof NgbDate) {
      tempToDate = this.toDateExampleForPlan;
    }
    else {
      var toDateTransformed = new Date(datePipe.transform(this.toDateExampleForPlan, 'yyyy-MM-dd'));
      tempToDate = { day: toDateTransformed.getDate(), month: toDateTransformed.getMonth(), year: toDateTransformed.getFullYear() }
    }

    this.toDateForPlan = new Date(tempToDate.year, tempToDate.month, tempToDate.day, 23, 59, 59);

    if (this.fromDateForPlan && this.toDateForPlan) {
      this.planService.getPlansWithoutActivities().subscribe((items: any) => {
        this.plans = items.data.filter(item =>
          new Date(item.startDate) >= this.fromDate &&
          new Date(item.startDate) <= this.toDate ||
          new Date(item.endDate) >= this.fromDate &&
          new Date(item.endDate) <= this.toDate
        );
      });
    }
    this.tableByFiltersIdForPlan();
  }

  getRouterLink(typeId: number, eventNumber: number): string {
    switch (typeId) {
      case 3: return `/home/updateproject/${eventNumber}`;
      case 4: return `/home/updatecontract/${eventNumber}`;
      case 5: return `/home/updatecontract/${eventNumber}`;
      case 7: return `/home/updatemodule/${eventNumber}`;
      case 8: return `/home/updatepartner/${eventNumber}`;
      case 11: return `/home/updateplan/${eventNumber}`;
      case 13: return `/home/updateuser/${eventNumber}`;
      case 14: return `/home/updateuser/${eventNumber}`;
      default: return '';
    }
  }

  getActionName(typeId: number): string {
    switch (typeId) {
      case 1: return 'Talep';
      case 2: return 'Talep Kullanıcısı';
      case 3: return 'Proje';
      case 4: return 'Sözleşme';
      case 5: return 'Sözleşme Detayı';
      case 6: return 'Efor';
      case 7: return 'Modül';
      case 8: return 'Cari';
      case 9: return 'Cari kullanıcısı';
      case 10: return 'Kullanıcı izinleri';
      case 11: return 'Plan';
      case 12: return 'Aktivite';
      case 13: return 'Kullanıcı';
      case 14: return 'Kullanıcının yetkileri';
      default: return '';
    }
  }
  getTransactionStatusName(statusId: number): any {
    const foundItem = this.statusesForTransaction.find((item: any) => item.id === statusId);
  
    if (foundItem) {
      return foundItem.name;
    }
    return null; 
  }
  getTransaction(id: number) {
    this.transactionService.get(id).subscribe((item: any) => {
      this.transaction = item.data;
      this.contentService.getListByTransactionId(item.data.id).subscribe((items: any) => {
        this.contents = items.data;
      }, (err) => {
        this.errorService.errorHandler(err);
      });
    })
  }

  getDisplayName(fieldName: string): string {
    switch (fieldName) {
      case 'PriorityId':
        return 'Öncelik Seviyesi';
      case 'DepartmentId':
        return 'Bölümü';
      case 'BillTypeId':
        return 'Faturalandırma Durumu';
      case 'StatusId':
        return 'Durumu';
      case 'TicketTypeId':
        return 'Talep Tipi';
      case 'AnswerTypeId':
        return 'Cevap Tipi';
      case 'LocationTypeId':
        return 'Yeri';
      case 'TypeId':
        return 'Türü';
      case 'ModuleId':
        return 'Modülü';
      case 'PartnerId':
        return 'Cari';
      case 'UserId':
        return 'Kullanıcı';
      default:
        return fieldName;
    }
  }

  getDisplayValue(id: string, fieldName: string): string {
    var eventId = parseInt(id);
    switch (fieldName) {
      case 'PriorityId':
        return this.priorities.find(p => p.id === eventId)?.name || '';
      case 'DepartmentId':
        return this.typeForFilter.find(t => t.id === eventId)?.name || '';
      case 'BillTypeId':
        return this.billTypes.find(b => b.id === eventId)?.name || '';
      case 'StatusId':
        if (this.transaction.typeId == 1)
          return this.statuses.find(s => s.id === eventId)?.name || '';
        if (this.transaction.typeId == 3)
          return this.projectStatuses.find(a => a.id === eventId)?.name || '';

      case 'TicketTypeId':
        return this.ticketUserTypes.find(t => t.id === eventId)?.name || '';
      case 'AnswerTypeId':
        return this.answerTypes.find(a => a.id === eventId)?.name || '';
      case 'LocationTypeId':
        return this.locationTypes.find(l => l.id === eventId)?.name || '';
      case 'TypeId':
        if (this.transaction.typeId == 1)
          return this.ticketTypes.find(t => t.id === eventId)?.name || '';
        if (this.transaction.typeId == 2)
          return this.ticketUserTypes.find(t => t.id === eventId)?.name || '';
        if (this.transaction.typeId == 11)
          return this.typeForFilter.find(t => t.id === eventId)?.name || '';

      case 'ModuleId':
        return this.modules.find(m => m.id === eventId)?.name || '';
      case 'PartnerId':
        return this.partners.find(p => p.id === eventId)?.title || '';
      case 'UserId':
        return this.users.find(u => u.id === eventId)?.firstName || '';
      case 'DepartmentId':
        return this.departments.find(d => d.id === eventId)?.name || '';
      case 'ProjectId':
        return this.projects.find(p => p.id === eventId)?.title || '';
      case 'OperationClaimId':
        return this.operationClaims.find(o => o.id === eventId)?.name || '';
      case 'PermissionId':
        return this.permissions.find(p => p.id === eventId)?.name || '';
      case 'ProjectStatusId':
        return this.projectStatuses.find(p => p.id === eventId)?.name || '';
      default:
        return id.toString();
    }
  }
  isHovered(date: NgbDate) {
    return (
      this.fromDateExample && !this.toDateExample && this.hoveredDate && date.after(this.fromDateExample) && date.before(this.hoveredDate)
    );
  }
  isInside(date: NgbDate) {
    return this.toDateExample && date.after(this.fromDateExample) && date.before(this.toDateExample);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDateExample) ||
      (this.toDateExample && date.equals(this.toDateExample)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDateExample && !this.toDateExample) {
      this.fromDateExample = date;
    } else if (this.fromDateExample && !this.toDateExample && date && date.after(this.fromDateExample)) {
      this.toDateExample = date;
    } else {
      this.toDateExample = null;
      this.fromDateExample = date;
    }
    this.tableByDates();
  }

  isHoveredForTransaction(date: NgbDate) {
    return (
      this.fromDateExampleForTransaction && !this.toDateExampleForTransaction && this.hoveredDateForTransaction && date.after(this.fromDateExampleForTransaction) && date.before(this.hoveredDateForTransaction)
    );
  }

  isInsideForTransaction(date: NgbDate) {
    return this.toDateExampleForTransaction && date.after(this.fromDateExampleForTransaction) && date.before(this.toDateExampleForTransaction);
  }

  isRangeForTransaction(date: NgbDate) {
    return (
      date.equals(this.fromDateExampleForTransaction) ||
      (this.toDateExampleForTransaction && date.equals(this.toDateExampleForTransaction)) ||
      this.isInsideForTransaction(date) ||
      this.isHoveredForTransaction(date)
    );
  }

  onDateSelectionForTransaction(date: NgbDate) {
    if (!this.fromDateExampleForTransaction && !this.toDateExampleForTransaction) {
      this.fromDateExampleForTransaction = date;
    } else if (this.fromDateExampleForTransaction && !this.toDateExampleForTransaction && date && date.after(this.fromDateExampleForTransaction)) {
      this.toDateExampleForTransaction = date;
    } else {
      this.toDateExampleForTransaction = null;
      this.fromDateExampleForTransaction = date;
    }
    this.tableByDatesForTransaction();
  }

  isHoveredForPlan(date: NgbDate) {
    return (
      this.fromDateExampleForPlan && !this.toDateExampleForPlan && this.hoveredDateForPlan && date.after(this.fromDateExampleForPlan) && date.before(this.hoveredDateForPlan)
    );
  }
  isInsideForPlan(date: NgbDate) {
    return this.toDateExampleForPlan && date.after(this.fromDateExampleForPlan) && date.before(this.toDateExampleForPlan);
  }

  isRangeForPlan(date: NgbDate) {
    return (
      date.equals(this.fromDateExampleForPlan) ||
      (this.toDateExampleForPlan && date.equals(this.toDateExampleForPlan)) ||
      this.isInsideForPlan(date) ||
      this.isHoveredForPlan(date)
    );
  }


  onDateSelectionForPlan(date: NgbDate) {
    if (!this.fromDateExampleForPlan && !this.toDateExampleForPlan) {
      this.fromDateExampleForPlan = date;
    } else if (this.fromDateExampleForPlan && !this.toDateExampleForPlan && date && date.after(this.fromDateExampleForPlan)) {
      this.toDateExampleForPlan = date;
    } else {
      this.toDateExampleForPlan = null;
      this.fromDateExampleForPlan = date;
    }
    this.tableByDatesForPlan();
  }


  increasePageSize(pageSize) {
    this.pageSize = pageSize + 5;
    this.transactionList();
  }

  getInitials(name: string) {
    const userName = name;
    const firstInitial = userName.charAt(0);
    const lastInitial = userName.lastIndexOf(' ') > -1
      ? userName.substring(userName.lastIndexOf(' ') + 1, userName.lastIndexOf(' ') + 2)
      : '';

    return firstInitial + lastInitial;
  }


  gridViewChange() {
    this.gridView = !this.gridView;
    this.transactionList();
  }


  exportExcelActivities() {
    let element = document.getElementById("excel-table-ticketactivity");
    let title = "Silinen Aktiviteler";
    this.helperService.exportExcel(element, title);
  }

  exportExcelPlans() {
    let element = document.getElementById("excel-table-plan");
    let title = "Aktivitesi Olmayan Planlar";
    this.helperService.exportExcel(element, title);
  }
  exportExcelTransactions() {
    let element = document.getElementById("excel-table-transaction");
    let title = "Yapılan İşlem Geçmişi";
    this.helperService.exportExcel(element, title);
  }
}
