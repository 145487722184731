<div class="container-new">
    <div *ngIf="loading" class="spinner-overlay">
      <div class="spinner-container">
        <p-progressSpinner strokeWidth="4"></p-progressSpinner>
      </div>
    </div>
    <div class="content-wrapper container-xxl p-0">
        <div class="content-body">
            <section id="ngx-datatable-kitchen-sink">
                <core-card-snippet>
                    <h4 class="card-title">Akış Raporu</h4>
                    <div class="row">
                        <div class="firstRow mt-1">
                            <div style="margin-left:9px; margin-bottom: 20px;">
                                <div class="col-12">
    
                                    <select class="form-control form-select" [(ngModel)]="basicSelectedOption" (ngModelChange)="list(1)">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="filter mt-1">
                            <div class="col-12">
                                <p-multiSelect 
                                [options]="consultantActivityFilters" 
                                [(ngModel)]="selectedConsultantActivityFilter"
                                (ngModelChange)="populateUserFilters();list(1);pageNumber=1;"
                                placeholder="Danışman Aktiflik Durumu Seç"
                                optionLabel="label"
                                optionValue="label"
                                [maxSelectedLabels]="2" 
                                [selectedItemsLabel]="'...'" 
                                display="chip" 
                                [showClear]="false"></p-multiSelect>
                            </div>
                        </div>
                        <div class="filter mt-1">
                            <div class="col-12">
                                <p-multiSelect 
                                [options]="consultantTypeFilters" 
                                [(ngModel)]="selectedConsultantTypeFilter"
                                (ngModelChange)="filterUserType();list(1);pageNumber=1;"
                                placeholder="Danışman Tipini Seç"
                                optionLabel="label"
                                optionValue="value"
                                [maxSelectedLabels]="2" 
                                [selectedItemsLabel]="'...'" 
                                display="chip" 
                                [showClear]="false"></p-multiSelect>    
                            </div>
                        </div>
                        <div class="filter mt-1">
                            <div class="col-12">
                                <p-multiSelect 
                                [options]="consultantFilters" 
                                [(ngModel)]="selectedConsultantFilter"
                                (ngModelChange)="handleSelectionChange($event);list(1);pageNumber=1;"
                                placeholder="Danışman Seç"
                                optionLabel="label"
                                optionValue="value"
                                [maxSelectedLabels]="2" 
                                [selectedItemsLabel]="'...'" 
                                [display]="selectedConsultantFilter.length > 2 ? 'comma' : 'chip'"
                                [showClear]="false"></p-multiSelect>    
                            </div>
                        </div>
                        <div class="filter mt-1">
                            <div class="col-12">
                                <p-multiSelect 
                                [options]="typeForFilter" 
                                [(ngModel)]="selectedPlanTypeFilter"
                                (ngModelChange)="handleSelectionChange($event);list(1);pageNumber=1;"
                                placeholder="Aktivite Tipi Seç"
                                optionLabel="name"
                                optionValue="name"
                                [maxSelectedLabels]="2" 
                                [selectedItemsLabel]="'...'" 
                                [display]="selectedPlanTypeFilter.length > 2 ? 'comma' : 'chip'"
                                [showClear]="false"></p-multiSelect>    
                            </div>
                        </div>
    
                    </div>
                    <div class="row  mb-2 mr-1">
                        <div class="col-4 ml-1">
                            <div class="col-12">
                                <button class="btn btn-primary btn-sm mr-1" routerLink="/home/addplan" rippleEffect
                                    title="Yeni Plan">
                                    <i data-feather="plus"></i><span class="d-none d-sm-inline-block"></span>
                                </button>
                                <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect
                                    title="Excele Aktar">
                                    <i data-feather="file-text" class=""></i>
                                </button>
                            </div>
                        </div>
                        <div id="dateFilter" class="row col-8"
                        style="display: flex;justify-content: end;">
           
                           <form class="form-inline">
                               <ng-select [clearable]="false" name="selectMonthForFilter" [multiple]="false" [items]="dateFilter"
                               [(ngModel)]="selectedDateFilter" placeholder="Aya Göre Filtrele"
                               bindLabel="name" bindValue="id" (change)="tableByFiltersId()" style="min-width:150px;" class="mr-1">
                               </ng-select>
                               <div class="form-group">
                                   <div class="input-group w-0 ">
                                       <input name="datepicker" class="form-control" ngbDatepicker
                                           #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                           (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                                           [dayTemplate]="rangeSelectionDP" outsideDays="hidden"
                                           [startDate]="fromDateExample!" />
                                       <ng-template #rangeSelectionDP let-date let-focused="focused">
                                           <span class="custom-day" type="datetime" [class.focused]="focused"
                                               [class.range]="isRange(date)"
                                               [class.faded]="isHovered(date) || isInside(date)"
                                               (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                               {{ date.day }}
                                           </span>
                                       </ng-template>
                                   </div>
                               </div>
                               <div class="form-group">
                                   <div class="input-group">
                                       <input class="form-control" type="date" placeholder="dd-MM-yyyy"
                                           name="dpFromDate"
                                           [ngModel]="checkType(fromDateExample) ? formatter.format(fromDateExample) : fromDateExample"
                                           (ngModelChange)="fromDateExample = $event; tableByDates();" />
                                       <div class="input-group-append">
                                           <button
                                               class="btn btn-outline-secondary feather icon-calendar"
                                               (click)="datepicker.toggle()" type="button"
                                               rippleEffect></button>
                                       </div>
                                   </div>
                               </div>
                               <div class="form-group ml-sm-2">
                                   <div class="input-group">
                                       <input class="form-control" type="date" placeholder="dd-MM-yyyy"
                                           name="dpToDate"
                                           [ngModel]="checkType(toDateExample) ? formatter.format(toDateExample) : toDateExample"
                                           (ngModelChange)="toDateExample = $event; tableByDates();" />
                                       <div class="input-group-append">
                                           <button
                                               class="btn btn-outline-secondary feather icon-calendar"
                                               (click)="datepicker.toggle()" type="button"
                                               rippleEffect></button>
                                       </div>
                                   </div>
                               </div>
                           </form>
                       </div>
                    </div>
    
                    <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
                        <thead>
                            <tr>
                                <td>Danışman Adı</td>
                                <td>Aktivite Tipi</td>
                                <td>Çalışma Tipi</td>
                                <td>Aktivite Tarihi</td>
                                <td>Aktivite Süresi</td>
                                <td>Aktivite Açıklaması</td>
                                <td>Modül Adı</td>
                                <td>Cari Adı</td>
                                <td>Proje Adı</td>
                                <td>Talep Takip Numarası</td>
                                <td>Faturalama Bilgisi</td>
                                <td>Kullanıcı Aktiflik Durumu</td>
    
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of filteredActivityInquiries">
                                <td>{{item.userName}}</td>
                                <td>{{item.activityTypeName}}</td>
                                <td>{{item.locationTypeName}}</td>
                                <td [innerHTML]="item.activityDate | date:'dd.MM.yyyy HH:mm:ss'"></td>
                                <td>{{item.activityHours}}</td>
                                <td>{{item.description}}</td>
                                <td>{{item.moduleName}}</td>
                                <td>{{item.partnerName}}</td>
                                <td>{{item.projectName}}</td>
                                <td>{{item.ticketId}}</td>
                                <td>{{item.billTypeName}}</td>
                                <td>{{item.isActiveUser}}</td>
    
                            </tr>
                        </tbody>
                    </table>
                    <ngx-datatable [rows]="filteredActivityInquiries | paginate:{itemsPerPage:basicSelectedOption,currentPage:pageNumber,totalItems:totalItems}" [rowHeight]="58" class="bootstrap core-bootstrap"
                        [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
                        [limit]="basicSelectedOption" (sort)="onSort($event)">
                        <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                            [resizeable]="false">
                            <ng-template ngx-datatable-header-template let-value="value"
                                let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                                        (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                                    <label class="custom-control-label" for="headerChkbxRef"></label>
                                </div>
                            </ng-template>
                            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value"
                                let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" [checked]="isSelected"
                                        (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}" />
                                    <label class="custom-control-label" for="rowChkbxRef{{rowIndex}}"></label>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Danışman" prop="userName" [width]="200">
                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                {{row.userName}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column *ngIf="userRole=='Admin'" name="D. Aktiflik" prop="isActiveUser" [width]="200">
                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                {{row.isActiveUser}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Aktivite Tipi" prop="activityTypeName" [width]="200">
                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                {{row.activityTypeName}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Çalışma Tipi" prop="locationTypeName" [width]="200">
                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                {{row.locationTypeName}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Tarih" prop="activityDate" [width]="200">
                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                {{row.activityDate | date:'dd.MM.yyyy'}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Süre" prop="activityHours" [width]="200">
                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                {{row.activityHours}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Açıklama" prop="description" [width]="200">
                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                <div 
                                class="description-container" 
                                (mouseenter)="onMouseEnter($event, row.description)" 
                                (mouseleave)="onMouseLeave()"
                                (mousemove)="onMouseMove($event)">
                                
                                <div class="description-text" [innerHTML]="row.description"></div>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Modül" prop="moduleName" [width]="200">
                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                {{row.moduleName}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Partner" prop="partnerName" [width]="200">
                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                {{row.partnerName}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Proje" prop="projectName" [width]="200">
                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                {{row.projectName}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Takip No" prop="ticketId" [width]="200">
                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                <div *ngIf="row.ticketId !=null">
                                    <a class="badge badge-pill badge-light-info font-small-3 font-weight-bold line-height-2 mb-25"
                                    [routerLink]="'/home/details/' + row.ticketId" target="_blank">#00000{{ row.ticketId }}</a>
    
                                </div>
                                <div *ngIf="row.ticketId==null">
                                    Girilmemiş
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Faturalama" prop="billTypeName" [width]="200">
                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                <div class="d-flex align-items-center">
                                    <div class="badge badge-pill badge-light-danger mr-1"
                                        *ngIf="row.billTypeId==4">Faturalandırılmadı</div>
                                    <div class="badge badge-pill badge-light-primary mr-1"
                                        *ngIf="row.billTypeId==5">Faturalandı</div>
                                    <div class="badge badge-pill badge-light-secondary mr-1"
                                        *ngIf="row.billTypeId==6">Feragat Edildi</div>
                                </div>                                
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                <div class="d-flex align-items-center">
                                    <div ngbDropdown container="body">
                                        <div *ngIf="row.activityTypeName != 'İzin'">
                                            <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                            id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                                            </a>
                                            
                                            <div *ngIf="row.ticketId==null && row.activityTypeName != 'İzin'">
    
                                                <div ngbDropdownMenu class="dropdown-menu-right"
                                                aria-labelledby="dropdownBrowserState">
                                                    <button ngbDropdownItem (click)="get(row.id)"
                                                        data-bs-toggle="modal" data-bs-target="#addactivity">
                                                        <i data-feather="edit-2" class="mr-50"></i><span>Güncelle</span>
                                                    </button>                                                
                                                    <button ngbDropdownItem (click)="delete(row.id)">
                                                        <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div *ngIf="row.ticketId!=null && row.activityTypeName=='Destek'">
                                                    <div ngbDropdownMenu class="dropdown-menu-right"
                                                    aria-labelledby="dropdownBrowserState">
                                                        <button ngbDropdownItem [routerLink]="'/home/details/'+ row.ticketId">
                                                        <i data-feather="edit-2" class="mr-50"></i><span>Talebi Aç</span>
                                                        </button> 
                                                    </div>
                                            </div>                                                
                                        
                                        </div>
    
                                    </div>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-footer>
                            <ng-template ngx-datatable-footer-template>                              
                                    <div class="d-flex justify-content-center ml-2 mt-2">
                                        {{totalItems }} aktivitenin {{ (pageNumber - 1) * basicSelectedOption + 1 }} - {{ pageNumber * basicSelectedOption }} aralığı gösteriliyor. 
    
                                        <pagination-controls
                                        [directionLinks]="true" 
                                        previousLabel="Geri" 
                                        nextLabel="İleri"
                                        (pageChange)="pageNumber=$event;list($event)">
                                        </pagination-controls>
                                    </div>
    
                            </ng-template>
                        </ngx-datatable-footer>
                    </ngx-datatable>
                </core-card-snippet>
            </section>
        </div>
    </div>


</div>

<div *ngIf="isOverlayVisible" 
     class="description-overlay" 
     [innerHTML]="overlayContent"
     [ngStyle]="{ top: overlayPosition.top + 'px', left: overlayPosition.left + 'px' }">
</div>