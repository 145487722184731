<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="ngx-datatable-kitchen-sink">
            <core-card-snippet>
                <div class="d-flex justify-content-between align-items-center ml-1">
                    <h4 class="card-title">
                      {{documentFormInfo.documentNo}} {{documentFormInfo.documentName}} Doküman Veri Listesi
                    </h4>
                    <button class="btn btn-secondary mr-1" (click)="goBack()">
                      <i class="pi pi-arrow-left"></i> Geri
                    </button>
                  </div>                
                  <div class="row">
                    <div class="firstRow mt-1">
                        <div style="margin-left:9px; margin-bottom: 20px;">
                            <div class="col-12">

                                <select class="form-control form-select" [(ngModel)]="basicSelectedOption">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="search mt-1">
                        <div class="col-12">
                            <input type="text" class="form-control mr-1" name="filterText" (keyup)="tableByFiltersId()" placeholder="Doküman Verisi Arayın.."
                                [(ngModel)]="filterText" [ngModelOptions]="{standalone:true}">
                        </div>
                    </div>
                    <div class="filter mt-1 ml-1">
                        <div class="form-group">
                            <ng-select [multiple]="false" [items]="itemTypes" [(ngModel)]="selectedItemType"
                                placeholder="Veri Tipi" bindLabel="name" bindValue="id"
                                (change)="tableByFiltersId()" style="width:200px;" [clearable]="false">
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <span class="ng-value-label">{{ item.name}}</span>
                                </ng-template>
                                <ng-template ng-header-tmp>
                                </ng-template>
                            </ng-select>
                            <br />
                        </div>
                    </div>
                </div>
                <div class="row  mb-2 mr-1">
                    <div class="col-4 ml-1">
                        <div class="col-12">
                            <button (click)="clear()" class="btn btn-primary btn-sm mr-1" data-bs-toggle="modal" data-bs-target="#addDocumentFormHeaderItem" rippleEffect
                                title="Yeni Doküman Verisi">
                                <i data-feather="plus"></i><span class="d-none d-sm-inline-block"></span>
                            </button>
                            <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect
                                title="Excele Aktar">
                                <i data-feather="file-text" class=""></i>
                            </button>
                        </div>
                    </div>
                </div>

                <table class="table table-hover table-bordered" id="excel-table" style="display:none;">
                    <thead>
                        <th *ngFor="let item of headerList">
                            {{item}}
                        </th>

                    </thead>
                    <tbody>
                        <tr *ngFor="let row of headerData; let rowIndex = index">
                            <td *ngFor="let value of row.valueDatas; let colIndex = index">
                              {{initialInputData[rowIndex][colIndex]}}
                            </td>
                        </tr>
                    </tbody>
                </table>


                <ngx-datatable
                class="bootstrap core-bootstrap"
                [rows]="filteredHeaderData"
                [columns]="[]"
                [columnMode]="'force'"
                [headerHeight]="50"
                [footerHeight]="50"
                [limit]="basicSelectedOption"
                [rowHeight]="58"                    
                [scrollbarH]="true"
              >
                <!-- Dynamically Generated Columns -->
                <ngx-datatable-column *ngFor="let col of headerList; let colIndex = index" [name]="col" [prop]="'valueDatas[' + colIndex + ']'" [width]="250">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <input class="form-control" [(ngModel)]="inputData[filteredHeaderData.indexOf(row)][colIndex]" type="text" />
                  </ng-template>
                </ngx-datatable-column>
              
                <!-- Actions Column -->
                <ngx-datatable-column name="İşlemler" [sortable]="false" [width]="200">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div *ngIf="row.userId==currentUserId || userRole=='Admin'">
                        <button [disabled]="pendingUpdate==true"  (click)="updateItemsInRowIndex(row.rowCount, row.index, row.rowVersion)" class="btn btn-warning btn-sm mr-1">
                            <i class="pi pi-pencil"></i>
                          </button>
                          <button [disabled]="pendingDelete==true" (click)="deleteItemsInRowIndex(row.rowCount,row.rowVersion)" class="btn btn-danger btn-sm">
                            <i class="pi pi-trash"></i>
                          </button>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>


            </core-card-snippet>
        </section>
    </div>
</div>





<div class="modal fade" id="addDocumentFormHeaderItem" tabindex="-1" aria-labelledby="staticBackdropLabel"
aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Doküman Formu Verisi Ekle</h1>
                <button type="button" id="headerDismissModalButton" class="btn btn-secondary" data-bs-dismiss="modal"><i
                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
            </div>
            <form class="" #AddDocumentFormHeaderForm="ngForm">
            <div class="modal-body">
                <div class="form-group">
                    <div *ngFor="let row of addDataForInput; let rowIndex = index" class="form-group">
                        <div class="row mb-2" *ngFor="let col of row; let colIndex = index">
                          <label for="input-{{ rowIndex }}-{{ colIndex }}">{{ headerList[colIndex] }}</label>
                          <input
                            type="text"
                            [(ngModel)]="addDataForInputInputs[rowIndex][colIndex]"
                            class="form-control"
                            id="input-{{ rowIndex }}-{{ colIndex }}"
                            name="input-{{ rowIndex }}-{{ colIndex }}"
                          />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div>
                    <button [disabled]="pendingInsert==true" type="button" rippleEffect class="btn btn-success mr-1" [disabled]="!AddDocumentFormHeaderForm.valid"
                    (click)="insertHeaderItem()">Kaydet</button>
                </div>
            </div>   
            </form>     
        </div>
    </div>
</div>