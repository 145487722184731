import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { AnswerType } from 'app/evo/models/answertype';
import { BillType } from 'app/evo/models/billtype';
import { Partner } from 'app/evo/models/partner';
import { LocationType } from 'app/evo/models/locationtype';
import { Module } from 'app/evo/models/module';
import { Priority } from 'app/evo/models/priority';
import { Status } from 'app/evo/models/status';
import { TicketActivity } from 'app/evo/models/ticketactivity';
import { TicketType } from 'app/evo/models/tickettype';
import { TicketUser } from 'app/evo/models/ticketuser';
import { TicketUserType } from 'app/evo/models/ticketusertype';
import { User } from 'app/evo/models/user';

import { ErrorService } from 'app/evo/services/error.service';
import { EvoPartnerUserService } from 'app/evo/services/evo-customer-user.service';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoModuleService } from 'app/evo/services/evo-module.service';
import { EvoTicketActivityService } from 'app/evo/services/evo-ticket-activity.service';
import { EvoTicketUserService } from 'app/evo/services/evo-ticketuser.service';
import { EvoTicketUserTypeService } from 'app/evo/services/evo-ticketusertype.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';
import { EvoTicketService } from 'app/evo/services/evo-ticket.service';
import { Ticket } from 'app/evo/models/ticket';
import { Observable, of } from 'rxjs';
import { StatusFilter } from 'app/evo/models/statusfilter';
import { EvoPermissionService } from 'app/evo/services/evo-permission.service';
import { EvoPermissionUserService } from 'app/evo/services/evo-permission-user.service';
import { PermissionUser } from 'app/evo/models/permissionuser';
import { Permission } from 'app/evo/models/permission';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-evo-ticketuser-list',
  templateUrl: './evo-ticketuser-list.component.html',
  styleUrls:['./evo-ticketuser-list.component.scss']
})


export class EvoTicketUserListComponent implements OnInit {

  startDateControl: FormControl;
  userRole: string;
  userName: string;

  constructor(private ticketUserService: EvoTicketUserService,
    private ticketService: EvoTicketService,
    private ticketActivityService: EvoTicketActivityService,
    private userService: EvoUserService,
    private ticketUserTypeService: EvoTicketUserTypeService,
    private partnerService: EvoPartnerService,
    private partnerUserService: EvoPartnerUserService,
    private permissionService: EvoPermissionService,
    private permissionUserService: EvoPermissionUserService,


    private moduleService: EvoModuleService,
    private informationService: InformationService,
    private helperService: HelperService,
    private decodeService: DecodeService,
    private errorService: ErrorService,
    public formatter: NgbDateParserFormatter,
  ) {
    this.startDateControl = new FormControl(new Date().toISOString().slice(0, 16));
  }

  public basicSelectedOption: number = 10;

  filterText: string = "";
  fromDate: Date;
  toDate: Date;
  deletedTicketUserId: number;

  followerInformation: boolean = false;
  firstLevelRestriction: boolean;
  secondLevelRestriction: boolean;

  users: User[] = [];
  tickets: Ticket[] = [];
  modules: Module[] = [];
  consultants: User[] = [];
  selectedUsers: User[] = [];
  partners: Partner[] = [];
  permissions: Permission[] = [];
  ticketUsers: TicketUser[] = [];
  selectedStatuses: any[] = [];
  selectedPartners: Partner[] = [];
  permissionUsers: PermissionUser[] = [];
  selectedPriorities: Priority[] = [];
  ticketUserTypes: TicketUserType[] = [];
  ticketUserTypesByTicketId: TicketUserType[] = [];

  newTicketUsers: any;
  dashboardFilter: any;
  fullnames: any;
  filter: any;

  user: User = new User();
  module: Module = new Module();
  ticket: Ticket = new Ticket();
  partner: Partner = new Partner();
  ticketUser: TicketUser = new TicketUser();
  ticketUserType: TicketUserType = new TicketUserType();

  selectedTicket: Ticket = new Ticket();
  selectedTicketUser: TicketUser = new TicketUser();
  ticketActivity: TicketActivity = new TicketActivity();

  public hoveredDate: any | null = null;
  public fromDateExample: any = null;
  public toDateExample: any = null;

  date: Date = new Date();
  toDay: Date = new Date();
  now: Date = new Date();
  day: number = this.now.getDate();
  month: number = this.now.getMonth() + 1;
  year: number = this.now.getFullYear();
  hour: number = this.now.getHours();
  minute: number = this.now.getMinutes();


  today: string = new Date(this.year, this.month - 1, this.day, this.hour, this.minute).toLocaleDateString() + ' ' + new Date(this.year, this.month - 1, this.day, this.hour, this.minute).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  sortOptions: any[] = [
    { prop: 'trackingNumber', dir: 'asc' },
  ];

  partnerId: number = 0;
  priorityId: number = 0;
  statusId: number = 0;
  userId: number = 0;
  myId: number;

  public customFooterDPdata: NgbDateStruct;
  public basicTP = { hour: 13, minute: 30 };

  public selectCustomHeaderFooterSelected = [];
  StartDate: Date;
  locationType: LocationType = new LocationType();

  locationTypes: LocationType[] = [
    { id: 3, name: "Yerinde" },
    { id: 4, name: "Uzaktan" },
  ];
  priorities: Priority[] = [
    { id: 1, name: "Yüksek" },
    { id: 2, name: "Normal" },
    { id: 3, name: "Düşük" },
  ];
  changedStatusId: number;
  statusForChange: Status[] = [
    { id: 2, name: "Danışmandan Cevap Bekleniyor" },
    { id: 8, name: "Müşteriden Cevap Bekleniyor" },
    { id: 4, name: "Tamamlandı" },
    { id: 5, name: "İptal Edildi" },
  ];

  public selectMulti: Observable<any[]>;

  statusFilter: StatusFilter[] = [
    { id: "1-1", name: "Yeni Oluşturuldu", typeName: "Talep Durumu" },
    { id: "2-1", name: "Danışmandan Cevap Bekleniyor", typeName: "Talep Durumu" },
    { id: "8-1", name: "Müşteriden Cevap Bekleniyor", typeName: "Talep Durumu" },
    { id: "10-1", name: "Test Sürecinde", typeName: "Talep Durumu" },
    { id: "4-1", name: "Tamamlandı", typeName: "Talep Durumu" },
    { id: "5-1", name: "İptal Edildi", typeName: "Talep Durumu" },
    { id: "22-1", name: "Efor Onayı Bekleniyor", typeName: "Talep Durumu" },

    { id: "4-2", name: "Faturalanmadı", typeName: "Fatura Durumu" },
    { id: "5-2", name: "Faturalandı", typeName: "Fatura Durumu" },
    { id: "6-2", name: "Feragat Edildi", typeName: "Fatura Durumu" },

    { id: "1-3", name: "Destek Talebi", typeName: "Talep Tipi" },
    { id: "2-3", name: "Müşteri Planı", typeName: "Talep Tipi" },
    { id: "3-3", name: "Proje Planı", typeName: "Talep Tipi" },
  ];

  statuses: Status[] = [
    { id: 1, name: "Yeni Oluşturuldu" },
    { id: 2, name: "Danışmandan Cevap Bekleniyor" },
    { id: 8, name: "Müşteriden Cevap Bekleniyor" },
    { id: 10, name: "Test Sürecinde" },
    { id: 4, name: "Tamamlandı" },
    { id: 5, name: "İptal Edildi" },
    { id: 22, name: "Efor Onayı Bekleniyor" },

  ];
  billTypes: BillType[] = [
    { id: 4, name: "Faturalanmadı" },
    { id: 5, name: "Faturalandı" },
    { id: 6, name: "Feragat Edildi" }
  ];
  ticketTypes: TicketType[] = [
    { id: 1, name: "Destek Talebi" },
    { id: 2, name: "Müşteri Planı" },
    { id: 3, name: "Proje Planı" },
  ];
  answerTypes: AnswerType[] = [
    { id: 1, name: "Çözüm Önerildi" },
    { id: 2, name: "Cevap Bekleniyor" },
    { id: 3, name: "Mesaj Gönder" },
  ];
  me: User = new User();
  public selectCustomHeaderFooter = this.priorities;

  public selectMultiSelectedStatus;
  public selectMultiSelectedUser;
  public selectMultiStatuses: Observable<any[]>;
  public selectMultiUsers: Observable<any[]>;

  overlayPosition = { top: 0, left: 0 };
  overlayContent: string = '';
  isOverlayVisible: boolean = false;

  ngOnInit(): void {
    this.myId = this.decodeService.getUserId();
    this.userRole = this.decodeService.getRole();
    this.userName = this.decodeService.getUserName();
    this.selectMultiStatuses = of(this.statuses);
    this.getPermissions();
    this.ticketService.getAll(200,0).subscribe(res=>{
    })
    const storedFilter = localStorage.getItem('filter');
    if (storedFilter && storedFilter !== 'undefined' && storedFilter !== 'null') {
      this.filterFromDashboard(storedFilter);
      localStorage.removeItem('filter');
    }
  }

  getUserListFroFilters() {
    this.userService.getList().subscribe((items: any) => {
      this.consultants = items.data;
      const index = this.consultants.findIndex(user => user.id === this.myId);
      if (index !== -1) {
        this.consultants.splice(index, 1);
      }
    });

    this.userService.getListForFilter().subscribe((items: any) => {
      this.users = items.data;

      const index = this.users.findIndex(user => user.id === this.myId);
      if (index !== -1) {
        const currentUser = this.users[index];
        this.users.splice(index, 1);
        this.users.unshift(currentUser);
        this.selectMultiUsers = of(this.users);
      } else {
        this.userService.get(this.myId).subscribe((user: any) => {
          this.me = user.data;
          this.users.unshift(this.me);
          this.selectMultiUsers = of(this.users);
        });
      }
    });
  }

  getListForFilter() {
    this.ticketUserTypeService.getList().subscribe((items: any) => {
      this.ticketUserTypes = items.data;
    });

    if (!this.firstLevelRestriction && !this.secondLevelRestriction) {
      this.partnerService.getList().subscribe((items: any) => {
        this.partners = items.data;
      });
    }

    this.moduleService.getList().subscribe((items: any) => {
      this.modules = items.data;
    });
  }

  getPermissions() {
    this.permissionService.getList().subscribe((items: any) => {
      this.permissions = items.data;
      this.checkPermissionUsers();
      this.getUserListFroFilters();
    });
  }

  checkPermissionUsers() {
    this.permissionUserService.getPermissionUserListByUserId(this.myId).subscribe((items: any) => {
      this.permissionUsers = items.data;

      if (this.permissionUsers.some(user => user.permissionId === 1)) {
        this.firstLevelRestriction = true;
      } else {
        this.firstLevelRestriction = false;
      }

      if (this.permissionUsers.some(user => user.permissionId === 2)) {
        this.secondLevelRestriction = true;
      } else {
        this.secondLevelRestriction = false;
      }
      //this.firstCheckForDashboard();
      this.tableByFiltersId();
      this.getListForFilter();
    });
  }
  firstCheckForDashboard() {
    this.selectMultiStatuses = of(this.statuses);
    this.tableByFiltersId();

    const storedFilter = localStorage.getItem('filter');

    if (storedFilter && storedFilter !== 'undefined' && storedFilter !== 'null') {
      this.filterFromDashboard(storedFilter);
      localStorage.removeItem('filter');
    }
  }

  exportExcel() {
    let element = document.getElementById("table-excel");
    let title = "Ataması Yapılmış Talepler";
    this.helperService.exportExcel(element, title);
  }

  delete(id: number) {
    this.ticketUserService.getDto(id).subscribe((item: any) => {
      item.data.deletedBy = this.myId;
      item.data.updatedBy = 0;

      this.ticketUserService.delete(item.data).subscribe((res: any) => {
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.list();
        }, 1000);
      });
    });
  }

  saveTicketUser(data: TicketUser) {
    this.selectedTicketUser = data;
  }
  saveTicket(data: number) {
    this.ticketService.get(data).subscribe((res: any) => {
      this.selectedTicket = res.data;
    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }

  addActivity(startDate: string, description: string, time: string) {
    let today;

    if (startDate == this.today) {
      today = new Date();
    } else {
      today = new Date(startDate);
    }

    this.ticketActivity = {
      id: 0,
      startDate: today,
      time: parseInt(time),
      description: description,
      endDate: new Date(),
      userName: "",
      ticketId: this.selectedTicketUser.ticketId,
      userId: this.myId,
      locationTypeId: this.locationType.id,
      approvementId: 0,
      isApprovement: true,
      ticketStatusId: 0,
      ticketStatusName: "",
      ticketBillTypeId: 0,
      ticketBillTypeName: "",
      ticketSubject: "",
      billTypeId: 0,

      locationTypeName: "",
      trackingNumber: "",
      moduleId: this.module.id,
      moduleName: "",
      partnerId: this.selectedTicketUser.partnerId,
      partnerName: "",
      partnerTicketNo: "",

      createdBy: this.myId,
      updatedBy: 0,
      deletedBy: 0,
    }
    this.ticketActivityService.add(this.ticketActivity).subscribe((res: any) => {
      this.informationService.add(res.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 1000);
    });
  }

  getTicketUserTypeList(ticketId: number) {
    this.ticketUserTypeService.getListByTicketId(ticketId).subscribe((res: any) => {
      this.ticketUserTypesByTicketId = res.data;
      for (let item = 0; item < this.ticketUserTypesByTicketId.length; item++) {
        if (this.myId == this.ticketUserTypesByTicketId[item].userId) {
          this.followerInformation = true;
          this.ticketUserType = this.ticketUserTypesByTicketId[item];
          break;
        }
      }
    });
  }

  updateTicketUser() {
    this.selectedTicketUser = {
      id: this.selectedTicketUser.id,
      ticketId: this.selectedTicketUser.ticketId,
      userId: this.selectedTicketUser.userId,
      ticketUserTypeId: this.selectedTicketUser.typeId,
      trackingNumber: "",
      userFirstName: "",
      userLastName: "",
      typeId: 0,
      typeName: "",
      subject: "",
      priorityId: 0,
      statusId: 0,
      departmentId: 0,
      departmentName: "",
      ticketTypeId: 0,
      ticketTypeName: "",
      expectedTime: 0,
      partnerId: this.selectedTicketUser.partnerId,
      partnerName: this.selectedTicketUser.partnerName,
      partnerTicketNo: "",
      information: this.selectedTicketUser.information,
      projectId: this.selectedTicketUser.projectId,
      projectName: this.selectedTicketUser.projectName,

      moduleId: this.selectedTicketUser.moduleId,
      moduleName: this.selectedTicketUser.moduleName,

      createdBy: 0,
      createdDate: new Date(),
      createdByUserName: "",
      updatedBy: this.myId,
      updatedDate: new Date(),
      deletedBy: 0,

    }
    this.ticketUserService.update(this.selectedTicketUser).subscribe((res: any) => {
      this.informationService.add(res.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }

  updateTicketStatus() {
    this.selectedTicket.updatedBy = this.myId;
    this.selectedTicket.deletedBy = 0;
    this.ticketService.update(this.selectedTicket).subscribe((res: any) => {
      this.informationService.update(res.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }

  filterFromDashboard(storedFilter: any) {
    this.dashboardFilter = JSON.parse(storedFilter);

    if (this.dashboardFilter.statusId) {
      const selectedStatus = this.statuses.find(status => status.id === this.dashboardFilter.statusId);
      this.selectMultiSelectedStatus = [{ name: selectedStatus.name }];
      this.selectedStatuses = [this.dashboardFilter.statusId + "-1"];
      this.tableByFiltersId();
    }
    else {
      this.userService.get(this.myId).subscribe((res: any) => {
        const selectedUser = res.data;
        this.selectMultiSelectedUser = [{ name: selectedUser.firstName + " " + selectedUser.lastName }];
        this.selectedUsers = [selectedUser.id];
        this.tableByFiltersId();
      });
    }
  }

  onSort(event) {
    const rows = [...this.newTicketUsers];
    const sort = event.sorts[0];
    rows.sort((a, b) => {
      return a[0][sort.prop].toString().localeCompare(b[0][sort.prop]) * (sort.dir === 'desc' ? -1 : 1);
    });
    this.newTicketUsers = rows;
  }

  list() {
    if ((this.userRole == "Admin" || this.userRole == "Consultant" || this.userRole == "ConsultantUser") &&
      (this.firstLevelRestriction == false && this.secondLevelRestriction == false)) {
      this.ticketUserService.getList().subscribe((items: any) => {
        this.ticketUsers = items.data;
    
        this.newTicketUsers = Object.values(this.ticketUsers.reduce((acc, ticket) => {
          if (!acc[ticket.trackingNumber]) {
            acc[ticket.trackingNumber] = [];
          }
          acc[ticket.trackingNumber].push(ticket);
          return acc;
        }, {}));
        this.informationService.list(items.message)
      }, (err: any) => {
        this.errorService.errorHandler(err)
      });
      
    }
    if ((this.userRole == "Customer" || this.userRole == "CustomerUser") &&
      (this.firstLevelRestriction == false && this.secondLevelRestriction == false)) {
      this.partnerUserService.getByUserId(this.myId).subscribe((res: any) => {
        this.partnerUserService.getMyPartnerTicketUserList(res.data.partnerId).subscribe((res: any) => {
          this.ticketUsers = res.data;
          this.newTicketUsers = Object.values(this.ticketUsers.reduce((acc, ticket) => {
            if (!acc[ticket.trackingNumber]) {
              acc[ticket.trackingNumber] = [];
            }
            acc[ticket.trackingNumber].push(ticket);
            return acc;
          }, {}));
        }, (err: any) => {
          this.errorService.errorHandler(err)
        });
      });
      this.partnerUserService.getByUserId(this.myId).subscribe((res: any) => {
        this.partnerUserService.getMyPartnerTicketList(res.data.partnerId).subscribe((res: any) => {
          this.tickets = res.data;
        });
      });
    }
    if (this.firstLevelRestriction && !this.secondLevelRestriction) {
      this.ticketUserService.restrictionForExternalSourceTicketUsers(this.myId).subscribe((items: any) => {
        this.ticketUsers = items.data;
        
        this.newTicketUsers = Object.values(this.ticketUsers.reduce((acc, ticket) => {
          if (!acc[ticket.trackingNumber]) {
            acc[ticket.trackingNumber] = [];
          }
          acc[ticket.trackingNumber].push(ticket);
          return acc;
        }, {}));
      });
    }
    if (this.secondLevelRestriction) {
      this.ticketUserService.getMyTicketUserList(this.myId).subscribe((items: any) => {
        this.ticketUsers = items.data;
      
        this.newTicketUsers = Object.values(this.ticketUsers.reduce((acc, ticket) => {
          if (!acc[ticket.trackingNumber]) {
            acc[ticket.trackingNumber] = [];
          }
          acc[ticket.trackingNumber].push(ticket);
          return acc;
        }, {}));
      });
    }
    
  }
  tableByFiltersId() {
    const isAdminOrConsultant = ["Admin", "Consultant", "ConsultantUser"].includes(this.userRole);
    const isCustomer = ["Customer", "CustomerUser"].includes(this.userRole);
    const isSecondLevelRestriction = this.secondLevelRestriction;
    const isExternalSourceRestriction = this.firstLevelRestriction;

    const filterItems = (items) => {
      let filteredItems = items;

      if (this.selectedPriorities.length > 0) {
        filteredItems = filteredItems.filter(item => this.selectedPriorities.includes(item.priorityId));
      }

      if (this.selectedStatuses.length > 0) {
        const filteredBy = { status: [], billType: [], ticketType: [] };

        for (const selectedStatus of this.selectedStatuses) {
          const [id, type] = selectedStatus.split("-").map(Number);
          filteredBy[type === 1 ? 'status' : type === 2 ? 'billType' : 'ticketType'].push(id);
        }

        for (const filterType in filteredBy) {
          if (filteredBy[filterType].length > 0) {
            filteredItems = filteredItems.filter(item => filteredBy[filterType].includes(item[`${filterType}Id`]));
          }
        }
      }

      if (this.selectedUsers.length > 0) {
        filteredItems = filteredItems.filter(item => this.selectedUsers.includes(item.userId));
      }

      if (this.selectedPartners.length > 0) {
        filteredItems = filteredItems.filter(item => this.selectedPartners.includes(item.partnerId));
      }

      if (this.fromDate && this.toDate) {
        filteredItems = filteredItems.filter(item =>
          new Date(item.createdDate) >= new Date(this.fromDate) &&
          new Date(item.createdDate) <= new Date(this.toDate)
        );
      }

      return Object.values(filteredItems.reduce((acc, ticket) => {
        if (!acc[ticket.trackingNumber]) {
          acc[ticket.trackingNumber] = [];
        }
        acc[ticket.trackingNumber].push(ticket);
        return acc;
      }, {}));
    };

    if ((isAdminOrConsultant) && (!isExternalSourceRestriction && !isSecondLevelRestriction)) {
      this.ticketUserService.getList().subscribe((items: any) => {
        this.newTicketUsers = filterItems(items.data);
      });
    }

    if (isCustomer && (!isExternalSourceRestriction && !isSecondLevelRestriction)) {
      this.partnerUserService.getByUserId(this.myId).subscribe((res: any) => {
        this.partnerUserService.getMyPartnerTicketUserList(res.data.partnerId).subscribe((res: any) => {
          this.newTicketUsers = filterItems(res.data);
        });
      });
    }

    if (isExternalSourceRestriction && !isSecondLevelRestriction) {
      this.ticketUserService.restrictionForExternalSourceTicketUsers(this.myId).subscribe((items: any) => {
        this.newTicketUsers = filterItems(items.data);
      });
    }

    if (isSecondLevelRestriction) {
      this.ticketUserService.getMyTicketUserList(this.myId).subscribe((items: any) => {
        this.newTicketUsers = filterItems(items.data);
      });
    }
  }


  checkType(value: any): value is NgbDate {
    return value instanceof NgbDate;
  }

  tableByDates() {
    var tempDate: any = {};
    var datePipe = new DatePipe("en-US");

    if (this.fromDateExample instanceof NgbDate) {
      tempDate = { day: this.fromDateExample.day, month: this.fromDateExample.month - 1, year: this.fromDateExample.year };
    }
    else {
      var fromDateTransformed = new Date(datePipe.transform(this.fromDateExample, 'yyyy-MM-dd'));
      tempDate = { day: fromDateTransformed.getDate(), month: fromDateTransformed.getMonth(), year: fromDateTransformed.getFullYear() }
    }

    this.fromDate = new Date(tempDate.year, tempDate.month, tempDate.day, 0, 0, 0);

    if (this.toDateExample == null)
      this.toDate = new Date(tempDate.year, tempDate.month, tempDate.day, 23, 59, 59);
    else
      var tempToDate: any = {};

    if (this.toDateExample instanceof NgbDate) {
      tempToDate = { day: this.toDateExample.day, month: this.toDateExample.month - 1, year: this.toDateExample.year };
    }
    else {
      var toDateTransformed = new Date(datePipe.transform(this.toDateExample, 'yyyy-MM-dd'));
      tempToDate = { day: toDateTransformed.getDate(), month: toDateTransformed.getMonth(), year: toDateTransformed.getFullYear() }
    }

    this.toDate = new Date(tempToDate.year, tempToDate.month, tempToDate.day, 23, 59, 59);


    if (!this.fromDate || !this.toDate) {
      this.list();
    } else {

      if ((this.userRole == "Admin" || this.userRole == "Consultant" || this.userRole == "ConsultantUser") &&
        (this.firstLevelRestriction == false && this.secondLevelRestriction == false)) {
        this.ticketUserService.getList().subscribe((items: any) => {
          this.filterAndGroupItems(items.data);
          this.informationService.list("Talep Bilgileri Başarılı Şekilde Listelendi")
        });
      } if ((this.userRole == "Customer" || this.userRole == "CustomerUser") &&
        (this.firstLevelRestriction == false && this.secondLevelRestriction == false)) {
        this.partnerUserService.getByUserId(this.myId).subscribe((res: any) => {
          this.partnerUserService.getMyPartnerTicketUserList(res.data.partnerId).subscribe((res: any) => {
            this.filterAndGroupItems(res.data);
          });
        });
      }
      if (this.firstLevelRestriction && !this.secondLevelRestriction) {
        this.ticketUserService.restrictionForExternalSourceTicketUsers(this.myId).subscribe((items: any) => {
          this.filterAndGroupItems(items.data);
        });
      }
      if (this.secondLevelRestriction) {
        this.ticketUserService.getMyTicketUserList(this.myId).subscribe((items: any) => {
          this.filterAndGroupItems(items.data);
        });
      }
      this.tableByFiltersId();
    }
  }

  filterAndGroupItems(items: any[]) {
    let filteredItems = items;

    if (this.selectedPriorities.length > 0) {
      filteredItems = filteredItems.filter(item => this.selectedPriorities.includes(item.priorityId));
    }

    if (this.selectedStatuses.length > 0) {
      filteredItems = filteredItems.filter(item => this.selectedStatuses.includes(item.statusId));
    }

    if (this.selectedUsers.length > 0) {
      filteredItems = filteredItems.filter(item => this.selectedUsers.includes(item.userId));
    }

    if (this.selectedPartners.length > 0) {
      filteredItems = filteredItems.filter(item => this.selectedPartners.includes(item.partnerId));
    }

    if (this.fromDate && this.toDate) {
      filteredItems = filteredItems.filter(item =>
        new Date(item.createdDate) >= this.fromDate &&
        new Date(item.createdDate) <= this.toDate
      );
    }

    this.newTicketUsers = Object.values(filteredItems.reduce((acc, ticket) => {
      if (!acc[ticket.trackingNumber]) {
        acc[ticket.trackingNumber] = [];
      }
      acc[ticket.trackingNumber].push(ticket);
      return acc;
    }, {}));

  }





  isHovered(date: NgbDate) {
    return (
      this.fromDateExample && !this.toDateExample && this.hoveredDate && date.after(this.fromDateExample) && date.before(this.hoveredDate)
    );
  }
  isInside(date: NgbDate) {
    return this.toDateExample && date.after(this.fromDateExample) && date.before(this.toDateExample);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDateExample) ||
      (this.toDateExample && date.equals(this.toDateExample)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDateExample && !this.toDateExample) {
      this.fromDateExample = date;
    } else if (this.fromDateExample && !this.toDateExample && date && date.after(this.fromDateExample)) {
      this.toDateExample = date;
    } else {
      this.toDateExample = null;
      this.fromDateExample = date;
    }
    this.tableByDates();
  }


  onMouseEnter(event: MouseEvent, description: string): void {
    console.log(description);

    this.overlayContent = description; // Set the overlay content

    // Update the position of the overlay based on mouse coordinates
    this.updateOverlayPosition(event);
    this.isOverlayVisible = true;
  }

  // Update the overlay position relative to the mouse position
  onMouseMove(event: MouseEvent): void {
    if (this.isOverlayVisible) {
      this.updateOverlayPosition(event);
    }
  }

  // Update the position of the overlay based on mouse coordinates
  updateOverlayPosition(event: MouseEvent): void {

    const isMenuCollapsed = localStorage.getItem("sidebarCollapsed");

    var mouseX = event.clientX;
    var mouseY = event.clientY;

    if(isMenuCollapsed == "true"){
        mouseX = event.clientX-50;
  
    }
    else{
        mouseX = event.clientX-200;

    }    
    

    this.overlayPosition.left = mouseX + window.scrollX;
    this.overlayPosition.top = mouseY + window.scrollY;
  }

  // Hide the overlay when mouse leaves the description part
  onMouseLeave(): void {
    this.isOverlayVisible = false;
  }


}
