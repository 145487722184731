<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="ngx-datatable-kitchen-sink">
            <core-card-snippet>
                <h4 class="card-title">Dokümanların Listesi</h4>
                <div class="row">
                    <div class="firstRow mt-1">
                        <div style="margin-left:9px; margin-bottom: 20px;">
                            <div class="col-12">

                                <select class="form-control form-select" [(ngModel)]="basicSelectedOption">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="search mt-1">
                        <div class="col-12">
                            <input type="text" class="form-control mr-1" name="filterText" (keyup)="tableByFiltersId()" placeholder="Doküman Arayın.."
                                [(ngModel)]="filterText" [ngModelOptions]="{standalone:true}">
                        </div>
                    </div>
                </div>
                <div class="row  mb-2 mr-1">
                    <div class="col-4 ml-1">
                        <div class="col-12">
                            <ng-container *ngIf="userRole=='Admin'|| userRole=='Consultant'">

                                <button (click)="clear()" class="btn btn-primary btn-sm mr-1" data-bs-toggle="modal" data-bs-target="#addDocumentForm" rippleEffect
                                    title="Yeni Doküman">
                                    <i data-feather="plus"></i><span class="d-none d-sm-inline-block"></span>
                                </button>
                            </ng-container>

                            <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect
                                title="Excele Aktar">
                                <i data-feather="file-text" class=""></i>
                            </button>
                        </div>
                    </div>
                </div>

                <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
                    <thead>
                        <th>Doküman No</th>
                        <th>Doküman Adı</th>
                        <th>Revizyon Tarihi</th>
                        <th>Revizyon No</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of filteredDocumentForms">
                            <td>{{item.documentNo}}</td>
                            <td>{{item.documentName}}</td>
                            <td>{{item.revisionDate | date:'dd.MM.yyyy HH:mm'}}</td>
                            <td>{{item.revisionNo}}</td>
                        </tr>
                    </tbody>
                </table>
                <ngx-datatable [rows]="filteredDocumentForms" [rowHeight]="58" class="bootstrap core-bootstrap"
                    [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
                    [limit]="basicSelectedOption">
                    <ngx-datatable-column name="Doküman No" prop="documentNo" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.documentNo}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Doküman Adı" prop="documentName" [width]="300">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.documentName}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Yayın Tarihi" prop="publicationDate" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.publicationDate | date:'dd.MM.yyyy HH:mm'}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Onay İzni" prop="requiresAdminApproval" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.requiresAdminApproval == false ? "Gerekmiyor" : "Gerekiyor" }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Onay Durumu" prop="approvalString" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            <div *ngIf="row.pendingApprovalCount>0" style="cursor:pointer; color:orange;" (click)="getApprovalListByFormId(row.id)" data-bs-toggle="modal" data-bs-target="#formApprovalListModal">
                                {{row.approvalString}}
                            </div>
                            <div *ngIf="row.pendingApprovalCount==0" style="cursor:pointer; color:gray;" (click)="getApprovalListByFormId(row.id)" data-bs-toggle="modal" data-bs-target="#formApprovalListModal">
                                {{row.approvalString}}
                            </div> 
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Revizyon No" prop="revisionNo" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.revisionNo}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Revizyon Tarihi" prop="revisionDate" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.revisionDate | date:'dd.MM.yyyy HH:mm'}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column *ngIf="userRole=='Admin'||userRole=='Consultant'" name="Onay İşlemleri" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            <button [title]="'Onay İşlemlerine Aç'" (click)="get(row.id);" data-bs-toggle="modal" data-bs-target="#changeAdminApprovalStatus" class="btn btn-info btn-sm mr-1">
                                <i class="pi pi-check-circle"></i>
                            </button>                        
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Diğer İşlemler" [width]="320" [sortable]="false">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            <ng-container *ngIf="userRole=='Admin'|| userRole=='Consultant'">
                                <button [title]="'Güncelle'" (click)="get(row.id);" data-bs-toggle="modal" data-bs-target="#addDocumentForm" class="btn btn-warning btn-sm mr-1">
                                    <i class="pi pi-pencil"></i>
                                </button>
                                <button [title]="'Dökümana Başlık Ekle'" (click)="selectedDocumentFormId=row.id;get(row.id);getDocumentFormHeaders(row.id);" data-bs-toggle="modal" data-bs-target="#documentFormHeaderList" class="btn btn-info btn-sm mr-1">
                                    <i class="pi pi-file"></i>
                                </button>
                            </ng-container>

                            <button [title]="'Dökümana Veri Ekle'" (click)="routeToDocumentFormItems(row.id);" class="btn btn-success btn-sm mr-1">
                                <i class="pi pi-file-plus"></i>
                            </button>
                            
                            <ng-container *ngIf="userRole=='Admin'||userRole=='Consultant'">
                                <button [title]="'Sil'" (click)="selectedDocumentForm=row;" data-bs-toggle="modal" data-bs-target="#deleteDocumentForm" class="btn btn-danger btn-sm">
                                    <i class="pi pi-trash"></i>
                                </button>
                            </ng-container>

                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </core-card-snippet>
        </section>
    </div>
</div>


<div class="modal fade" id="addDocumentForm" tabindex="-1" aria-labelledby="staticBackdropLabel"
aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Doküman Formu Ekle</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                <form class="" #AddDocumentForm="ngForm">
                    <div class="modal-body">
                        <div class="form-group">
                            <div class="row mb-2">
                                <label for="name-vertical">Doküman No</label>
                                <input type="text" [(ngModel)]="documentForm.documentNo" class="form-control" name="txtDocumentNo" required
                                    #txtDocumentNo="ngModel" ngModel>
                                <small class="text-danger"
                                    *ngIf="txtDocumentNo.invalid&& txtDocumentNo.touched">Doküman Numarası boş olamaz.</small>
                            </div>
                            <div class="row mb-2">
                                <label for="name-vertical">Doküman Adı</label>
                                <input type="text" [(ngModel)]="documentForm.documentName" class="form-control" name="txtDocumentName" required
                                    #txtDocumentName="ngModel" ngModel>
                                <small class="text-danger"
                                    *ngIf="txtDocumentName.invalid&& txtDocumentName.touched">Doküman Adı boş olamaz.</small>
                            </div>
                            <div class="row mb-2">
                                <label for="name-vertical">Yayın Tarihi</label>
                                <input type="datetime-local" [(ngModel)]="documentForm.publicationDate" class="form-control" name="txtPublicationDate"
                                    #txtPublicationDate="ngModel" ngModel>

                            </div>
                            <div class="row mb-2" *ngIf="documentForm.id != 0">
                                <label for="name-vertical">Revizyon No</label>
                                <input type="text" [(ngModel)]="documentForm.revisionNo" class="form-control" name="txtRevisionNoAdd" required
                                    #txtRevisionNoAdd="ngModel" ngModel>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div>
                            <button type="button" rippleEffect class="btn btn-success mr-1" [disabled]="!AddDocumentForm.valid"
                            (click)="saveDocumentForm()">Kaydet</button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="deleteDocumentForm" tabindex="-1" aria-labelledby="staticBackdropLabel"
aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Doküman Formunu Sil</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
            </div>
            <form class="" #deleteDocumentForm="ngForm">
                <div class="modal-body">
                    Bu Kaydı Silmek İstediğinize Emin Misiniz?
                </div>
                <div class="modal-footer">
                    <div>
                        <button [disabled]="!deleteDocumentForm.valid" type="button" class="btn btn-danger" data-bs-dismiss="modal"
                            (click)="delete()"><i data-feather="trash"
                                class="mr-50"></i>Sil</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


<div class="modal fade" id="documentFormHeaderList" style="overflow-y: scroll;" tabindex="-1" aria-labelledby="staticBackdropLabel"
aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Doküman Formu Başlık Listesi</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                <div class="row mb-2">
                    <div class="col-12">
                        <button class="btn btn-primary btn-sm mr-1" (click)="clearHeader()" data-bs-toggle="modal" data-bs-target="#addDocumentFormHeader" rippleEffect
                        title="Yeni Doküman Başlığı">
                        <i data-feather="plus"></i><span class="d-none d-sm-inline-block"></span>
                        </button>
                    </div>
                </div>
                <table class="table table-hover table-bordered" id="documentHeaderTable">
                    <thead>
                        <th>Ad</th>
                        <th>İşlemler</th>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="documentFormHeaders.length>0">
                            <tr *ngFor="let item of documentFormHeaders">
                                <td>{{item.name}}</td>
                                <td>
                                    <button (click)="getDocumentFormHeader(item);" data-bs-target="#addDocumentFormHeader" data-bs-toggle="modal" class="btn btn-warning btn-sm mr-1">
                                        <i class="pi pi-pencil"></i>
                                    </button>
                                    <button (click)="get(item.id);selectedDocumentFormHeader=item;" data-bs-toggle="modal" data-bs-target="#deleteDocumentFormHeader" class="btn btn-danger btn-sm">
                                        <i class="pi pi-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="documentFormHeaders.length==0">
                            <tr *ngFor="let item of bulkDocumentFormHeaders">
                                <td>{{item.name}}</td>
                                <td>
                                    <button (click)="deleteFromBulkHeaders(item.id)" class="btn btn-danger btn-sm">
                                        <i class="pi pi-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-container>

                    </tbody>
                </table>
            </div>
            <div *ngIf="documentFormHeaders.length==0">
                <div class="modal-footer">
                    <div>
                        <button type="button" rippleEffect class="btn btn-success mr-1"
                        (click)="addDocumentFormHeaderInBulk()">Toplu Kaydet</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="formApprovalListModal" style="overflow-y: scroll;" tabindex="-1" aria-labelledby="staticBackdropLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Doküman Formu Onay Listesi</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                <div class="row">

                    <div class="filter mt-1 ml-1">
                        <div class="form-group">
                            <ng-select [multiple]="true" [items]="approvalTypeFilters" [(ngModel)]="selectedApprovalTypes"
                                placeholder="Onay Tipi Seçiniz" bindLabel="name" bindValue="id"
                                (change)="approvalTableFilter()">
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <span class="ng-value-label">{{ item.name}}</span>
                                    <span class="ng-value-icon right" (click)="clear(item)"
                                        aria-hidden="true">×</span>
                                </ng-template>
                                <ng-template ng-header-tmp>
                                </ng-template>
                            </ng-select>
                            <br />
                        </div>
                    </div>
                    <div class="filter mt-1 ml-1" *ngIf="userRole=='Admin'">
                        <div class="form-group">
                            <ng-select [multiple]="true" [items]="users" [(ngModel)]="selectedUsers"
                                placeholder="Danışman Seçiniz" bindLabel="fullName" bindValue="id"
                                (change)="approvalTableFilter()">
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <span class="ng-value-label">{{ item.fullName}}</span>
                                    <span class="ng-value-icon right" (click)="clear(item)"
                                        aria-hidden="true">×</span>
                                </ng-template>
                                <ng-template ng-header-tmp>
                                </ng-template>
                            </ng-select>
                            <br />
                        </div>
                    </div>
                </div>
                <!-- Add horizontal scroll -->
                <div style="overflow-x: auto;">
                    <table class="table table-hover table-bordered" id="documentHeaderTable" style="table-layout: fixed; width: 100%;">
                        <thead>
                            <tr>
                                <th style="width: 100px;">Veri Satır No</th>
                                <th style="width: 150px;">İşlem Adı</th>
                                <th *ngIf="userRole=='Admin'" style="width: 150px;">İsteyen Adı</th>
                                <th style="width: 400px; word-wrap: break-word; white-space: normal;">Eski Değerler</th>
                                <th style="width: 400px; word-wrap: break-word; white-space: normal;">Yeni Değerler</th>                
                                <th style="width: 150px;">Onay Durumu</th>
                                <th style="width: 250px;">Onaylayan Adı</th>
                                <th style="width: 200px;">Onay Sebebi</th>
                                <th style="width: 150px;">Onay Tarihi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of filteredDocumentFormApprovalOperationList | paginate: { itemsPerPage: 10, currentPage: p };">
                                <td>{{item.rowId == 0 ? '-' : item.rowId}}</td>
                                <td>{{item.operationName == 'add' ? "Yeni" :(item.operationName == 'update' ? "Değişiklik" : "Silme")}}</td>
                                <td *ngIf="userRole=='Admin'">{{item.userName}}</td>
                                <td style="word-wrap: break-word; white-space: normal;">
                                    {{item.oldFormValuesString == null ? "-" : item.oldFormValuesString}}
                                </td>
                                <td style="word-wrap: break-word; white-space: normal;">
                                    {{item.newFormValuesString == null ? "-" : item.newFormValuesString}}
                                </td>                                
                                <td>
                                    <div *ngIf="item.approvalStatus==1" style="color:orange;">Onaylanmamış</div>
                                    <div *ngIf="item.approvalStatus==2" style="color:green;">Onaylandı</div>
                                    <div *ngIf="item.approvalStatus==3" style="color:red">Reddedildi</div>
                                </td>
                                <td>{{item.approverName}}</td>
                                <td>
                                    <div 
                                    class="description-container" 
                                    (mouseenter)="onMouseEnter($event,item.approvalCause)" 
                                    (mouseleave)="onMouseLeave()"
                                    (mousemove)="onMouseMove($event)">
                                    
                                        <div class="description-text" [innerHTML]="item.approvalCause"></div>
                                    
                                    </div>

                                </td>
                                <td>{{item.approvalDate | date:"dd.MM.yyyy HH:mm:ss"}}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div style="text-align:center;">
                        <pagination-controls previousLabel="Geri" nextLabel="İleri" (pageChange)="p = $event"></pagination-controls>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="deleteDocumentFormHeader" tabindex="-1" aria-labelledby="staticBackdropLabel"
aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Doküman Başlığı Sil</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                Bu Kaydı Silmek İstediğinize Emin Misiniz?        
            </div>
            <div class="modal-footer">
                <div>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                        (click)="deleteDocumentFormHeader()"><i data-feather="trash"
                            class="mr-50"></i>Sil</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="sendApprovalModal" tabindex="-1" aria-labelledby="staticBackdropLabel"
aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Doküman Onayı</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                Bu dokümanı onaya göndermek istediğinize emin misiniz?     
            </div>
            <div class="modal-footer">
                <div>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                        (click)="sendFormToApproval()"><i data-feather="check"
                            class="mr-50"></i>Gönder</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="changeAdminApprovalStatus" tabindex="-1" aria-labelledby="staticBackdropLabel"
aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Doküman Onayı</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                Bu dokümana her yeni veri eklemede onay gönderme senaryosunu onaylıyor musunuz?     
            </div>
            <div class="modal-footer">
                <div>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                        (click)="changeAdminApprovalRequirementStatus()"><i data-feather="check"
                            class="mr-50"></i>Gönder</button>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="addDocumentFormHeader" tabindex="-1" aria-labelledby="staticBackdropLabel"
aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Doküman Formu Başlığı Ekle</h1>
                <button type="button" id="headerDismissModalButton" class="btn btn-secondary" data-bs-target="#documentFormHeaderList" data-bs-toggle="modal"><i
                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
            </div>
            <form class="" #AddDocumentFormHeaderForm="ngForm">
            <div class="modal-body">
                <div class="form-group">
                    <div class="row mb-2">
                        <label for="name-vertical">Doküman Başlık Adı</label>
                        <input type="text" [(ngModel)]="documentFormHeader.name" class="form-control" name="txtDocumentFormHeaderName" required
                            #txtDocumentFormHeaderName="ngModel" ngModel>
                        <small class="text-danger"
                            *ngIf="txtDocumentFormHeaderName.invalid&& txtDocumentFormHeaderName.touched">Doküman Numarası boş olamaz.</small>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div>
                    <button type="button" rippleEffect class="btn btn-success mr-1" [disabled]="!AddDocumentFormHeaderForm.valid"
                    (click)="documentFormHeaders.length>0 ? saveDocumentFormHeader() : addToBulkHeaders()">Kaydet</button>
                </div>
            </div>   
            </form>     
        </div>
    </div>
</div>

<div *ngIf="isOverlayVisible" 
     class="description-overlay" 
     [innerHTML]="overlayContent"
     [ngStyle]="{ top: overlayPosition.top + 'px', left: overlayPosition.left + 'px' }">
</div>