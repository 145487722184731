<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="ngx-datatable-kitchen-sink">
            <core-card-snippet>
                <h4 class="card-title">Planların Listesi</h4>
                <div class="row">
                    <div class="firstRow mt-1">
                        <div style="margin-left:9px; margin-bottom: 20px;">
                            <div class="col-12">

                                <select class="form-control form-select" [(ngModel)]="basicSelectedOption">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="search mt-1">
                        <div class="col-12">
                            <input type="text" class="form-control mr-1" name="filterText" (ngModelChange)="tableByFiltersId()" placeholder="Plan Arayın.."
                                [(ngModel)]="filterText" [ngModelOptions]="{standalone:true}">
                        </div>
                    </div>
                    <div class="filter mt-1">
                        <div class="col-12">
                            <ng-select [multiple]="true" [items]="typeForFilter" [(ngModel)]="selectedTypes"
                                placeholder="Plan Türünü Seçiniz" bindLabel="name" bindValue="id"
                                (change)="tableByFiltersId()">
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <span class="ng-value-label"> {{ item.name }}</span>
                                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                </ng-template>
                                <ng-template ng-header-tmp>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="filter mt-1">
                        <div class="col-12">
                            <ng-select [multiple]="true" [items]="users" [(ngModel)]="selectedUsers"
                                placeholder="Kullanıcı Seçiniz " bindLabel="fullName" bindValue="id"
                                (change)="tableByFiltersId()">
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <span class="ng-value-label">{{ item.firstName }}
                                        {{item.lastName}}</span>
                                    <span class="ng-value-icon right" (click)="clear(item)"
                                        aria-hidden="true">×</span>
                                </ng-template>
                                <ng-template ng-header-tmp>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>

                </div>
                <div class="row  mb-2 mr-1">
                    <div class="col-4 ml-1">
                        <div class="col-12">
                            <button class="btn btn-primary btn-sm mr-1" routerLink="/home/addplan" rippleEffect
                                title="Yeni Plan">
                                <i data-feather="plus"></i><span class="d-none d-sm-inline-block"></span>
                            </button>
                            <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect
                                title="Excele Aktar">
                                <i data-feather="file-text" class=""></i>
                            </button>
                        </div>
                    </div>
                    <div id="dateFilter" class="row col-8"
                    style="display: flex;justify-content: end;">
       
                       <form class="form-inline">
                           <ng-select [clearable]="false" name="selectMonthForFilter" [multiple]="false" [items]="dateFilter"
                           [(ngModel)]="selectedDateFilter" placeholder="Aya Göre Filtrele"
                           bindLabel="name" bindValue="id" (change)="tableByFiltersId()" style="min-width:150px;" class="mr-1">
                           </ng-select>
                           <div class="form-group">
                               <div class="input-group w-0 ">
                                   <input name="datepicker" class="form-control" ngbDatepicker
                                       #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                       (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                                       [dayTemplate]="rangeSelectionDP" outsideDays="hidden"
                                       [startDate]="fromDateExample!" />
                                   <ng-template #rangeSelectionDP let-date let-focused="focused">
                                       <span class="custom-day" type="datetime" [class.focused]="focused"
                                           [class.range]="isRange(date)"
                                           [class.faded]="isHovered(date) || isInside(date)"
                                           (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                           {{ date.day }}
                                       </span>
                                   </ng-template>
                               </div>
                           </div>
                           <div class="form-group">
                               <div class="input-group">
                                   <input class="form-control" type="date" placeholder="dd-MM-yyyy"
                                       name="dpFromDate"
                                       [ngModel]="checkType(fromDateExample) ? formatter.format(fromDateExample) : fromDateExample"
                                       (ngModelChange)="fromDateExample = $event; tableByDates();" />
                                   <div class="input-group-append">
                                       <button
                                           class="btn btn-outline-secondary feather icon-calendar"
                                           (click)="datepicker.toggle()" type="button"
                                           rippleEffect></button>
                                   </div>
                               </div>
                           </div>
                           <div class="form-group ml-sm-2">
                               <div class="input-group">
                                   <input class="form-control" type="date" placeholder="dd-MM-yyyy"
                                       name="dpToDate"
                                       [ngModel]="checkType(toDateExample) ? formatter.format(toDateExample) : toDateExample"
                                       (ngModelChange)="toDateExample = $event; tableByDates();" />
                                   <div class="input-group-append">
                                       <button
                                           class="btn btn-outline-secondary feather icon-calendar"
                                           (click)="datepicker.toggle()" type="button"
                                           rippleEffect></button>
                                   </div>
                               </div>
                           </div>
                       </form>
                   </div>
                </div>

                <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
                    <thead>
                        <th>Takip No</th>
                        <th>Başlığı</th>
                        <th>Kullanıcı Adı</th>
                        <th>Partner Adı</th>
                        <th>Başlangıç Tarihi</th>
                        <th>Bitiş Tarihi</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of plans">
                            <td>{{item.trackingNumber}}</td>
                            <td>{{item.title}}</td>
                            <td>{{item.userName}}</td>
                            <td>{{item.partnerName}}</td>
                            <td [innerHTML]="item.startDate | date:'yyyy-MM-dd HH:mm:ss'"></td>                                            
                            <td [innerHTML]="item.endDate | date:'yyyy-MM-dd HH:mm:ss'"></td>                                            
                        </tr>
                    </tbody>
                </table>
                <ngx-datatable [rows]="filteredPlans" [rowHeight]="58" class="bootstrap core-bootstrap"
                    [limit]="10" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
                    [limit]="basicSelectedOption">
                    <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                        [resizeable]="false">
                        <ng-template ngx-datatable-header-template let-value="value"
                            let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                                    (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                                <label class="custom-control-label" for="headerChkbxRef"></label>
                            </div>
                        </ng-template>
                        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value"
                            let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [checked]="isSelected"
                                    (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}" />
                                <label class="custom-control-label" for="rowChkbxRef{{rowIndex}}"></label>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Kullanıcı Adı" prop="userName" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.userName}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Plan Başlığı" prop="title" [width]="300">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.title}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Başlangıç Tarihi" prop="startDate" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.startDate| date:'dd-MM-yyyy HH:mm'}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Bitiş Tarihi" prop="endDate" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.endDate| date:'dd-MM-yyyy HH:mm'}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Toplam Süre" prop="timeDifference" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.timeDifference}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Plan Türü" prop="planTypeName" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.planTypeName}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Partner Adı" prop="partnerName" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.partnerName == "" ? "Girilmemiş" : row.partnerName}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Çalışma Tipi" prop="locationTypeName" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.locationTypeName}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Seyahat Planı" prop="tripTypeString" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.tripTypeString==""?"Girilmemiş":row.tripTypeString}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Seyahat Tipi" prop="tripPlanString" [width]="250">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.tripPlanString==""?"Girilmemiş":row.tripPlanString}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            <div *ngIf="row.typeId != 4" class="d-flex align-items-center">
                                <div ngbDropdown container="body">
                                    <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                        id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                                    </a>
                                    <div ngbDropdownMenu class="dropdown-menu-right"
                                        aria-labelledby="dropdownBrowserState">
                                        <a routerLink="/home/updateplan/{{row.id}}" ngbDropdownItem><i
                                                data-feather="edit-2" class="mr-50"></i><span>Güncelle</span></a>
                                        <button ngbDropdownItem href="javascript:void(0);"
                                            (click)="deletedPlanId=row.id" data-bs-toggle="modal"
                                            data-bs-target="#deleteplan">
                                            <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                                        </button>
                                    </div>
                                </div>
                                <a routerLink="/home/updateplan/{{row.id}}" ngbDropdownItem title="Güncelle"><i
                                        data-feather="edit" class="text-primary cursor-pointer"></i></a>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
                <div class="row">
                    <div class="col-7"></div>
                    <div class="col-5 mb-3">
                        <table class="table table-borderless" >
                            <tbody>
                              <tr>
                                <td class="font-weight-bold">Toplam Gün:</td>
                                <td class="text-right">
                                  <div class="badge badge-pill badge-info font-small-3 font-weight-bold line-height-2 mb-25">
                                    {{ calculateDateDifferenceForAll() }}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                    </div>
                </div>

            </core-card-snippet>
            <div class="modal fade" id="deleteplan" tabindex="-1" aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Planı Sil</h1>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                        </div>
                        <div class="modal-body">
                            Kaydı Silmek İstediğinize Emin Misiniz?
                        </div>
                        <div class="modal-footer">
                            <div>
                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                    (click)="delete(deletedPlanId)"><i data-feather="trash"
                                        class="mr-50"></i>Sil</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>