import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UserEmergencyContact } from '../models/user-emergency-contact';

@Injectable({
  providedIn: 'root'
})
export class EvoUserEmergencyContactService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<UserEmergencyContact[]>{
    return this.httpClient.get<UserEmergencyContact[]>(this.baseApiUrl+'GetUserEmergencyContactList');
  }

  getListByUserId(id:number):Observable<UserEmergencyContact[]>{
    return this.httpClient.get<UserEmergencyContact[]>(this.baseApiUrl+'GetUserEmergencyContactListByUserId/'+id);
  }
  get (id: number):Observable <UserEmergencyContact> {
    return this.httpClient.get<UserEmergencyContact>(this.baseApiUrl+'GetUserEmergencyContact/'+id);
  }
  add(UserEmergencyContact:UserEmergencyContact):Observable<UserEmergencyContact>{
    return this.httpClient.post<UserEmergencyContact>(this.baseApiUrl+'AddUserEmergencyContact/',UserEmergencyContact);
  }
  update(UserEmergencyContact:UserEmergencyContact):Observable<UserEmergencyContact>{
    return this.httpClient.post<UserEmergencyContact>(this.baseApiUrl+"UpdateUserEmergencyContact/",UserEmergencyContact);
  }
  delete(id:UserEmergencyContact):Observable<UserEmergencyContact>{
    return this.httpClient.post<UserEmergencyContact>(this.baseApiUrl+"DeleteUserEmergencyContact/",id);
  }
}
