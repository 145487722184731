<style>
  .task {
    display: flex;
  }

  @media screen and (width < 992px) {
    .tasks {
      flex-direction: column;
    }
  }
</style>

<div class="container">
  <div class="card" style="width: 102%;">
      <div class="card-body">
        <div class="row">
          <div class="search mt-1">
            <div class="col-12">
              <input type="text" class="form-control mr-1" name="filterText" placeholder="Görev Arayın.."
                [(ngModel)]="filterText">
            </div>
          </div>
          <div class="filter mt-1" *ngIf="userRole=='Admin'">
            <div class="col-12">
                <ng-select [multiple]="true" [items]="users" [(ngModel)]="selectedUsers"
                    placeholder="Kullanıcı Seçiniz " bindLabel="fullName" bindValue="id"
                    (change)="tableByFiltersId()">
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                        <span class="ng-value-label">{{ item.firstName }}
                            {{item.lastName}}</span>
                        <span class="ng-value-icon right" (click)="clear(item)"
                            aria-hidden="true">×</span>
                    </ng-template>
                    <ng-template ng-header-tmp>
                    </ng-template>
                </ng-select>
            </div>
          </div>
          <div class="filter mt-1">
            <div class="col-12"
              *ngIf="userRole=='Admin' || userRole=='Consultant' || userRole=='ConsultantUser'">
              <ng-select [multiple]="true" [items]="frequencyTypes" [(ngModel)]="selectedFrequencies"
                placeholder="Tekrar Türü Seçiniz" bindLabel="name" bindValue="id" (change)="tableByFiltersId()">
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label"> {{ item.name }}</span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>
                <ng-template ng-header-tmp>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="filter mt-1">
            <div class="col-12">
                <ng-select [multiple]="true" [items]="taskCategories" [(ngModel)]="selectedTaskCategoryFilter"
                    placeholder="Kategori Seçiniz " bindLabel="name" bindValue="id"
                    (change)="tableByFiltersId()">
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                        <span class="ng-value-label">{{ item.name}}</span>
                        <span class="ng-value-icon right" (click)="clear(item)"
                            aria-hidden="true">×</span>
                    </ng-template>
                    <ng-template ng-header-tmp>
                    </ng-template>
                </ng-select>
            </div>
          </div>
        </div>
        
        <div class="row mt-2">
          <div class="col-3 ml-1">
            <div class="col-12">
              <button class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#addTaskModal"
              (click)="createNewTask()">
              <i data-feather="plus"></i>
              </button>
              <button class="btn btn-sm btn-warning ml-1" data-bs-toggle="modal" data-bs-target="#openIncompleteTasks"
              (click)="getIncompleteTasks()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-history"><polyline points="12 6 12 12 16 14"></polyline><path d="M2 13.24a9.67 9.67 0 0 0 2.71 5.83 10.2 10.2 0 0 0 14.32 0 9.89 9.89 0 0 0 0-14.14 10.2 10.2 0 0 0-13.52-.7C5.24 4.44 2.26 7.74 2 8"></path><path d="M6 9H1V4"></path></svg>
              </button>
            </div>
          </div>
          <div id="dateFilter" class="row col-9" style="display: flex;justify-content: end;">
            <form class="form-inline">
              <ng-select [clearable]="false" name="selectMonthForFilter" [multiple]="false" [items]="dateFilter"
              [(ngModel)]="selectedDateFilter" placeholder="Aya Göre Filtrele"
              bindLabel="name" bindValue="id" (change)="tableByFiltersId()" style="min-width:150px;" class="mr-1">
              </ng-select>
              <div class="form-group">
                <div class="input-group w-0">
                  <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                    [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                    [dayTemplate]="rangeSelectionDP" outsideDays="hidden" [startDate]="fromDateExample!" />
                  <ng-template #rangeSelectionDP let-date let-focused="focused">
                    <span class="custom-day" type="datetime" [class.focused]="focused" [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null">
                      {{ date.day }}
                    </span>
                  </ng-template>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input class="form-control" type="date" placeholder="dd-MM-yyyy" name="dpFromDate"
                    [ngModel]="checkType(fromDateExample) ? formatter.format(fromDateExample) : fromDateExample"
                    (ngModelChange)="fromDateExample = $event; tableByFiltersId();" />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary feather icon-calendar" (click)="datepicker.toggle()"
                      type="button" rippleEffect></button>
                  </div>
                </div>
              </div>
              <div class="form-group ml-sm-2">
                <div class="input-group">
                  <input class="form-control" type="date" placeholder="dd-MM-yyyy" name="dpToDate"
                    [ngModel]="checkType(toDateExample) ? formatter.format(toDateExample) : toDateExample"
                    (ngModelChange)="toDateExample = $event; tableByFiltersId();" />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary feather icon-calendar" (click)="datepicker.toggle()"
                      type="button" rippleEffect></button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

  </div>

  <div class="d-flex tasks">
    <div class="col-lg-4 col-md-8 col-12 ">
      <div class="card card-employee-task" style="height:850px;">
        <div class="mb-1" style="padding-top:1px;">
          Başlamamış Görevler
        </div>
        <div class="card-body" dndDropzone class="task-column" (dndDrop)="onDrop($event, 1,tasks)"
          style="min-height:85%;display: flex; flex-direction: column; justify-content: space-between;">
          <div>
            <div class="d-flex flex-column" style="cursor:pointer;" [ngClass]="cardClass()"
              *ngFor="let item of tasks | evoTodo: filterText | paginate: { itemsPerPage: 5, currentPage: nostartpage, id: 'nostart' }"
              [dndDraggable]="item" (dndCopied)="onDragged(item, tasks, 'move')" (click)="openTodo(item.id); $event.stopPropagation()"
              data-bs-toggle="modal" data-bs-target="#updateModal">
              
              <!-- Title Section -->
              <div class="d-flex justify-content-between align-items-center">
                <!-- Left-aligned title with text wrapping/shrinking -->
                <h6 class="mb-0 text-truncate" style="max-width: 70%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                  {{ item.title }}
                </h6>
              
                <!-- Right-aligned badges -->
                <div class="ml-auto">
                  <div class="badge badge-pill badge-light-info text-capitalize mr-1" *ngIf="item.frequencyTypeId == 1">Günlük Görev</div>
                  <div class="badge badge-pill badge-light-warning text-capitalize mr-1" *ngIf="item.frequencyTypeId == 2">Haftalık Görev</div>
                  <div class="badge badge-pill badge-light-success text-capitalize mr-1" *ngIf="item.frequencyTypeId == 3">Aylık Görev</div>
                  <div class="badge badge-pill badge-light-danger text-capitalize mr-1" *ngIf="item.frequencyTypeId == 4">Yıllık Görev</div>
                  <div class="badge badge-pill badge-light-secondary text-capitalize mr-1" *ngIf="item.frequencyTypeId == null">Tekrarsız Görev</div>
                </div>
              </div>
          
              <!-- EVO-itemId, Badge, and Date Section (Aligned Horizontally) -->
              <div class="d-flex justify-content-between align-items-center mt-1">
                <!-- EVO-itemId on the left -->
                <div class="evo-item-id" style="font-size:12px;">
                  EVO-{{item.id-1}}
                </div>
          
                <!-- Badge and Date on the right, aligned horizontally -->
                <div class="d-flex align-items-center">
                  <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0 mr-1">
                    <div class="media">
                        <div class="avatar bg-light-success">
                            <div class="avatar-content"
                                style="font-size: small; padding: 20px;">
                                {{ getInitials(item.userName) }}
                            </div>
                        </div>
                    </div>
                  </div>
                  <!-- Badge -->
                  <div class="badge badge-pill badge-light-secondary text-capitalize mr-1" *ngIf="item.typeId==1">Düşük</div>
                  <div class="badge badge-pill badge-light-primary text-capitalize mr-1" *ngIf="item.typeId==2">Normal</div>
                  <div class="badge badge-pill badge-light-danger text-capitalize mr-1" *ngIf="item.typeId==3">Yüksek</div>
          
                  <!-- Date -->
                  <small class="text-muted">{{ getTurkishMonth(item.startDate) }} {{ item.startDate | date: 'dd' }}</small>


                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div style="text-align:center;">
            <pagination-controls id="nostart" previousLabel="Geri" nextLabel="İleri" (pageChange)="nostartpage = $event"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-8 col-12 ">
      <div class="card card-employee-task" style="height:850px">
        <div class="mb-1" style="padding-top:1px;">
          İşlemdeki Görevler
        </div>
        <div class="card-body" dndDropzone class="task-column" (dndDrop)="onDrop($event, 2,inprogressTasks)" 
          style="min-height:85%;display: flex; flex-direction: column; justify-content: space-between;">
          <div>
            <div class="d-flex flex-column" style="cursor:pointer;" [ngClass]="cardClass()"
              *ngFor="let item of inprogressTasks|evoTodo:filterText | paginate: { itemsPerPage: 5, currentPage: progresspage,id:'progress' }"
              [dndDraggable]="item" (dndCopied)="onDragged(item,inprogressTasks,'move')" (click)="openTodo(item.id); $event.stopPropagation()"
              data-bs-toggle="modal" data-bs-target="#updateModal">
              <div class="d-flex justify-content-between align-items-center">
                <!-- Left-aligned title with text wrapping/shrinking -->
                <h6 class="mb-0 text-truncate" style="max-width: 70%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                  {{ item.title }}
                </h6>
              
                <!-- Right-aligned badges -->
                <div class="ml-auto">
                  <div class="badge badge-pill badge-light-info text-capitalize mr-1" *ngIf="item.frequencyTypeId == 1">Günlük Görev</div>
                  <div class="badge badge-pill badge-light-warning text-capitalize mr-1" *ngIf="item.frequencyTypeId == 2">Haftalık Görev</div>
                  <div class="badge badge-pill badge-light-success text-capitalize mr-1" *ngIf="item.frequencyTypeId == 3">Aylık Görev</div>
                  <div class="badge badge-pill badge-light-danger text-capitalize mr-1" *ngIf="item.frequencyTypeId == 4">Yıllık Görev</div>
                  <div class="badge badge-pill badge-light-secondary text-capitalize mr-1" *ngIf="item.frequencyTypeId == null">Tekrarsız Görev</div>
                </div>
              </div>
          
          
              <!-- EVO-itemId, Badge, and Date Section (Aligned Horizontally) -->
              <div class="d-flex justify-content-between align-items-center mt-1">
                <!-- EVO-itemId on the left -->
                <div class="evo-item-id" style="font-size:12px;">
                  EVO-{{item.id-1}}
                </div>
          
                <!-- Badge and Date on the right, aligned horizontally -->
                <div class="d-flex align-items-center">
                  <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0 mr-1">
                    <div class="media">
                        <div class="avatar bg-light-success">
                            <div class="avatar-content"
                                style="font-size: small; padding: 20px;">
                                {{ getInitials(item.userName) }}
                            </div>
                        </div>
                    </div>
                  </div>
                  <!-- Badge -->
                  <div class="badge badge-pill badge-light-secondary text-capitalize mr-1" *ngIf="item.typeId==1">Düşük</div>
                  <div class="badge badge-pill badge-light-primary text-capitalize mr-1" *ngIf="item.typeId==2">Normal</div>
                  <div class="badge badge-pill badge-light-danger text-capitalize mr-1" *ngIf="item.typeId==3">Yüksek</div>
          
                  <!-- Date -->
                  <small class="text-muted">{{ getTurkishMonth(item.startDate) }} {{ item.startDate | date: 'dd' }}</small>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div style="text-align:center;">
            <pagination-controls id="progress" previousLabel="Geri" nextLabel="İleri" (pageChange)="progresspage = $event"></pagination-controls>
          </div>
        </div>
      </div>



    </div>
    <div class="col-lg-4 col-md-8 col-12">
      <div class="card card-employee-task" style="height:850px;"> 
        <div class="mb-1" style="padding-top:1px;">
          Tamamlanmış Görevler
        </div>
        <div class="card-body" dndDropzone class="task-column" (dndDrop)="onDrop($event, 4,doneTasks)"
          style="min-height:85%; display: flex; flex-direction: column; justify-content: space-between;">
          <div>
            <div class="d-flex flex-column" style="cursor:pointer;" [ngClass]="cardClass()"
              *ngFor="let item of doneTasks|evoTodo:filterText | paginate: { itemsPerPage: 5, currentPage: donepage,id:'done' }"
              [dndDraggable]="item" (dndCopied)="onDragged(item,doneTasks,'move')">
              <div class="d-flex justify-content-between align-items-center">
                <!-- Left-aligned title with text wrapping/shrinking -->
                <h6 class="mb-0 text-truncate" style="max-width: 70%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                  {{ item.title }}
                </h6>
              
                <!-- Right-aligned badges -->
                <div class="ml-auto">
                  <div class="badge badge-pill badge-light-info text-capitalize mr-1" *ngIf="item.frequencyTypeId == 1">Günlük Görev</div>
                  <div class="badge badge-pill badge-light-warning text-capitalize mr-1" *ngIf="item.frequencyTypeId == 2">Haftalık Görev</div>
                  <div class="badge badge-pill badge-light-success text-capitalize mr-1" *ngIf="item.frequencyTypeId == 3">Aylık Görev</div>
                  <div class="badge badge-pill badge-light-danger text-capitalize mr-1" *ngIf="item.frequencyTypeId == 4">Yıllık Görev</div>
                  <div class="badge badge-pill badge-light-secondary text-capitalize mr-1" *ngIf="item.frequencyTypeId == null">Tekrarsız Görev</div>
                </div>
              </div>
          
              <!-- EVO-itemId, Badge, and Date Section (Aligned Horizontally) -->
              <div class="d-flex justify-content-between align-items-center mt-1">
                <!-- EVO-itemId on the left -->
                <div class="evo-item-id" style="font-size:12px;">
                  EVO-{{item.id-1}}
                </div>
          
                <!-- Badge and Date on the right, aligned horizontally -->
                <div class="d-flex align-items-center">
                  <div class="col-sm-6 col-xl-3 mb-2 mb-xl-0 mr-1">
                    <div class="media">
                        <div class="avatar bg-light-success">
                            <div class="avatar-content"
                                style="font-size: small; padding: 20px;">
                                {{ getInitials(item.userName) }}
                            </div>
                        </div>
                    </div>
                  </div>
                  <!-- Badge -->
                  <div class="badge badge-pill badge-light-secondary text-capitalize mr-1" *ngIf="item.typeId==1">Düşük</div>
                  <div class="badge badge-pill badge-light-primary text-capitalize mr-1" *ngIf="item.typeId==2">Normal</div>
                  <div class="badge badge-pill badge-light-danger text-capitalize mr-1" *ngIf="item.typeId==3">Yüksek</div>
          
                  <!-- Date -->
                  <small class="text-muted">{{ getTurkishMonth(item.startDate) }} {{ item.startDate | date: 'dd' }}</small>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="card-footer">
          <div style="text-align:center;">
            <pagination-controls id="done" previousLabel="Geri" nextLabel="İleri" (pageChange)="donepage = $event"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="updateModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Görev Detay</h5>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
            class="mr-50"></i>Çıkış</button>
      </div>
      <div class="modal-body">
          <form #taskUpdateForm="ngForm">
            <div *ngIf="userRole=='Admin'">
              <div class="form-group">
                <label for="usrSelect">Kullanıcı Seç</label>
                <ng-select name="slcUser" [multiple]="true" [items]="users" [(ngModel)]="selectedUsersForAddOperation"
                    placeholder="Kullanıcı Seçiniz " bindLabel="fullName" bindValue="id" #slcUser="ngModel"
                    required>
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                        <span class="ng-value-label">{{ item.firstName }} {{item.lastName}}</span>
                        <span class="ng-value-icon right" (click)="clear(item)"
                            aria-hidden="true">×</span>
                    </ng-template>
                    <ng-template ng-header-tmp>
                    </ng-template>
                </ng-select>
                <small class="text-danger" *ngIf="slcUser.invalid&& slcUser.touched">Bir Kullanıcı
                  seçmeniz gerekiyor!</small>
              </div>
            </div>
            <div class="form-group">
              <label for="name-vertical">Başlığını Giriniz</label>
              <input type="text" required id="name-vertical" class="form-control" name="txtTitle"
                placeholder="Görev Başlığı" [(ngModel)]="task.title" #txtTitle="ngModel" />
            </div>
            <div class="form-group position-relative">
              <div class="form-group">
                <label for="name-vertical">Başlangıç Tarihini Giriniz</label>
                <input type="datetime-local" required id="name-vertical" class="form-control" name="txtStartDate"
                  placeholder="Başlangıç Tarihi" [ngModel]="task.startDate | date:'yyyy-MM-ddTHH:mm'"
                  (ngModelChange)="task.startDate = $event" #txtStartDate />
              </div>
            </div>
            <div class="form-group position-relative">
              <label for="name-vertical">Bitiş Tarihini Giriniz</label>
              <input type="datetime-local" required id="name-vertical" class="form-control" name="txtEndDate"
                placeholder="Bitiş Tarihi" [ngModel]="task.endDate | date:'yyyy-MM-ddTHH:mm'"
                (ngModelChange)="task.endDate = $event" #txtEndDate />
            </div>

            <div class="form-group position-relative mb-2">
              <label for="name-vertical">Bir Görev Türü Seçiniz</label>
              <select class="form-control" ngModel required name="slcTask" [(ngModel)]="task.typeId" #slcTask="ngModel">
                <option *ngFor="let item of taskTypes" value="{{item.id}}">{{item.name}}
                </option>
              </select>
            </div>
            <div class="form-group position-relative">
              <label for="name-vertical">Bir Görev Kategorisi Seçiniz</label>

              <ng-select [multiple]="true" [items]="taskCategories" [(ngModel)]="task.taskCategoryList"
              placeholder="Görev Kategorisi Seçiniz" bindLabel="name" bindValue="id" name="slcTaskCategoryUpdate" required
              >
              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label">{{ item.name}}</span>
                  <span class="ng-value-icon right" (click)="clear(item)"
                      aria-hidden="true">×</span>
              </ng-template>
              <ng-template ng-header-tmp>
              </ng-template>
              </ng-select>
            </div>
            <div class="row col-12">
              <div class="col-6" style="text-align: center;">
                <div class="custom-control custom-control-success custom-switch">
                  <p class="mb-50" click>Tekrarlı Görev</p>
                  <input type="checkbox" [(ngModel)]="task.isReccuren" name="updateRecurre"
                    class="custom-control-input" id="customSwitchRecurre" />
                  <label class="custom-control-label" for="customSwitchRecurre"></label>
                </div>
              </div>
            </div>
            <div *ngIf="task.isReccuren == true ">
              <div class="form-group position-relative">
                <label for="name-vertical">Tekrar Türünü Seçiniz</label>
                <select class="form-control" ngModel required name="slcFrequencyType" [(ngModel)]="recurre.frequencyTypeId"
                  #slcFrequencyType="ngModel">
                  <option *ngFor="let item of frequencyTypes" value="{{item.id}}">{{item.name}}
                  </option>
                </select>
              </div>
            </div>
            <br>
            <div class="form-group mt-2">
              <div class="form-group">
                <label for="name-vertical">Açıklamasını Giriniz</label>
                <textarea type="text" id="name-vertical" class="form-control" name="txtDescription"
                  [(ngModel)]="task.description" placeholder="Görevin Açıklaması" ngModel
                  #txtDescription="ngModel"></textarea>
              </div>
            </div>
          </form>
      </div>
      <div class="modal-footer">
        <div class="form-group my-1">
          <button type="button" data-bs-toggle="modal" data-bs-target="#deleteTask" class="btn btn-danger delete-btn delete-todo-item mr-1" *ngIf="isDataEmpty === false"
            rippleEffect>
            Sil
          </button>
          <button type="button" class="btn btn-success update-btn update-todo-item mr-1" [disabled]="!taskUpdateForm.valid || taskInProcess==true" *ngIf="isDataEmpty === false"
            (click)="taskInProcess=true; updateTodo()" rippleEffect>
            Güncelle
          </button>
          <button type="button" id="updatemodalclose" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteTask" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Görevi
          Sil</h1>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
            class="mr-50"></i>Çıkış</button>
      </div>
      <div class="modal-body">
        Kaydı Silmek İstediğinize Emin Misiniz?
      </div>
      <div class="modal-footer">
        <div>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="deleteTodo()"><i data-feather="trash"
              class="mr-50"></i>Sil</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="openIncompleteTasks" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Geçmiş Tekrarlı Görevler</h1>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
            class="mr-50"></i>Çıkış</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="search mt-1">
            <div class="col-12">
              <input type="text" class="form-control mr-1" name="filterText" placeholder="Görev Arayın.."
                [(ngModel)]="filterPastText">
            </div>
          </div>
          <div class="filter mt-1" *ngIf="userRole=='Admin'">
            <div class="col-12">
                <ng-select [multiple]="true" [items]="users" [(ngModel)]="selectedPastUsers"
                    placeholder="Kullanıcı Seçiniz " bindLabel="fullName" bindValue="id"
                    (change)="pastTableByFiltersId()">
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                        <span class="ng-value-label">{{ item.firstName }}
                            {{item.lastName}}</span>
                        <span class="ng-value-icon right" (click)="clear(item)"
                            aria-hidden="true">×</span>
                    </ng-template>
                    <ng-template ng-header-tmp>
                    </ng-template>
                </ng-select>
            </div>
          </div>
          <div class="filter mt-1">
            <div class="col-12"
              *ngIf="userRole=='Admin' || userRole=='Consultant' || userRole=='ConsultantUser'">
              <ng-select [multiple]="true" [items]="frequencyTypes" [(ngModel)]="selectedPastFrequencies"
                placeholder="Tekrar Türü Seçiniz" bindLabel="name" bindValue="id" (change)="pastTableByFiltersId()">
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label"> {{ item.name }}</span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>
                <ng-template ng-header-tmp>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="filter mt-1" *ngIf="userRole=='Admin'">
            <div class="col-12">
                <ng-select [multiple]="true" [items]="statusTypes" [(ngModel)]="selectedPastStatus"
                    placeholder="Görev Durum Seçiniz " bindLabel="name" bindValue="id"
                    (change)="pastTableByFiltersId()">
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                        <span class="ng-value-label">{{item.name}}</span>
                        <span class="ng-value-icon right" (click)="clear(item)"
                            aria-hidden="true">×</span>
                    </ng-template>
                    <ng-template ng-header-tmp>
                    </ng-template>
                </ng-select>
            </div>
          </div>

          <div class="filter mt-1">
            <div class="col-12">
                <ng-select [multiple]="true" [items]="taskCategories" [(ngModel)]="selectedPastTaskCategoryFilter"
                    placeholder="Kategori Seçiniz " bindLabel="name" bindValue="id"
                    (change)="pastTableByFiltersId()">
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                        <span class="ng-value-label">{{ item.name}}</span>
                        <span class="ng-value-icon right" (click)="clear(item)"
                            aria-hidden="true">×</span>
                    </ng-template>
                    <ng-template ng-header-tmp>
                    </ng-template>
                </ng-select>
            </div>
          </div>
          
        </div>
        <div id="dateFilter2" class="row mt-1 mr-1" style="display: flex;justify-content: end;">
          <form class="form-inline">
            <ng-select [clearable]="false" name="selectMonthForFilter" [multiple]="false" [items]="dateFilter"
            [(ngModel)]="selectedPastDateFilter" placeholder="Aya Göre Filtrele"
            bindLabel="name" bindValue="id" (change)="pastTableByFiltersId()" style="min-width:150px;" class="mr-1">
            </ng-select>
            <div class="form-group">
              <div class="input-group w-0">
                <input name="datepicker2" class="form-control" ngbDatepicker #datepicker2="ngbDatepicker"
                  [autoClose]="'outside'" (dateSelect)="onPastDateSelection($event)" [displayMonths]="2"
                  [dayTemplate]="rangeSelectionDP2" outsideDays="hidden" [startDate]="fromPastDateExample!" />
                <ng-template #rangeSelectionDP2 let-date let-focused="focused">
                  <span class="custom-day" type="datetime" [class.focused]="focused" [class.range]="isPastRange(date)"
                    [class.faded]="isPastHovered(date) || isPastInside(date)" (mouseenter)="hoveredPastDate = date"
                    (mouseleave)="hoveredPastDate = null">
                    {{ date.day }}
                  </span>
                </ng-template>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <input class="form-control" type="date" placeholder="dd-MM-yyyy" name="dpFromDate"
                  [ngModel]="checkType(fromPastDateExample) ? formatter.format(fromPastDateExample) : fromPastDateExample"
                  (ngModelChange)="fromPastDateExample = $event; tableByPastDates();" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary feather icon-calendar" (click)="datepicker2.toggle()"
                    type="button" rippleEffect></button>
                </div>
              </div>
            </div>
            <div class="form-group ml-sm-2">
              <div class="input-group">
                <input class="form-control" type="date" placeholder="dd-MM-yyyy" name="dpToDate"
                  [ngModel]="checkType(toPastDateExample) ? formatter.format(toPastDateExample) : toPastDateExample"
                  (ngModelChange)="toPastDateExample = $event; tableByPastDates();" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary feather icon-calendar" (click)="datepicker2.toggle()"
                    type="button" rippleEffect></button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="mt-2">
          <ngx-datatable [rows]="filteredPastTasks | filter:filterPastText" [rowHeight]="58"
          class="bootstrap core-bootstrap" [limit]="10" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true">
            <ngx-datatable-column name="Danışman" prop="userName" [width]="250">
              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                {{row.userName}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Konu" prop="title" [width]="250">
              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                {{row.title}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Açıklama" prop="description" [width]="250">
              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                {{row.description}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Öncelik" prop="typeId" [width]="250">
              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <div class="badge badge-pill badge-light-success text-capitalize mr-1" *ngIf="row.typeId==1">Düşük</div>
                  <div class="badge badge-pill badge-light-primary text-capitalize mr-1" *ngIf="row.typeId==2">Normal</div>
                  <div class="badge badge-pill badge-light-danger text-capitalize mr-1" *ngIf="row.typeId==3">Yüksek</div>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Görev Tipi" prop="frequencyTypeId" [width]="250">
              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <div class="badge badge-pill badge-light-info text-capitalize mr-1" *ngIf="row.frequencyTypeId==1">Günlük Görev</div>
                  <div class="badge badge-pill badge-light-warning text-capitalize mr-1" *ngIf="row.frequencyTypeId==2">Haftalık Görev</div>
                  <div class="badge badge-pill badge-light-success text-capitalize mr-1" *ngIf="row.frequencyTypeId==3">Aylık Görev</div>
                  <div class="badge badge-pill badge-light-danger text-capitalize mr-1" *ngIf="row.frequencyTypeId==4">Yıllık Görev</div>
                  <div class="badge badge-pill badge-light-secondary text-capitalize mr-1" *ngIf="row.frequencyTypeId==null">Tekrarsız Görev</div>

                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Tarih" prop="startDate" [width]="250">
              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                {{row.startDate|date:'dd-MM-yyyy HH:mm'}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Durum" prop="statusId" [width]="250">
              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                <span *ngIf="row.statusId == 4" style="color: green;">Tamamlandı</span>
                <span *ngIf="row.statusId == 5" style="color: red;">Tamamlanmadı</span>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>  
        </div>
    
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="addTaskModal" tabindex="-1" aria-labelledby="addTaskModal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addTaskModal">Görev Detay</h5>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
            class="mr-50"></i>Çıkış</button>
      </div>
      <div class="modal-body">
        <form #todoForm="ngForm">
          <div *ngIf="userRole=='Admin'">
            <div class="form-group">
              <label for="usrSelect">Kullanıcı Seç</label>
              <ng-select name="slcUser" [multiple]="true" [items]="users" [(ngModel)]="selectedUsersForAddOperation"
                  placeholder="Kullanıcı Seçiniz " bindLabel="fullName" bindValue="id" #slcUser="ngModel"
                  required>
                  <ng-template ng-label-tmp let-item="item" let-clear="clear">
                      <span class="ng-value-label">{{ item.firstName }} {{item.lastName}}</span>
                      <span class="ng-value-icon right" (click)="clear(item)"
                          aria-hidden="true">×</span>
                  </ng-template>
                  <ng-template ng-header-tmp>
                  </ng-template>
              </ng-select>
              <small class="text-danger" *ngIf="slcUser.invalid&& slcUser.touched">Bir Kullanıcı
                seçmeniz gerekiyor!</small>
            </div>
          </div>
          <div class="form-group">
            <label for="name-vertical">Başlığını Giriniz</label>
            <input type="text" required id="name-vertical" class="form-control" name="taskTitle"
              placeholder="Görev Başlığı" [(ngModel)]="task1.title" />
          </div>
          <div class="form-group position-relative">
            <div class="form-group">
              <label for="name-vertical">Başlangıç Tarihini Giriniz</label>
              <input type="datetime-local" required id="name-vertical" class="form-control" name="taskstartdate"
                placeholder="Başlangıç Tarihi" [ngModel]="task1.startDate | date:'yyyy-MM-ddTHH:mm'"
                (ngModelChange)="task1.startDate = $event" #taskstartdate />
            </div>
          </div>
          <div class="form-group position-relative">
            <label for="name-vertical">Bitiş Tarihini Giriniz</label>
            <input type="datetime-local" required id="name-vertical" class="form-control" name="txtEndDate"
              placeholder="Bitiş Tarihi" [ngModel]="task1.endDate | date:'yyyy-MM-ddTHH:mm'"
              (ngModelChange)="task1.endDate = $event" #txtEndDate />
          </div>

          <div class="form-group position-relative">
            <label for="name-vertical">Bir Görev Türü Seçiniz</label>
            <select class="form-control" ngModel required name="slcTask" [(ngModel)]="task1.typeId" #slcTask="ngModel">
              <option *ngFor="let item of taskTypes" value="{{item.id}}">{{item.name}}
              </option>
            </select>
          </div>
          <div class="form-group position-relative">
            <label for="name-vertical">Bir Görev Kategorisi Seçiniz</label>

            <ng-select [multiple]="true" [items]="taskCategories" [(ngModel)]="task1.taskCategoryList"
            placeholder="Görev Kategorisi Seçiniz" bindLabel="name" bindValue="id" name="slcTaskCategory" required
            >
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <span class="ng-value-label">{{ item.name}}</span>
                <span class="ng-value-icon right" (click)="clear(item)"
                    aria-hidden="true">×</span>
            </ng-template>
            <ng-template ng-header-tmp>
            </ng-template>
            </ng-select>
          </div>

          <div class="row col-12">
            <div class="col-6" style="text-align: center;">
              <div class="custom-control custom-control-success custom-switch">
                <p class="mb-50" click>Tekrarlı Görev</p>
                <input name="customSwitchRecurre2" type="checkbox" [(ngModel)]="task1.isReccuren"
                  class="custom-control-input" id="customSwitchRecurre2" />
                <label class="custom-control-label" for="customSwitchRecurre2"></label>
              </div>
            </div>
          </div>

          <div *ngIf="task1.isReccuren==true">
            <div class="form-group position-relative">
              <label for="name-vertical">Tekrar Türünü Seçiniz</label>
              <select class="form-control" ngModel required name="slcFrequencyType"
                [(ngModel)]="recurre1.frequencyTypeId" #slcFrequencyType="ngModel">
                <option *ngFor="let item of frequencyTypes" value="{{item.id}}">{{item.name}}
                </option>
              </select>
            </div>
          </div>
          <br>
          <div class="form-group">
            <div class="form-group">
              <label for="name-vertical">Açıklamasını Giriniz</label>
              <textarea required type="text" id="name-vertical" class="form-control" name="txtDescription"
                [(ngModel)]="task1.description" placeholder="Görevin Açıklaması" ngModel
                #txtDescription="ngModel"></textarea>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="form-group my-1">
          <button type="submit" class="btn btn-success add-todo-item mr-1" [disabled]="!todoForm.valid || taskInProcess==true" *ngIf="isDataEmpty === true"
          (click)="taskInProcess=true;addTodo1()" rippleEffect>
          Kaydet
        </button>
        <button type="button" id="addmodalclose" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>

        </div>
      </div>
    </div>
  </div>
</div>