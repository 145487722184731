<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="ngx-datatable-kitchen-sink">
            <core-card-snippet>
                <h4 class="card-title">Erişim Tablosu Listesi</h4>
                <div class="row">
                    <div class="firstRow mt-1">
                        <div style="margin-left:9px; margin-bottom: 20px;">
                            <div class="col-12">

                                <select class="form-control form-select" [(ngModel)]="basicSelectedOption">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="search mt-1">
                        <div class="col-12">
                            <input type="text" class="form-control mr-1" name="filterText" placeholder="Erişim Tablosu Verisi Arayın.."
                                [(ngModel)]="filterText" [ngModelOptions]="{standalone:true}">
                        </div>
                    </div>
                    <div class="filter mt-1">
                        <div class="col-12">
                            <ng-select [multiple]="true" [items]="userList" [(ngModel)]="selectedUsers"
                                placeholder="Kullanıcı Seçiniz " bindLabel="fullName" bindValue="id"
                                (change)="tableByFiltersId()">
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <span class="ng-value-label">{{ item.firstName }}
                                        {{item.lastName}}</span>
                                    <span class="ng-value-icon right" (click)="clear(item)"
                                        aria-hidden="true">×</span>
                                </ng-template>
                                <ng-template ng-header-tmp>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>

                </div>
                <div class="row  mb-2 mr-1">
                    <div class="col-4 ml-1">
                        <div class="col-12">
                            <button class="btn btn-primary btn-sm mr-1" (click)="clear();" data-bs-target="#addUserServerAccessTable" data-bs-toggle="modal" rippleEffect
                                title="Yeni Erişim Tablo Verisi">
                                <i data-feather="plus"></i><span class="d-none d-sm-inline-block"></span>
                            </button>
                            <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect
                                title="Excele Aktar">
                                <i data-feather="file-text" class=""></i>
                            </button>
                        </div>
                    </div>
                </div>

                <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
                    <thead>
                        <th>Personel</th>
                        <th>Kullanılan Platform</th>
                        <th>Yetki</th>
                        <th>Tipi</th>
                        <th>Tarihi</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of pastUserServerAccessTableList">
                            <td>{{item.userName}}</td>
                            <td>{{item.serverName}}</td>
                            <td>{{item.operationClaimName}}</td>
                            <td>{{ turkishOperationNames(item.operationName)}}</td>
                            <td [innerHTML]="item.createdDate | date:'dd.MM.yyyy HH:mm:ss'"></td>                                            
                        </tr>
                    </tbody>
                </table>
                <ngx-datatable [rows]="filteredUserServerAccessTableList | filter:filterText" [rowHeight]="58" class="bootstrap core-bootstrap"
                    [limit]="10" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
                    [limit]="basicSelectedOption">
                    <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            <div class="d-flex align-items-center">
                                <div ngbDropdown container="body">
                                    <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                        id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                                    </a>
                                    <div ngbDropdownMenu class="dropdown-menu-right"
                                        aria-labelledby="dropdownBrowserState">
                                        <a (click)="get(row.id);" data-bs-target="#addUserServerAccessTable" data-bs-toggle="modal" ngbDropdownItem><i
                                                data-feather="edit-2" class="mr-50"></i><span>Güncelle</span></a>
                                        <button ngbDropdownItem href="javascript:void(0);"
                                            (click)="get(row.id);" data-bs-toggle="modal"
                                            data-bs-target="#deleteUserAccessTable">
                                            <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                                        </button>
                                    </div>
                                </div>
                                <a (click)="get(row.id);" data-bs-target="#addUserServerAccessTable" data-bs-toggle="modal"  ngbDropdownItem title="Güncelle"><i
                                        data-feather="edit" class="text-primary cursor-pointer"></i></a>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Kullanıcı Adı" prop="userName" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.userName}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Platform Adı" prop="title" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.serverName}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Yetki Adı" prop="startDate" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.operationClaimName}}
                        </ng-template>
                    </ngx-datatable-column>

                </ngx-datatable>

            </core-card-snippet>

        </section>
    </div>
</div>

<div class="modal fade" id="deleteUserAccessTable" tabindex="-1" aria-labelledby="staticBackdropLabel"
aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Planı Sil</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                Kaydı Silmek İstediğinize Emin Misiniz?
            </div>
            <div class="modal-footer">
                <div>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                        (click)="delete()"><i data-feather="trash"
                            class="mr-50"></i>Sil</button>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="addUserServerAccessTable" tabindex="-1" aria-labelledby="staticBackdropLabel"
aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Erişim Tablo Verisi Ekle</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                <form class="" #AddServerForm="ngForm">
                    <div class="modal-body">
                        <div class="form-group">
                            <div class="row mb-2">
                                <label for="usrSelect">Kullanıcı Seç</label>
                                <ng-select name="slcUserId" [multiple]="false" [items]="userList" [(ngModel)]="userServerAccessTable.userId"
                                    placeholder="Kullanıcı Seçiniz " bindLabel="fullName" bindValue="id" #slcUserId="ngModel"
                                    required style="width:100%;">
                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <span class="ng-value-label">{{ item.fullName}}</span>
                                        <span class="ng-value-icon right" (click)="clear(item)"
                                            aria-hidden="true">×</span>
                                    </ng-template>
                                    <ng-template ng-header-tmp>
                                    </ng-template>
                                </ng-select>
                                <small class="text-danger" *ngIf="slcUserId.invalid&& slcUserId.touched">Bir Kullanıcı
                                    seçmeniz gerekiyor!</small>
                            </div>
                            <div class="row mb-2">
                                <label for="srvSelect">Kullanılan Platform</label>
                                <ng-select name="slcServerId" [multiple]="false" [items]="serverList" [(ngModel)]="userServerAccessTable.serverId"
                                    placeholder="Platform Seçiniz " bindLabel="name" bindValue="id" #slcServerId="ngModel"
                                    required style="width:100%;">
                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <span class="ng-value-label">{{ item.name}}</span>
                                        <span class="ng-value-icon right" (click)="clear(item)"
                                            aria-hidden="true">×</span>
                                    </ng-template>
                                    <ng-template ng-header-tmp>
                                    </ng-template>
                                </ng-select>
                                <small class="text-danger" *ngIf="slcServerId.invalid && slcServerId.touched">Bir Sunucu
                                    seçmeniz gerekiyor!</small>
                            </div>

                            <div class="row mb-2">
                                <label for="name-vertical">Yetki Adı</label>
                                <select class="form-control" required name="slcOperationClaimId" [(ngModel)]="userServerAccessTable.operationClaimId"
                                #slcOperationClaimId="ngModel">
                                <option *ngFor="let item of operationClaimList" value="{{item.id}}">{{item.description}}</option>
                                </select>
                                <small class="text-danger" *ngIf="slcOperationClaimId.invalid && slcOperationClaimId.touched">Bir Yetki
                                    seçmeniz gerekiyor!</small>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div>
                            <button type="button" rippleEffect class="btn btn-success mr-1" [disabled]="!AddServerForm.valid"
                            (click)="saveUserServerAccessTable()">Kaydet</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>