<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="ngx-datatable-kitchen-sink">
            <core-card-snippet>
                <h4 class="card-title">Doküman Onay Listesi</h4>
                <div class="row">
                    <div class="firstRow mt-1">
                        <div style="margin-left:9px; margin-bottom: 20px;">
                            <div class="col-12">

                                <select class="form-control form-select" [(ngModel)]="basicSelectedOption">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="search mt-1">
                        <div class="col-12">
                            <input type="text" class="form-control mr-1" name="filterText" (keyup)="tableByFiltersId()" placeholder="Doküman Arayın.."
                                [(ngModel)]="filterText" [ngModelOptions]="{standalone:true}">
                        </div>
                    </div>
                    
                    <div class="filter mt-1 ml-1">
                        <div class="form-group">
                            <ng-select [multiple]="true" [items]="approvalTypeFilters" [(ngModel)]="selectedApprovalTypes"
                                placeholder="Onay Tipi Seçiniz" bindLabel="name" bindValue="id"
                                (change)="tableByFiltersId()">
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <span class="ng-value-label">{{ item.name}}</span>
                                    <span class="ng-value-icon right" (click)="clear(item)"
                                        aria-hidden="true">×</span>
                                </ng-template>
                                <ng-template ng-header-tmp>
                                </ng-template>
                            </ng-select>
                            <br />
                        </div>
                    </div>
                    <div class="filter mt-1 ml-1">
                        <div class="form-group">
                            <ng-select [multiple]="true" [items]="users" [(ngModel)]="selectedUsers"
                                placeholder="Danışman Seçiniz" bindLabel="fullName" bindValue="id"
                                (change)="tableByFiltersId()">
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <span class="ng-value-label">{{ item.fullName}}</span>
                                    <span class="ng-value-icon right" (click)="clear(item)"
                                        aria-hidden="true">×</span>
                                </ng-template>
                                <ng-template ng-header-tmp>
                                </ng-template>
                            </ng-select>
                            <br />
                        </div>
                    </div>
                </div>
                <div class="row  mb-2 mr-1">
                    <div class="col-4 ml-1">
                        <div class="col-12">
                            <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect
                                title="Excele Aktar">
                                <i data-feather="file-text" class=""></i>
                            </button>
                        </div>
                    </div>
                </div>

                <table class="table table-hover table-bordered" id="excel-table-2" style="display:none;">
                    <thead>
                        <th *ngFor="let item of headerList">
                            {{item}}
                        </th>

                    </thead>
                    <tbody>
                        <tr *ngFor="let row of headerData; let rowIndex = index">
                            <td *ngFor="let value of row.valueDatas; let colIndex = index">
                              {{initialInputData[rowIndex][colIndex]}}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
                    <thead>
                        <th>Doküman No</th>
                        <th>Doküman Adı</th>
                        <th>Onay Durumu</th>
                        <th>Onaylayan/Reddeden Adı</th>
                        <th>Onay/Red Tarihi</th>
                        <th>Onay/Red Sebebi</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of pastDocumentForms">
                            <td>{{item.documentNo}}</td>
                            <td>{{item.documentName}}</td>
                            <td>
                                <div *ngIf="item.approvalStatus==2">
                                    Onaylandı
                                </div>
                                <div *ngIf="item.approvalStatus==3">
                                    Reddedildi
                                </div>    
                            </td>
                            <td>{{item.userName}}</td>
                            <td>{{item.approvalDate | date:'dd.MM.yyyy HH:mm'}}</td>
                            <td>{{item.approvalCause}}</td>
                        </tr>
                    </tbody>
                </table>
                <p-table [tableStyle]="{ 'min-width': '120rem' }" [value]="filteredDocumentFormApprovalOperationList" rowExpandMode="single" sortField="documentFormName" sortMode="single" dataKey="documentFormName" rowGroupMode="subheader" groupRowsBy="documentFormName">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Veri Satır No</th>
                            <th>İşlem Adı</th>
                            <th>Eski Değerler</th>
                            <th>Yeni Değerler</th>
                            <th>İsteyen Adı</th>
                            <th>İsteme Tarihi</th>
                            <th>Onay Durumu</th>
                            <th>Onaylayan Adı</th>
                            <th>Onaylama Tarihi</th>
                            <th>Onaylama Sebebi</th>
                            <th *ngIf="userRole=='Admin'">Onayla</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="groupheader" let-item let-rowIndex="rowIndex" let-expanded="expanded">
                      <tr>
                        <td colspan="11">
                            <div style="display: flex; align-items: center; justify-content: space-between;">
                              <div>
                                <button
                                  type="button"
                                  pButton
                                  pRipple
                                  [pRowToggler]="item"
                                  class="p-button-text p-button-rounded p-button-plain mr-2"
                                  [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                                ></button>
                                <img src="https://static.thenounproject.com/png/684885-200.png" width="32" style="vertical-align: middle" />
                                <span class="font-bold ml-2"><strong>{{ item.documentFormName }}</strong></span>
                                <button  title="Excel İndir" (click)="getExcelInfo(item.documentFormId)" class="btn btn-info btn-sm mr-1 ml-2">
                                    <i class="pi pi-file"></i>
                                </button>
    
                              </div>
                            </div>
                          </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-item>
                        <tr>
                            <td>
                                {{item.rowId == 0 ? "-" : item.rowId}}
                            </td>
                            <td>
                                {{item.operationName == 'add' ? "Yeni" :(item.operationName == 'update' ? "Değişiklik" : "Silme")}}
                            </td>
                            <td>
                                {{item.oldFormValuesString == "null" ? "-" : item.oldFormValuesString}}   
                            </td>
                            <td>
                                {{item.newFormValuesString == "null" ? "-" : item.newFormValuesString}}
                            </td>
                            <td>
                                {{item.userName}}
                            </td>
                            <td>
                                {{item.createdDate | date:"dd.MM.yyyy HH:mm:ss"}}
                            </td>
                            <td>
                                <div *ngIf="item.approvalStatus==1" style="color:orange;">
                                    Onaylanmamış
                                </div>
                                <div *ngIf="item.approvalStatus==2" style="color:green;">
                                    Onaylandı
                                </div>
                                <div *ngIf="item.approvalStatus==3" style="color:red">
                                    Reddedildi
                                </div>
                            </td>
                            <td>
                                {{item.approverName}}
                            </td>
                            <td>
                                {{item.approvalDate | date: 'dd.MM.yyyy HH:mm:ss'}}
                            </td>
                            <td>
                                <div 
                                class="description-container" 
                                (mouseenter)="onMouseEnter($event,item.approvalCause)" 
                                (mouseleave)="onMouseLeave()"
                                (mousemove)="onMouseMove($event)">
                                
                                    <div class="description-text" [innerHTML]="item.approvalCause"></div>
                                
                                </div>
                            </td>
                            <td *ngIf="userRole=='Admin'">
                                <div>
                                    <button [disabled]="item.approvalStatus==2" (click)="get(item.id);approvalStatus=2;" title="Onayla" data-bs-toggle="modal" data-bs-target="#approvalModal" class="btn btn-success btn-sm mr-1 mb-1">
                                        <i class="pi pi-check"></i>
                                    </button>                                          
                                    <button [disabled]="item.approvalStatus==2" (click)="get(item.id);approvalStatus=3;" title="Reddet" data-bs-toggle="modal" data-bs-target="#approvalModal"  class="btn btn-danger btn-sm mb-1">
                                        <i class="pi pi-times"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </core-card-snippet>
        </section>
    </div>
</div>


<div class="modal fade" id="approvalModal" tabindex="-1" aria-labelledby="staticBackdropLabel"
aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Doküman Onayı</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
            </div>
            <form class="" #AddDocumentFormApprovalForm="ngForm">
            <div class="modal-body">
                <div class="form-group">
                    <div class="row mb-2">
                        <label for="name-vertical">{{documentFormApprovalOperation.approvalStatus == 2 ? 'Onay' : 'Red'}} Sebebi</label>
                        <textarea [(ngModel)]="documentFormApprovalOperation.approvalCause" class="form-control" name="txtDocumentFormApprovalCause" required
                            #txtDocumentFormApprovalCause="ngModel" ngModel></textarea>
                        <small class="text-danger"
                            *ngIf="txtDocumentFormApprovalCause.invalid&& txtDocumentFormApprovalCause.touched">Onay sebebi boş olamaz.</small>
                    </div>
                </div>   
            </div>
            <div class="modal-footer">
                <div>
                    <button [disabled]="!AddDocumentFormApprovalForm.valid" type="button" class="btn btn-danger" data-bs-dismiss="modal"
                        (click)="changeApprovalStatus()"><i data-feather="check"
                            class="mr-50"></i>Gönder</button>
                </div>
            </div>
            </form>
        </div>
    </div>
</div>

<div *ngIf="isOverlayVisible" 
     class="description-overlay" 
     [innerHTML]="overlayContent"
     [ngStyle]="{ top: overlayPosition.top + 'px', left: overlayPosition.left + 'px' }">
</div>