import { CoreMenu } from '@core/types'
import { TranslateService } from '@ngx-translate/core';

export const menu: CoreMenu[] = [
  {
    id: 'ticket',
    type: 'section',
    title: '',
    icon: 'package',
    children: [
      {
        id: 'tickets',
        title: 'MENU.TICKETS',
        type: 'collapsible',
        icon: 'server',
        children: [
          {
            id: 'ticketlist',
            title: 'MENU.ALLTICKETS',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/tickets'
          },
          {
            id: 'myticketlist',
            title: 'MENU.MYTICKETS',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/tickets'
          },
          {
            id: 'mytickets',
            title: 'MENU.OWNEDTICKETS',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/mytickets'
          },

          {
            id: 'no-start',
            title: 'MENU.UNSTARTEDTICKETS',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/no-start'
          },
        ]
      }]
  },
  // {
  //   id: 'ticketlist',
  //   title: 'Bütün Talepler',
  //   type: 'item',
  //   classes: "pull-up",
  //   icon: 'server',
  //   url: '/home/tickets'
  // },
  // {
  //   id: 'myticketlist',
  //   title: 'Taleplerim',
  //   type: 'item',
  //   classes: "pull-up",
  //   icon: 'eye',
  //   url: '/home/tickets'
  // },
  {
    id: 'ticketactivitylist',
    title: 'MENU.ACTIVITIES',
    type: 'item',
    classes: "pull-up",
    icon: 'clock',
    url: '/home/ticketactivities'
  },

  {
    id: 'userlist',
    title: 'MENU.CONSULTANTS',
    type: 'item',
    classes: "pull-up",
    icon: 'users',
    url: '/home/users'
  },

  {
    id: 'partneruserlist',
    title: 'MENU.USERS',
    type: 'item',
    classes: "pull-up",
    icon: 'users',
    url: '/home/partnerusers/'
  },
  {
    id: 'planlist',
    title: 'MENU.PLANS',
    type: 'item',
    classes: "pull-up",
    icon: 'calendar',
    url: '/home/plans'
  },
  {
    id: 'operationclaimlist',
    title: 'MENU.OPERATIONCLAIMS',
    type: 'item',
    classes: "pull-up",
    icon: 'key',
    url: '/home/operationclaims'
  },
  {
    id: 'expenelist',
    title: 'MENU.EXPENSES',
    type: 'item',
    classes: "pull-up",
    icon: 'credit-card',
    url: '/home/expenses'
  },
  {
    id: 'goalList',
    title: 'MENU.TARGETCARDS',
    type: 'item',
    classes: "pull-up",
    icon: 'compass',
    url: '/home/goals'
  },
  {
    id: 'competenceList',
    title: 'MENU.COMPETENCES',
    type: 'item',
    classes: "pull-up",
    icon: 'bar-chart-2',
    url: '/home/competences'
  },

  {
    id: 'partner',
    type: 'section',
    title: '',
    icon: 'package',
    children: [
      {
        id: 'partnersCollapsible',
        title: 'MENU.RESPONDENTS',
        type: 'collapsible',
        icon: 'briefcase',
        children: [
          {
            id: 'partnerlist',
            title: 'MENU.RESPONDENTS',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/partners'
          },
          {
            id: 'projectlist',
            title: 'MENU.PROJECTS',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/projects'
          },
          {
            id: 'contractlist',
            title: 'MENU.CONTRACTS',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/contracts'
          },
        ]
      }]
  },
  {
    id: 'definition',
    type: 'section',
    title: '',
    icon: 'package',
    children: [
      {
        id: 'definitions',
        title: 'MENU.DEFINITIONS',
        type: 'collapsible',
        icon: 'book-open',
        children: [
          {
            id: 'modulelist',
            title: 'MENU.MODULES',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/modules'
          },
          {
            id: 'departmentlist',
            title: 'MENU.DEPARTMENTS',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/departments'
          },
          {
            id: 'vacationlist',
            title: 'MENU.VACATIONS',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/vacations'
          },
          {
            id: 'servercontrollist',
            title: 'MENU.SERVERS',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/server-control'
          },
          {
            id: 'termlist',
            title: 'MENU.ANNUALTERMS',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/annualterms'
          },
          {
            id: 'taskcategorylist',
            title: 'MENU.TASKCATEGORIES',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/todocategories'
          },
          {
            id: 'serveraccesstableitems',
            title: 'MENU.SERVERACCESSTABLEITEMS',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/serveraccesstableitems'
          },
        ]
      }]
  },
  {
    id: 'reports',
    type: 'section',
    title: '',
    icon: 'package',
    children: [
      {
        id: 'reportlist',
        title: 'MENU.REPORTS',
        type: 'collapsible',
        icon: 'list',
        children: [
          {
            id: 'ticketsstatuswihnew',
            title: 'MENU.UNASSIGNEDTICKETS',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: ' '
          },
          {
            id: 'ticketstatuswithpartner',
            title: 'MENU.TICKETSPENDINGCUSTOMERRESPONSE',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: ' '
          },
          {
            id: 'ticketstatuswithconsultant',
            title: 'MENU.TICKETSPENDINGCONSULTANTRESPONSE',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: ' '
          },
          {
            id: 'ticketstatuswithcanselled',
            title: 'MENU.CANCELLEDTICKETS',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: ' '
          },
          {
            id: 'effortslist',
            title: 'MENU.TICKETSPENDINGEFFORTAPPROVAL',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: ' '
          },
          {
            id: 'transactionreportlist',
            title: 'MENU.SITELOGS',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/transactions'
          },

          {
            id: 'offdays',
            title: 'MENU.DAYOFFREPORTS',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/offdays'
          },

          {
            id: 'activitiesreport',
            title: 'MENU.ACTIVITIESREPORT',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/activitiesreport'
          },
        ]
      },
    ]

  },
  {
    id: 'activityinquiries',
    title: 'MENU.ALLACTIVITIES',
    type: 'item',
    classes: "pull-up",
    icon: 'bar-chart-2',
    url: '/home/activityinquiries'
  },
  {
    id: 'purchaseenquirylist',
    title: 'MENU.PURCHASEENQUIRIES',
    type: 'item',
    classes: "pull-up",
    icon: 'package',
    url: '/home/purchaseenquiries'
  },
  {
    id: 'documentforms',
    title: 'MENU.DOCUMENTFORMS',
    type: 'item',
    classes: "pull-up",
    icon: 'bar-chart-2',
    url: '/home/documentforms'
  },
  {
    id: 'formapprovallist',
    title: 'MENU.DOCUMENTFORMAPPROVALLIST',
    type: 'item',
    classes: "pull-up",
    icon: 'bar-chart-2',
    url: '/home/formapprovallist'
  },
  {
    id: 'serveraccesstable',
    title: 'MENU.SERVERACCESSTABLES',
    type: 'item',
    classes: "pull-up",
    icon: 'bar-chart-2',
    url: '/home/serveraccesstables'
  },

]
