import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { DocumentFormApprovalOperation } from '../models/document-form-approval-operation';
import { DocumentFormApprovalOperationList } from '../models/document-form-approval-operation-list';

@Injectable({
  providedIn: 'root'
})
export class EvoDocumentFormApprovalOperationsService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<DocumentFormApprovalOperationList[]>{
    return this.httpClient.get<DocumentFormApprovalOperationList[]>(this.baseApiUrl+'GetDocumentFormApprovalOperationsList');
  }
  getListByFormId(id:number):Observable<DocumentFormApprovalOperationList[]>{
    return this.httpClient.get<DocumentFormApprovalOperationList[]>(this.baseApiUrl+'GetDocumentFormApprovalOperationsByFormId/'+id);
  }
  getListByFormAndUserId(id:number):Observable<DocumentFormApprovalOperationList[]>{
    return this.httpClient.get<DocumentFormApprovalOperationList[]>(this.baseApiUrl+'GetDocumentFormApprovalOperationsByFormAndUserId/'+id);
  }
  get (id: number):Observable <DocumentFormApprovalOperation> {
    return this.httpClient.get<DocumentFormApprovalOperation>(this.baseApiUrl+'GetDocumentFormApprovalOperations/'+id);
  }
  add(department:DocumentFormApprovalOperation):Observable<DocumentFormApprovalOperation>{
    return this.httpClient.post<DocumentFormApprovalOperation>(this.baseApiUrl+'AddDocumentFormApprovalOperations/',department);
  }
  update(department:DocumentFormApprovalOperation):Observable<DocumentFormApprovalOperation>{
    return this.httpClient.post<DocumentFormApprovalOperation>(this.baseApiUrl+"UpdateDocumentFormApprovalOperations/",department);
  }
  delete(department:DocumentFormApprovalOperation):Observable<DocumentFormApprovalOperation>{
    return this.httpClient.post<DocumentFormApprovalOperation>(this.baseApiUrl+"DeleteDocumentFormApprovalOperations",department);
  }
}
