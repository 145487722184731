import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterMatrixPipe'
})
export class FilterMatrixPipe implements PipeTransform {
    transform(items: { rowCount: number; valueDatas: string[] }[], filterText: string): { rowCount: number; valueDatas: string[] }[] {
        if (!items || !filterText) {
          return items;
        }
    
        const lowerFilterText = filterText.toLowerCase();
    
        return items.filter(item =>
          item.valueDatas.some(value => value.toLowerCase().includes(lowerFilterText))
        );
    }
}