import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { ActivityInquiryDate } from 'app/evo/models/activity-inquiry-date';
import { AnnualTerm } from 'app/evo/models/annualterm';
import { AnnualTermList } from 'app/evo/models/annualtermlist';
import { Competence } from 'app/evo/models/competence';
import { CompetenceModules } from 'app/evo/models/competencemodules';
import { DocumentFormRevisionRequest } from 'app/evo/models/document-form-revision-request';
import { Module } from 'app/evo/models/module';
import { User } from 'app/evo/models/user';
import { EvoAnnualTermService } from 'app/evo/services/annualterm.service';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoCompetenceService } from 'app/evo/services/evo-competence.service';
import { EvoDocumentFormRevisionRequestService } from 'app/evo/services/evo-document-form-revision.service';
import { EvoModuleService } from 'app/evo/services/evo-module.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';

const themes = {
  light: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css',
  dark: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-dark-blue/theme.css'
};

@Component({
  selector: 'app-evo-document-form-revision',
  templateUrl: './evo-document-form-revision.component.html',
  styles: [
  ],
  providers:[ConfirmationService]
})
export class EvoDocumentFormRevisionRequestComponent implements OnInit {

  constructor(
    private documentFormRevisionService:EvoDocumentFormRevisionRequestService,
    private errorService: ErrorService,
    private helperService: HelperService,
    private informationService: InformationService,
    private decodeService: DecodeService,
    private confirmationService:ConfirmationService,
    private router:Router,
    private cd:ChangeDetectorRef,
  ) { }

  userRole: string = "";
  revisionRequestList:DocumentFormRevisionRequest[]=[];
  revisionRequest:DocumentFormRevisionRequest=new DocumentFormRevisionRequest();

  private themeLink: HTMLLinkElement;
  private primengLink: HTMLLinkElement;
  private primeiconsLink: HTMLLinkElement;
  backgroundColor:string="default";
  action:string="Ekle";

  selectedTable:string="";
  selectedRowId:number=0;
  selectedChangeList:any[]=[];
  selectedOperationType:string="";

  approvalCause:string="";
  approvalStatus:number=0;
  approvalType:number=1;

  headerTypes:any[]=[
    {id:1,type:'Yazı'}
  ]

  ngOnDestroy(): void {
    this.unloadPrimeNGStyles();
  }

  ngOnInit(): void {
    this.loadPrimeNGStyles();
    this.applyThemeChanges();
    this.listenForThemeChanges();
    
    this.userRole = this.decodeService.getRole();

    if(this.userRole!='Admin' && this.userRole!='Consultant'){
        this.informationService.warning("Bu sayfayı görmek için yetkiniz bulunmamaktadır.")
        this.router.navigate(['/home']);
      }

    this.list();

  }

  getForm(item:any){
    this.selectedTable = item.revisionDetails[0].affectedTableName;
    this.selectedRowId = item.revisionDetails[0].affectedRowId;
    this.selectedOperationType = item.revisionDetails[0].operationType;
    this.selectedChangeList = item.revisionDetails[0].changes;
  } 


  list() {
    this.documentFormRevisionService.getList().subscribe((res:any)=>{
        this.revisionRequestList = res.data;
        this.informationService.list("Dönem Listesi Başarıyla Getirildi.");

    })

  }

  approve(row:any,id:number){
    this.revisionRequest = {...row};
    this.revisionRequest.approvalStatus = id;

  }

  sendApproval(){
    console.log(this.approvalCause);
    this.revisionRequest.approvalCause = this.approvalCause


    if(this.approvalType == 1){
        this.documentFormRevisionService.changeApprovalStatus(this.revisionRequest).subscribe((res:any)=>{
            if(this.revisionRequest.approvalStatus==1){
                this.informationService.add("Revizyon onay talebi döküman formuna uygulandı.");
            }
            else{
                this.informationService.delete("Revizyon talebi reddedildi");

            }
        },(err:any)=>{
            this.errorService.errorHandler(err);
        })

    }
    else{
        this.documentFormRevisionService.changeApprovalStatusInBulk(this.revisionRequest).subscribe((res:any)=>{
            if(this.approvalStatus==1){
                this.informationService.add("Revizyon onay talebi döküman formuna uygulandı.");
            }
            else{
                this.informationService.delete("Revizyon talebi reddedildi");

            }
        },(err:any)=>{
            this.errorService.errorHandler(err);
        })
    }

  }


  loadPrimeNGStyles() {
    const head = document.getElementsByTagName('head')[0];

    // PrimeNG theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css';
    head.appendChild(this.themeLink);

    // PrimeNG core styles
    this.primengLink = document.createElement('link');
    this.primengLink.rel = 'stylesheet';
    this.primengLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/primeng.min.css';
    head.appendChild(this.primengLink);

    // PrimeIcons
    this.primeiconsLink = document.createElement('link');
    this.primeiconsLink.rel = 'stylesheet';
    this.primeiconsLink.href = 'https://cdn.jsdelivr.net/npm/primeicons/primeicons.css';
    head.appendChild(this.primeiconsLink);
  }

  unloadPrimeNGStyles() {
    if (this.themeLink) {
      this.themeLink.remove();
    }
    if (this.primengLink) {
      this.primengLink.remove();
    }
    if (this.primeiconsLink) {
      this.primeiconsLink.remove();
    }
  }


  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Yetkinlikler";
    this.helperService.exportExcel(element, title);
  }


  turkishOperationNames(key:string){
    var dictionary: Map<string, string> = new Map([
      ['add', 'Yeni'],
      ['update', 'Değişiklik'],
      ['delete', 'İptal']
    ]);
  
    return dictionary.get(key);
  }

  turkishColumnNames(key:string){
    var dictionary: Map<string, string> = new Map([
      ['DocumentFormHeader', 'Doküman Formu Başlıkları'],
      ['DocumentForm', 'Doküman Formu'],
      ['Name', 'Ad'],
      ['DocumentNo','Doküman Numarası'],
      ['DocumentName','Doküman Adı'],
      ['Id','Id'],
      ['DocumentFormId','Doküman Formu Id'],
      ['HeaderTypeId','Başlık Tipi']
    ]);
  
    return dictionary.get(key);
  }

  getHeaderType(id:string){
    
    var headerType = this.headerTypes.find(x=>x.id==Number.parseInt(id));
    return headerType.type;
  }

  applyThemeChanges() {
    const backgroundColor = localStorage.getItem("backgroundColor");
  
    // If a theme is already applied, remove it first
    if (this.themeLink) {
      this.themeLink.remove();
    }
  
    // Create a new link element and load the appropriate theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = backgroundColor === 'dark' ? themes.dark : themes.light;
    document.head.appendChild(this.themeLink);
}

listenForThemeChanges() {
    window.addEventListener('localStorageChange', () => {
      const backgroundColor = localStorage.getItem('backgroundColor');
      console.log('Background color changed:', backgroundColor);
      this.backgroundColor = backgroundColor;
      // Trigger Angular's change detection to update the view
      this.cd.detectChanges();
      this.applyThemeChanges(); // Apply the new theme instantly
    });
  }

}
