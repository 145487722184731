<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="ngx-datatable-kitchen-sink">
            <core-card-snippet>
                <h4 class="card-title">Tatillerin Listesi</h4>
                <div class="row">
                    <div class="firstRow mt-1">
                        <div style="margin-left:9px; margin-bottom: 20px;">
                            <div class="col-12">
                                <select class="form-control form-select" [(ngModel)]="basicSelectedOption">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="search mt-1">
                        <div class="col-12">
                            <input type="text" class="form-control mr-1" name="filterText" placeholder="Tatil Arayın.."
                                [(ngModel)]="filterText" [ngModelOptions]="{standalone:true}">
                        </div>
                    </div>

                </div>

                <div class="row  mb-2">
                    <div class="col-5 ml-1">
                        <div class="col-12">
                            <button class="btn btn-primary btn-sm mr-1" data-bs-toggle="modal"
                                data-bs-target="#addvacation" rippleEffect title="Yeni Resmi Tatil">
                                <i data-feather="plus"></i><span class="d-none d-sm-inline-block"></span>
                            </button>
                            <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect
                                title="Excele Aktar">
                                <i data-feather="file-text" class=""></i>
                            </button>
                        </div>
                    </div>

                    <div id="dateFilter" class="row col-7" style="display: flex;justify-content: end;">
                        <form class="form-inline">
                            <div class="form-group">
                                <div class="input-group w-0">
                                    <input name="datepicker" class="form-control" ngbDatepicker
                                        #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                        (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                                        [dayTemplate]="rangeSelectionDP" outsideDays="hidden"
                                        [startDate]="fromDateExample!" />
                                    <ng-template #rangeSelectionDP let-date let-focused="focused">
                                        <span class="custom-day" type="datetime" [class.focused]="focused"
                                            [class.range]="isRange(date)"
                                            [class.faded]="isHovered(date) || isInside(date)"
                                            (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                            {{ date.day }}
                                        </span>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group">
                                    <input #dpFromDate class="form-control" type="date" placeholder="dd-MM-yyyy"
                                        name="dpFromDate" [ngModel]="checkType(fromDateExample) ? formatter.format(fromDateExample) : fromDateExample" (ngModelChange)="fromDateExample = $event; tableByDates();" />
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary feather icon-calendar"
                                            (click)="datepicker.toggle()" type="button" rippleEffect></button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group ml-sm-2">
                                <div class="input-group">
                                    <input #dpToDate class="form-control" type="date" placeholder="dd-MM-yyyy"
                                        name="dpToDate" [ngModel]="checkType(toDateExample) ? formatter.format(toDateExample) : toDateExample" (ngModelChange)="toDateExample = $event; tableByDates();" />
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary feather icon-calendar"
                                            (click)="datepicker.toggle()" type="button" rippleEffect></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
                    <thead>
                        <th>Başlığı</th>
                        <th>Başlangıç Tarihi</th>
                        <th>Bitiş Tarihi</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of vacations">
                            <td>{{item.title}}</td>
                            <td>{{item.startDate | date:'dd-MM-yyyy HH:mm'}}</td>
                            <td>{{item.endDate | date:'dd-MM-yyyy HH:mm'}}</td>
                        </tr>
                    </tbody>
                </table>
                <ngx-datatable [rows]="vacations |evoPlan:filterText" [rowHeight]="58" class="bootstrap core-bootstrap"
                    [limit]="10" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
                    [limit]="basicSelectedOption">
                    <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                        [resizeable]="false">
                        <ng-template ngx-datatable-header-template let-value="value"
                            let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                                    (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                                <label class="custom-control-label" for="headerChkbxRef"></label>
                            </div>
                        </ng-template>
                        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value"
                            let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [checked]="isSelected"
                                    (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}" />
                                <label class="custom-control-label" for="rowChkbxRef{{rowIndex}}"></label>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Plan Başlığı" prop="title" [width]="400">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.title}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Başlangıç Tarihi" prop="startDate" [width]="300">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.startDate| date:'dd-MM-yyyy HH:mm'}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Bitiş Tarihi" prop="endDate" [width]="300">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{ row.endDate| date:'dd-MM-yyyy HH:mm'}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            <div class="d-flex align-items-center">
                                <div ngbDropdown container="body">
                                    <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                        id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                                    </a>
                                    <div ngbDropdownMenu class="dropdown-menu-right"
                                        aria-labelledby="dropdownBrowserState">
                                        <button data-bs-toggle="modal" data-bs-target="#updatevacation" ngbDropdownItem
                                            (click)="get(row.id)"><i data-feather="edit-2"
                                                class="mr-50"></i><span>Güncelle</span></button>
                                        <button ngbDropdownItem href="javascript:void(0);"
                                            (click)="deletedVacationId=row.id" data-bs-toggle="modal"
                                            data-bs-target="#deletevacation">
                                            <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                                        </button>
                                    </div>
                                </div>
                                <button ngbDropdownItem title="Güncelle" data-bs-toggle="modal"
                                    data-bs-target="#updatevacation" (click)="get(row.id)"><i data-feather="edit"
                                        class="text-primary cursor-pointer"></i></button>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </core-card-snippet>
            <div class="modal fade" id="addvacation" tabindex="-1" aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Resmi Tatil Ekle</h1>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                        </div>
                        <form #AddVacation="ngForm">
                            <div class="modal-body">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="name-vertical">Plan Başlığını Giriniz</label>
                                        <input type="text" ngModel required id="name-vertical" class="form-control"
                                            name="txtTitle" placeholder="Plan Başlığı" #txtTitle="ngModel" />

                                        <small class="text-danger" *ngIf="txtTitle.invalid&& txtTitle.touched">Tatilin
                                            Başlığı
                                            boş
                                            bırakılamaz!</small>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="name-vertical">Plan Açıklamasını Giriniz</label>
                                        <textarea type="text" ngModel id="name-vertical" class="form-control"
                                            name="txtDescription" placeholder="Plan Açıklaması"
                                            #txtDescription="ngModel"></textarea>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="name-vertical">Plan Başlangıç Tarihini Giriniz</label>
                                        <input type="datetime-local" ngModel required id="name-vertical"
                                            class="form-control" name="txtStartDate" placeholder="Kullanıcı Soyadı"
                                            #txtStartDate="ngModel" [(ngModel)]="startDateStr" />

                                        <small class="text-danger"
                                            *ngIf="txtStartDate.invalid&& txtStartDate.touched">Tatilin
                                            Başlangıç Tarhi boş
                                            bırakılamaz!</small>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="name-vertical">Plan Bitiş Tarihini Giriniz</label>
                                        <input type="datetime-local" ngModel required id="name-vertical"
                                            class="form-control" name="txtEndDate" placeholder="Mail Adresi"
                                            #txtEndDate="ngModel" [(ngModel)]="endDateStr" />

                                        <small class="text-danger"
                                            *ngIf="txtEndDate.invalid&& txtEndDate.touched">Tatilin
                                            Bitiş Tarhi boş
                                            bırakılamaz!</small>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <div>
                                    <button type="button" class="btn btn-success" data-bs-dismiss="modal"
                                        (click)="add(txtTitle.value,txtDescription.value,txtStartDate.value,txtEndDate.value)"
                                        [disabled]="!AddVacation.valid"><i data-feather="check"
                                            class="mr-50"></i>Kaydet</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="updatevacation" tabindex="-1" aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Resmi Tatili Güncelle</h1>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                        </div>
                        <div class="modal-body">
                            <form #UpdateVacation="ngForm">
                                <div class="modal-body">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Plan Başlığını Giriniz</label>
                                            <input type="text" [(ngModel)]="vacation.title" required id="name-vertical"
                                                class="form-control" name="txtUpdateTitle" placeholder="Plan Başlığı"
                                                #txtUpdateTitle="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtUpdateTitle.invalid&& txtUpdateTitle.touched">Tatilin Başlığı
                                                boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Plan Açıklamasını Giriniz</label>
                                            <textarea type="text" [(ngModel)]="vacation.description" id="name-vertical"
                                                class="form-control" name="txtUpdateDescription"
                                                placeholder="Plan Açıklaması"
                                                #txtUpdateDescription="ngModel"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Plan Başlangıç Tarihini Giriniz</label>
                                            <input type="datetime-local" [(ngModel)]="vacation.startDate" required
                                                id="name-vertical" class="form-control" name="txtUpdateStartDate"
                                                placeholder="Kullanıcı Soyadı" #txtUpdateStartDate="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtUpdateStartDate.invalid&& txtUpdateStartDate.touched">Tatilin
                                                Başlangıç Tarhi boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Plan Bitiş Tarihini Giriniz</label>
                                            <input type="datetime-local" [(ngModel)]="vacation.endDate" required
                                                id="name-vertical" class="form-control" name="txtUpdateEndDate"
                                                placeholder="Mail Adresi" #txtUpdateEndDate="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtUpdateEndDate.invalid&& txtUpdateEndDate.touched">Tatilin
                                                Bitiş Tarhi boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <div>
                                        <button type="button" class="btn btn-success" data-bs-dismiss="modal"
                                            (click)="update()" [disabled]="!UpdateVacation.valid"><i
                                                data-feather="check" class="mr-50"></i>Kaydet</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="deletevacation" tabindex="-1" aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Resmi Tatili Sil</h1>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                        </div>
                        <div class="modal-body">
                            Kaydı Silmek İstediğinize Emin Misiniz?
                        </div>
                        <div class="modal-footer">
                            <div>
                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                    (click)="delete(deletedVacationId)"><i data-feather="trash"
                                        class="mr-50"></i>Sil</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>