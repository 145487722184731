import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { ActivityInquiryDate } from 'app/evo/models/activity-inquiry-date';
import { AnnualTerm } from 'app/evo/models/annualterm';
import { AnnualTermList } from 'app/evo/models/annualtermlist';
import { Competence } from 'app/evo/models/competence';
import { CompetenceModules } from 'app/evo/models/competencemodules';
import { DocumentFormRevisionRequest } from 'app/evo/models/document-form-revision-request';
import { Module } from 'app/evo/models/module';
import { User } from 'app/evo/models/user';
import { EvoAnnualTermService } from 'app/evo/services/annualterm.service';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoCompetenceService } from 'app/evo/services/evo-competence.service';
import { EvoDocumentFormRevisionRequestService } from 'app/evo/services/evo-document-form-revision.service';
import { EvoModuleService } from 'app/evo/services/evo-module.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';
import { FilterPipe } from 'app/evo/pipe/filter.pipe';
import { EvoDocumentFormHeaderItemService } from 'app/evo/services/evo-document-form-header-items.service';
import { EvoDocumentFormHeaderService } from 'app/evo/services/evo-document-form-header.service';
import { DocumentFormHeaderItem } from 'app/evo/models/document-form-header-item';
import { FilterMatrixPipe } from 'app/evo/pipe/filter-matrix.pipe';
import { EvoDocumentFormService } from 'app/evo/services/evo-document-form.service';
import { DocumentForm } from 'app/evo/models/document-form';
import { DocumentFormHeaderItemGroupedData } from 'app/evo/models/document-form-grouped-item-data';

const themes = {
  light: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css',
  dark: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-dark-blue/theme.css'
};

@Component({
  selector: 'app-evo-document-form-header-items',
  templateUrl: './evo-document-form-header-items.component.html',
  styles: [
  ],
  providers:[ConfirmationService,FilterMatrixPipe]
})
export class EvoDocumentFormHeaderItemsComponent implements OnInit {

  constructor(
    private documentFormHeaderItemService:EvoDocumentFormHeaderItemService,
    private documentFormHeaderService:EvoDocumentFormHeaderService,
    private documentFormService:EvoDocumentFormService,
    private errorService: ErrorService,
    private helperService: HelperService,
    private informationService: InformationService,
    private decodeService: DecodeService,
    private confirmationService:ConfirmationService,
    private router:Router,
    private cd:ChangeDetectorRef,
    private filterPipe:FilterMatrixPipe,
    private route: ActivatedRoute,  
) { }

  userRole: string = "";
  revisionRequestList:DocumentFormRevisionRequest[]=[];
  revisionRequest:DocumentFormRevisionRequest=new DocumentFormRevisionRequest();

  private themeLink: HTMLLinkElement;
  private primengLink: HTMLLinkElement;
  private primeiconsLink: HTMLLinkElement;
  backgroundColor:string="default";
  action:string="Ekle";

  addDataForInput:string[][]=[];
  addDataForInputInputs:string[][]=[];

  inputData:any[][]=[];
  initialInputData:any[][]=[];

  headerList:string[]=[];
  headerData:any[]=[];
  filteredHeaderData:any[]=[];

  basicSelectedOption:number=10;
  filterText:string="";

  formId:number=0;
  mainListSize = 0;
  pageSize = 25;
  currentPage:number=1;
  columns = []
  documentFormInfo:DocumentForm = new DocumentForm();
  currentUserId:number=1;
  itemTypes:any[]=[{id:1,name:"Benim Verilerim"},{id:2,name:"Tüm Veriler"}]
  selectedItemType:number=2
  pendingInsert:boolean=false
  pendingUpdate:boolean=false
  pendingDelete:boolean=false

  ngOnDestroy(): void {
    this.unloadPrimeNGStyles();
  }

  ngOnInit(): void {
    this.formId = this.route.snapshot.params['formId'];

    this.loadPrimeNGStyles();
    this.applyThemeChanges();
    this.listenForThemeChanges();
    
    this.userRole = this.decodeService.getRole();
    this.currentUserId = this.decodeService.getUserId();

    if(this.userRole!='Admin' && this.userRole!='Consultant' && this.userRole!='ConsultantUser'){
        this.informationService.warning("Bu sayfayı görmek için yetkiniz bulunmamaktadır.")
        this.router.navigate(['/home']);
      }

    this.list();
    this.requiredList();

  }

  pageChange(page:number){
    this.currentPage = page;
  }

  goBack(){
    this.router.navigate(['/home/documentforms']);
  }
  list() {
    this.documentFormHeaderItemService.getGroupedListByFormId(this.formId).subscribe((res:any)=>{

      this.informationService.list("Doküman Verisi Listesi Başarıyla Getirildi.");

      // Map the response to extract the headerData and inputData while keeping RowCount
      this.headerData = res.data;
      this.inputData = this.headerData.map(group => [...group.valueDatas]);

      this.mainListSize = this.headerData.length;


      // Filtered data will include all rows initially
      this.filteredHeaderData = res.data;
      this.filteredHeaderData = this.filteredHeaderData.map((row, i) => ({ ...row, index: i }));
      this.inputData = this.filteredHeaderData.map(group => [...group.valueDatas]);
      this.initialInputData = {...this.inputData};
      this.tableByFiltersId();
    })
  }

  requiredList() {
    this.documentFormHeaderService.getHeaderNames(this.formId).subscribe((res:any)=>{
        this.headerList = res.data;
        this.informationService.list("Doküman Başlık Listesi Başarıyla Getirildi.");
        this.columns = this.headerList.map((header, index) => ({
          prop: `valueDatas[${index}]`, // Binding to index-based data
          name: header,
          width: 250
        }));
    })

    this.documentFormService.get(this.formId).subscribe((res:any)=>{
      this.documentFormInfo = res.data;
    })
  }

  clear(){

    this.documentFormHeaderService.getHeaderNames(this.formId).subscribe((res:any)=>{
        this.headerList = res.data;


        this.addDataForInput= [res.data.map(() => "")];
        this.addDataForInputInputs = [this.headerList.map(() => "")];

    })
  }

  insertHeaderItem(){
    this.pendingInsert =true;
    this.documentFormHeaderItemService.addRow(this.formId,this.addDataForInputInputs[0]).subscribe((res:any)=>{
        this.informationService.add(res.message);
    },(err:any)=>{
        this.errorService.errorHandler(err);
        this.pendingInsert = false;
    },()=>{
        setTimeout(()=>{
            this.list();
            this.clear();
            this.pendingInsert = false;
        },150)

        
    })
  }

  updateItemsInRowIndex(rowCountId:number,inputRowId:number,rowVersion:string[]){

    this.pendingUpdate =true;

    var documentFormItemGroupedData = new DocumentFormHeaderItemGroupedData();

    var originalInput = this.initialInputData[inputRowId];

    documentFormItemGroupedData.userId = this.currentUserId;
    documentFormItemGroupedData.documentFormDto = this.inputData[inputRowId];

    if (JSON.stringify(originalInput) === JSON.stringify(documentFormItemGroupedData.documentFormDto)) {
      this.informationService.warning("Bu satırla ilgili herhangi birşey değiştirmediniz.");
      this.pendingUpdate=false;
      return;
    }

    documentFormItemGroupedData.rowVersion = rowVersion;
    documentFormItemGroupedData.formId = this.formId;
    documentFormItemGroupedData.rowId = rowCountId;
    this.documentFormHeaderItemService.updateRow(documentFormItemGroupedData).subscribe((res:any)=>{
        this.informationService.update(res.message);
    },(err:any)=>{
        this.errorService.errorHandler(err);
        this.pendingUpdate = false;
    },()=>{
        setTimeout(()=>{
            this.list();
            this.pendingUpdate = false;

        },150)
    })
  }

  deleteItemsInRowIndex(id:number,rowVersion:string[]){
    this.pendingDelete =true;

    this.documentFormHeaderItemService.deleteRow(this.formId,id,rowVersion).subscribe((res:any)=>{
        this.informationService.delete(res.message);
    },(err:any)=>{
        this.errorService.errorHandler(err);
        this.pendingDelete = false;
    },()=>{
        setTimeout(()=>{
            this.list();
            this.pendingDelete = false;

        },150)
    })
  }

  loadPrimeNGStyles() {
    const head = document.getElementsByTagName('head')[0];

    // PrimeNG theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css';
    head.appendChild(this.themeLink);

    // PrimeNG core styles
    this.primengLink = document.createElement('link');
    this.primengLink.rel = 'stylesheet';
    this.primengLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/primeng.min.css';
    head.appendChild(this.primengLink);

    // PrimeIcons
    this.primeiconsLink = document.createElement('link');
    this.primeiconsLink.rel = 'stylesheet';
    this.primeiconsLink.href = 'https://cdn.jsdelivr.net/npm/primeicons/primeicons.css';
    head.appendChild(this.primeiconsLink);
  }

  unloadPrimeNGStyles() {
    if (this.themeLink) {
      this.themeLink.remove();
    }
    if (this.primengLink) {
      this.primengLink.remove();
    }
    if (this.primeiconsLink) {
      this.primeiconsLink.remove();
    }
  }

  tableByFiltersId() {

    if (!this.filterText || this.filterText.trim() === "") {
      this.filteredHeaderData = [...this.headerData];
      this.filteredHeaderData = this.filteredHeaderData.map((row, i) => ({ ...row, index: i }));

      this.inputData = this.filteredHeaderData.map(group => [...group.valueDatas]);
    }
  
    this.filteredHeaderData = this.headerData.filter(group =>
      group.valueDatas.some(value =>
        value.toLocaleLowerCase("tr-TR").includes(this.filterText.toLocaleLowerCase("tr-TR"))
      )
    );


    if(this.selectedItemType==1){
      this.filteredHeaderData = this.filteredHeaderData.filter(x=>x.userId==this.currentUserId)

    }
  
    this.filteredHeaderData = this.filteredHeaderData.map((row, i) => ({ ...row, index: i }));

    this.inputData = this.filteredHeaderData.map(group => [...group.valueDatas]);
  
        
  }


  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = this.documentFormInfo.documentNo + " " + this.documentFormInfo.documentName + " Doküman Raporu";
    this.helperService.exportExcelForBGYS(element, title,this.headerList,this.documentFormInfo);
  }


  applyThemeChanges() {
    const backgroundColor = localStorage.getItem("backgroundColor");
  
    // If a theme is already applied, remove it first
    if (this.themeLink) {
      this.themeLink.remove();
    }
  
    // Create a new link element and load the appropriate theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = backgroundColor === 'dark' ? themes.dark : themes.light;
    document.head.appendChild(this.themeLink);
}

listenForThemeChanges() {
    window.addEventListener('localStorageChange', () => {
      const backgroundColor = localStorage.getItem('backgroundColor');
      console.log('Background color changed:', backgroundColor);
      this.backgroundColor = backgroundColor;
      // Trigger Angular's change detection to update the view
      this.cd.detectChanges();
      this.applyThemeChanges(); // Apply the new theme instantly
    });
  }

}
