import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { EvoPermissionUserService } from 'app/evo/services/evo-permission-user.service';
import { EvoPermissionService } from 'app/evo/services/evo-permission.service';
import { Permission } from 'app/evo/models/permission';
import { PermissionUser } from 'app/evo/models/permissionuser';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;

  @Input()
  layout = 'vertical';

  @Input()
  menu: any;
  menu_customer: any;
  userRole: string;
  userId: number;
  _unsubscribeAll: Subject<any>;

  permissions: Permission[] = [];
  permissionUsers: PermissionUser[] = [];

  partnerListingPermission: boolean;
  expenseListingPermission: boolean;
  goalListingPermission: boolean;
  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   */
  constructor(private _changeDetectorRef: ChangeDetectorRef,
    private _coreMenuService: CoreMenuService,
    private decodeService: DecodeService,
    private permissionService: EvoPermissionService,
    private permissionUserService: EvoPermissionUserService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    
    this.userRole = this.decodeService.getRole();
    this.userId = this.decodeService.getUserId();
    this.getPermissions();

  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Set the menu either from the input or from the service
    this.menu = cloneDeep(this.menu || this._coreMenuService.getCurrentMenu());
    this.menu_customer = cloneDeep(this.menu_customer || this._coreMenuService.getCurrentMenu());

    // Modify the menu based on the user role
    this.modifyMenuBasedOnRole();

    // Subscribe to the current menu changes
    this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      // Load menu
      this.menu = cloneDeep(this._coreMenuService.getCurrentMenu());
      this.modifyMenuBasedOnRole();
      this._changeDetectorRef.markForCheck();
    });
  }
  
  getPermissions() {
    this.permissionService.getList().subscribe((items: any) => {
      this.permissions = items.data;
      this.checkPermissionUsers();
    });
  }

  checkPermissionUsers() {
    this.permissionUserService.getPermissionUserListByUserId(this.userId).subscribe((items: any) => {
      this.permissionUsers = items.data;

      this.partnerListingPermission = this.permissionUsers.some(user => user.permissionId === 10);
      this.expenseListingPermission = this.permissionUsers.some(user => user.permissionId === 11);
      this.goalListingPermission = this.permissionUsers.some(user => user.permissionId === 12);
      if (this.partnerListingPermission) {
        this.menu[10].children[0].children[0].disabled = true;
      } else {
        this.menu[10].children[0].children[0].disabled = false;
      }
  
      if (this.expenseListingPermission) {
        this.menu[6].disabled = true;
      } else {
        this.menu[6].disabled = false;
      }
  
      if (this.goalListingPermission) {
        this.menu[7].disabled = true;
      } else {
        this.menu[7].disabled = false;
      }
    });
  }


  modifyMenuBasedOnRole() {
    switch (this.userRole) {
      case 'Consultant':
        this.hideMenuItems([2, 3, 4, 5,15]);
        this.spliceMenuItems([9, 10, 11]);
        break;
      case 'ConsultantUser':
        this.hideMenuItems([0, 2, 3, 4, 5,15,16]);
        this.spliceMenuItems([9, 10, 11]);
        break;
      case 'Customer':
        this.hideMenuItems([0, 1, 2, 4, 5, 6, 7, 8,12,13,14,15,16]);
        this.menu[3].url = `/home/partnerusers/${this.userId}`;
        this.spliceMenuItems([9, 10, 11]);
        break;
      case 'CustomerUser':
        this.hideMenuItems([0, 1, 2, 3, 4, 5, 6, 7, 8,12,13,14,15,16]);
        this.spliceMenuItems([9, 10, 11]);
        break;
      case 'Admin':
        this.hideMenuItems([3, 5]);
        break;
      default:
        // Handle default case if necessary
        break;
    }
  }
  
  hideMenuItems(indices: number[]) {
    indices.forEach(index => {
      if (this.menu[index]) {
        this.menu[index].hidden = true;
      }
    });
  }

  spliceMenuItems(indices: number[]) {
    indices.sort((a, b) => b - a).forEach(index => {
      if (this.menu.length > index) {
        this.menu.splice(index, 1);
      }
    });
  }
  
}
