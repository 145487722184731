export class Effort{
    id:number;
    ticketId:number;
    userId:number;
    userName:string;
    partnerUserId:number;
    partnerUserName:string;
    description:string;
    statusId:number;
    time:number;
    startDate:Date;
    createdDate:Date;
    
    createdBy:number;
    updatedBy:number;
    deletedBy:number;

    approvalDate:Date|null=null;
}