import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, MaxLengthValidator } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { zoomIn } from '@core/animations/core.animation';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { BillType } from 'app/evo/models/billtype';
import { Department } from 'app/evo/models/department';
import { Effort } from 'app/evo/models/effort';
import { EffortStatus } from 'app/evo/models/effortstatus';
import { LocationType } from 'app/evo/models/locationtype';
import { Priority } from 'app/evo/models/priority';
import { Status } from 'app/evo/models/status';
import { Ticket } from 'app/evo/models/ticket';
import { TicketActivity } from 'app/evo/models/ticketactivity';
import { TicketFollow } from 'app/evo/models/ticketfollow';
import { TicketType } from 'app/evo/models/tickettype';
import { TicketUser } from 'app/evo/models/ticketuser';
import { TicketUserType } from 'app/evo/models/ticketusertype';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoDepartmentService } from 'app/evo/services/evo-department.service';
import { EvoEffortService } from 'app/evo/services/evo-effort.service';
import { EvoTicketActivityService } from 'app/evo/services/evo-ticket-activity.service';
import { EvoTicketService } from 'app/evo/services/evo-ticket.service';
import { EvoTicketMessageService } from 'app/evo/services/evo-ticketmessage.service';
import { EvoTicketUserService } from 'app/evo/services/evo-ticketuser.service';
import { EvoTicketUserTypeService } from 'app/evo/services/evo-ticketusertype.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';
import { FileItem, FileUploader } from 'ng2-file-upload';
import * as snippet from '../layout/quill-editor.snippetcode';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { Partner } from 'app/evo/models/partner';
import { Module } from 'app/evo/models/module';
import { EvoModuleService } from 'app/evo/services/evo-module.service';
import { AnswerType } from 'app/evo/models/answertype';
import { Project } from 'app/evo/models/project';
import { EvoProjectService } from 'app/evo/services/evo-project.service';
import { EvoPartnerUserService } from 'app/evo/services/evo-customer-user.service';
import { Permission } from 'app/evo/models/permission';
import { PermissionUser } from 'app/evo/models/permissionuser';
import { EvoPermissionService } from 'app/evo/services/evo-permission.service';
import { EvoPermissionUserService } from 'app/evo/services/evo-permission-user.service';
import { EvoTicketconnectionService } from 'app/evo/services/evo-ticketconnection.service';
import { TicketConnection } from 'app/evo/models/ticketconnection';

import Quill from 'quill';
import QuillMention from 'quill-mention';
import { environment } from 'environments/environment';
import { TicketLinkService } from 'app/evo/services/ticketLink.service';
import { TicketLink } from 'app/evo/models/ticketLink';
import { SecurityKodDto } from 'app/evo/models/SecurityKodDto';
import { EvoAuditService } from 'app/evo/services/evo-audit.service';

const URL = environment.apiUrl + 'UploadTicketMessageFile';
declare var window: any;
declare var bootstrap:any;

@Component({
    selector: 'app-evo-ticketmessages-list',
    templateUrl: './evo-ticketmessages-list.component.html',
    animations: [zoomIn, trigger('menu', [
        state('enter', style({ opacity: 1, marginTop: '0px', visibility: 'visible' })),
        state('exit, void', style({ opacity: 0, marginTop: '-15px' })),
        transition('* => *', animate('120ms ease-in'))
    ])]

})
export class EvoTicketMessagesListComponent implements OnInit {
    quillEditorContent = snippet.quillEditorContent;
    message: string;
    filterText: string = "";

    userList: User[] = [];

    quillEditor: any;
    prevContent = null;
    securityKod:string = "";

    editorModules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'align': [] }],
            [{ 'list': 'ordered' }],
            ['link'],
            ['formula', 'code-block'],
            ['clean']
        ],
        clipboard: {
            matchers: [],
            dragNDrop: {
                draggableElements: []
            }
        },
        mention: {
            allowedChars: /^[A-Za-z\sÇçĞğİıÖöŞşÜü]*$/,
            mentionDenotationChars: ['@'],
            source: this.mentionSourceCallback.bind(this),
            renderItem: this.renderMentionItem,
            onSelect: (item, insertItem) => {
                this.userService.get(item.id).subscribe((res: any) => {
                    this.userList.push(res.data);

                })
                insertItem(item);
            }
        }
    };


    documents: any[] = [];
    public selectLabel = [
        { label: 'Business', bullet: 'primary' },
        { label: 'Personal', bullet: 'danger' },
        { label: 'Family', bullet: 'warning' },
        { label: 'Holiday', bullet: 'success' },
        { label: 'ETC', bullet: 'info' }
    ];
    billTypeForEffort: BillType = new BillType();
    billTypes: BillType[] = [
        { id: 4, name: "Faturalanmadı" },
        { id: 5, name: "Faturalandı" },
        { id: 6, name: "Feragat Edildi" }
    ];
    ticketTypes: TicketType[] = [
        { id: 1, name: "Destek Talebi" },
        { id: 2, name: "Müşteri Planı" },
        { id: 3, name: "Proje Planı" },
    ];

    @ViewChild('imageElement') imageElement: ElementRef<HTMLImageElement>;
    @ViewChild('userbutton') userbutton: ElementRef;

    ticketId: number;
    userId: number;
    myId: number;
    id: number;

    ticketMessageId: number;
    selectedUserId: number;
    ticketUsersId: number;
    ticketUsersTypeId: number;
    ticketActivityUserId:number =null;

    selectedAnswerTypes: AnswerType[] = [];
    newTicketMessages: any;
    imageUrl: string;
    userRole: string;
    userName: string;
    followerInformation: boolean = true;

    firstLevelRestriction: boolean;
    secondLevelRestriction: boolean;
    firstLevelRestrictionForEffort: boolean;
    secondLevelRestrictionForEffort: boolean;
    firstLevelRestrictionForTicket: boolean;
    secondLevelRestrictionForTicket: boolean;

    connectedTicketList: any[];
    ticketConnection: TicketConnection = new TicketConnection();


    user: User = new User();
    ticket: Ticket = new Ticket();
    module: Module = new Module();
    effort: Effort = new Effort();
    createdUser: User = new User();
    mentionUser: User = new User();
    partner: Partner = new Partner();
    priority: Priority = new Priority();
    ticketsPartner: Partner = new Partner();
    ticketsProject: Project = new Project();
    department: Department = new Department();
    ticketDetail: TicketUser = new TicketUser();
    ticketFollow: TicketFollow = new TicketFollow();
    ticketActivity: TicketActivity = new TicketActivity();
    ticketUserType: TicketUserType = new TicketUserType();
    deleteTicketUser: TicketUserType = new TicketUserType();

    users: User[] = [];
    modules: Module[] = [];
    efforts: Effort[] = [];
    taggedUsers: any[] = [];
    departments: Department[];
    ticketMessages: any[] = [];
    permissions: Permission[] = [];
    permissionUsers: PermissionUser[] = [];
    ticketUserTypes: TicketUserType[] = [];
    ticketActivities: TicketActivity[] = [];
    ticketUserTypesByTicketId: TicketUserType[] = [];

    deletedMessageId: number;
    deletedEffortId: number;
    deletedTicketActivityId: number;

    now: Date = new Date();
    day: number = this.now.getDate();
    month: number = this.now.getMonth() + 1;
    year: number = this.now.getFullYear();
    hour: number = this.now.getHours();
    minute: number = this.now.getMinutes();
    originalEffort:Effort=new Effort();
    today: string = new Date(this.year, this.month - 1, this.day, this.hour, this.minute).toLocaleDateString() + ' ' + new Date(this.year, this.month - 1, this.day, this.hour, this.minute).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    priorities: Priority[] = [
        { id: 1, name: "Yüksek" },
        { id: 2, name: "Normal" },
        { id: 3, name: "Düşük" },
    ];
    status: Status = new Status();
    statuses: Status[] = [
        { id: 1, name: "Yeni Oluşturuldu" },
        { id: 2, name: "Danışmandan Cevap Bekleniyor" },
        { id: 8, name: "Müşteriden Cevap Bekleniyor" },
        { id: 22, name: "Efor Onayı Bekleniyor" },
        { id: 4, name: "Tamamlandı" },
        { id: 5, name: "İptal Edildi" },
    ];
    effortStatus: EffortStatus = new EffortStatus();

    effortStatuses: EffortStatus[] = [
        { id: 1, name: "Onaylandı" },
        { id: 2, name: "Onay Bekliyor" },
        { id: 3, name: "Reddedildi" },
    ];
    locationType: LocationType = new LocationType();

    locationTypes: LocationType[] = [
        { id: 3, name: "Yerinde" },
        { id: 4, name: "Uzaktan" },
    ];

    answerType: AnswerType = new AnswerType();

    answerTypes: AnswerType[] = [
        { id: 1, name: "Çözüm Önerildi" },
        { id: 3, name: "Mesaj Gönder" },
        { id: 2, name: "Cevap Bekleniyor" },
    ];

    iconMappings: { [key: string]: string } = {
        'Ticket': 'file-text',          // Represents a generic document or ticket
        'TicketMessage': 'message-square', // Represents a message or communication
        'TicketActivity': 'activity',    // Represents activity or timeline
        'TicketUser': 'user',            // Represents a user or participant
        'TicketConnection': 'link',      // Represents a connection or relation
        'Effort': 'clock',               // Represents time or effort
        // Add more mappings as needed
      };

    private debounceTimeout: any;
    public isButtonDisabled = false;

    isSafely: boolean = false;

    navoption = 1;
    searchId: number;
    searchedTicket: Ticket;
    deleteTicketConnectionId: number;

    showPreviewImage = false;
    previewImageSrc = '';
    previewDocumentUrl: string = '';
    hoveredItem: FileItem;

    startDateControl: FormControl;
    startDateEffortControl: FormControl;
    chronologicalList : any[] = [];
    usersForActivity:User[]=[];
    constructor(
        private ticketService: EvoTicketService,
        private userService: EvoUserService,
        private ticketMessageService: EvoTicketMessageService,
        private departmentService: EvoDepartmentService,

        private ticketUserService: EvoTicketUserService,
        private ticketUserTypeService: EvoTicketUserTypeService,
        private ticketActivityService: EvoTicketActivityService,
        private partnerServcie: EvoPartnerService,
        private partnerUserService: EvoPartnerUserService,
        private projectService: EvoProjectService,
        private effortService: EvoEffortService,
        private moduleService: EvoModuleService,
        private permissionService: EvoPermissionService,
        private permissionUserService: EvoPermissionUserService,
        private ticketConnectionService: EvoTicketconnectionService,

        private route: ActivatedRoute,
        private router: Router,
        private informationService: InformationService,
        private decodeService: DecodeService,
        private helperService: HelperService,
        private errorService: ErrorService,
        private ticketLinkService: TicketLinkService,
        private auditService : EvoAuditService
    ) {
        let currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + 3);
        this.startDateControl = new FormControl(currentDate.toISOString().slice(0, 16));
        this.startDateEffortControl = new FormControl(currentDate.toISOString().slice(0, 16));
    }
    public uploader: FileUploader = null;
    ngOnInit(): void {

        if (localStorage.getItem("NewlyAdded") == "true") {
            localStorage.removeItem("NewlyAdded");
            setTimeout(()=>{
                window.location.reload();
            },100)
        }

        this.userName = this.decodeService.getUserName();
        this.userRole = this.decodeService.getRole();
        console.log(this.userRole);
        this.myId = this.decodeService.getUserId();
        this.ticketActivityUserId = this.myId;
        this.status.id = 0;
        this.ticketId = this.route.snapshot.params['TicketId'];
        Quill.register('modules/mention', QuillMention);
        this.getRequiredList();
        this.getPermissions();
        this.GetConnectedTickets()
        this.userService.getActiveUserList().subscribe((res: any) => {
            this.users = res.data
            this.usersForActivity = res.data.map(item=>{
                return {
                    ...item,  // Spread operator to include all existing properties of the item
                    fullName: item.firstName + " " + item.lastName // Adding the fullName property
                  };
            })
        })
        this.configureFileUploader();

    }

    getChronologicalList(id:number){
        this.auditService.getChronologicalList(id).subscribe((res:any)=>{
            this.chronologicalList = res.data;
        })
    }
    configureFileUploader() {
        this.uploader = new FileUploader({
            url: URL,
            isHTML5: true,
            additionalParameter: { ticketId: this.ticketId.toString() },

        });
        this.uploader.onBeforeUploadItem = (item) => {
            item.withCredentials = false;
        }
    }

    mentionSourceCallback(searchTerm: string, renderList: (items: any[], searchTerm: string) => void) {
        // Simulated data for demonstration purposes
        const mentionItems = this.users.map(user => {
            return {
                id: user.id,
                value: user.firstName + (user.lastName==null ? "" : " " + user.lastName)  // Change the parameter name here
            };
        });

        if (searchTerm.length === 0) {
            // Render full list when search term is empty
            renderList(mentionItems, searchTerm);
        } else {
            // Filter mention items based on search term
            const matches = mentionItems.filter(item =>
                item.value.toLowerCase().includes(searchTerm.toLowerCase())
            );
            // Render filtered list
            renderList(matches, searchTerm);
        }
    }

    renderMentionItem(item) {
        return item.value;
    }

    focusTicketActivity() {
        if (localStorage.getItem("TicketActivityId")) {
            this.getTicketActivityforPreview(parseInt(localStorage.getItem("TicketActivityId")));
            var ticketactivityuserid = parseInt(localStorage.getItem("TicketActivityUserId"))
            if (this.userRole == 'Admin' || ticketactivityuserid == this.myId || this.ticket.billTypeId != 4) {
                document.getElementById("hiddenButton").click()
                this.navoption = 2
                localStorage.removeItem("TicketActivityId")
                localStorage.removeItem("TicketActivityUserId")
            }
            else {
                this.errorService.errorHandler("yetki yok")
            }
        }
    }

    getPermissions() {
        this.permissionService.getList().subscribe((items: any) => {
            this.permissions = items.data;
            this.checkPermissionUsers();
            this.checkProjectView();
        });
    }
    GetConnectedTickets() {
        this.ticketConnectionService.getConnectedTickets(this.ticketId).subscribe((res: any) => {
            this.connectedTicketList = res.data;
        }, err => {
            console.log(err)
        })
    }

    addConnectionTickets() {
        if (this.searchedTicket.id == this.ticket.id) {
            this.informationService.warning("Talep kendisi ile bağlantı kuramaz")
        }
        else {
            var r = this.connectedTicketList.find(p => p.id == this.searchedTicket.id)
            if (r != null && this.searchedTicket.id == r.id) {
                this.informationService.warning("Talep Bağlantısı Zaten Mevcut")
            }
            else {
                this.ticketConnection.ticketId = this.ticket.id;
                this.ticketConnection.createdBy = this.userId;
                this.ticketConnection.connectedTicketId = this.searchedTicket.id;

                this.ticketConnectionService.add(this.ticketConnection).subscribe((res: any) => {
                    this.informationService.add(res.message)

                    this.GetConnectedTickets()
                })
            }
        }
        this.searchId = null
        this.searchedTicket = null
    }

    deleteTicketConnection() {
        this.ticketConnectionService.GetByTicketIds(this.deleteTicketConnectionId, this.ticketId).subscribe((res: any) => {
            var deletedData = res.data;
            deletedData.deletedBy = this.myId;
            deletedData.updatedBy = this.myId;
            this.ticketConnectionService.delete(deletedData).subscribe((res: any) => {
                this.informationService.delete(res.message)
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
        })
    }

    getSeacrhTicket() {
        if (this.searchId == null) {
            this.searchedTicket = null;
        }
        else {
            this.ticketService.getSearchTicket(this.searchId).subscribe((res: any) => {
                if (res.data.id != 0) {
                    this.searchedTicket = res.data;
                }
                else {
                    this.searchedTicket = null
                }
            })
        }
    }
    addStorageTicketId() {
        localStorage.setItem("TicketId", this.ticket.id.toString())
        this.router.navigate(["home/addticket"])
    }



    checkPermissionUsers() {
        this.permissionUserService.getPermissionUserListByUserId(this.myId).subscribe((items: any) => {
            this.permissionUsers = items.data;
            
            if (this.permissionUsers.some(user => user.permissionId === 1)) {
                this.firstLevelRestrictionForTicket = true;
            } else {
                this.firstLevelRestrictionForTicket = false;
            }

            if (this.permissionUsers.some(user => user.permissionId === 2)) {
                this.secondLevelRestrictionForTicket = true;
            } else {
                this.secondLevelRestrictionForTicket = false;
            }

            if (this.permissionUsers.some(user => user.permissionId === 5)) {
                this.firstLevelRestriction = true;
            } else {
                this.firstLevelRestriction = false;
            }

            if (this.permissionUsers.some(user => user.permissionId === 6)) {
                this.secondLevelRestriction = true;
            } else {
                this.secondLevelRestriction = false;
            }

            if (this.permissionUsers.some(user => user.permissionId === 8)) {
                this.firstLevelRestrictionForEffort = true;
            } else {
                this.firstLevelRestrictionForEffort = false;
            }

            if (this.permissionUsers.some(user => user.permissionId === 9)) {
                this.secondLevelRestrictionForEffort = true;
            } else {
                this.secondLevelRestrictionForEffort = false;
            }
        });
        this.getTicketDetailsByPermissions();
    }

    checkProjectView() {
        this.projectService.chechkProjectView(this.myId, this.ticketId).subscribe((res: any) => {
            if (res.data == false) {
                this.ticketId = 0;
                this.informationService.warning("Bu Talep İçin Proje Görüntüleme Yetkiniz Bulunmamaktadır!");
                setTimeout(() => {
                    this.router.navigate(['home/tickets']);
                }, 1000);
            }
            else {
                this.getDetails();
            }
        }, (err: any) => {
            this.errorService.errorHandler(err);
        })
    }

    getRequiredList(){
        this.ticketUserTypeService.getList().subscribe((res: any) => {
            this.ticketUserTypes = res.data;
        });
        this.departmentService.getList().subscribe((items: any) => {
            this.departments = items.data;
        });
        this.moduleService.getList().subscribe((items: any) => {
            this.modules = items.data;
        });
    }

    getDetails() {
        this.userService.getListForFilter().subscribe((res: any) => {
            this.users = res.data;

        });

        this.getTicketUserTypeList();
        this.list();

        this.ticketService.get(this.ticketId).subscribe((item: any) => {
            this.ticket = item.data;
            if(this.ticket.statusId==4){
                this.statuses = [{ id: 2, name: "Danışmandan Cevap Bekleniyor" },{ id: 8, name: "Müşteriden Cevap Bekleniyor" },{ id: 4, name: "Tamamlandı" }]
            }
            this.focusTicketActivity()
            this.ticketUserService.getDto(this.ticketId).subscribe((item: any) => {
                this.ticketDetail = item.data;
                this.projectService.get(this.ticket.projectId).subscribe((item: any) => {
                    this.ticketsProject = item.data;
                    this.partnerServcie.get(this.ticketsProject.partnerId).subscribe((item: any) => {
                        this.ticketsPartner = item.data;
                    })
                });
                this.userService.get(this.ticket.createdBy).subscribe((item: any) => {
                    this.createdUser = item.data;
                });
            });
        });
    }



    getTicketDetailsByPermissions() {
        if (this.firstLevelRestriction && !this.secondLevelRestriction) {
            this.ticketActivityService.restrictedTicketActivitiesWithTicketId(this.myId, this.ticketId).subscribe((res: any) => {
                this.ticketActivities = res.data.sort((a, b) => {
                    return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
                });

            });
        }
        if (this.secondLevelRestriction) {
            this.ticketActivityService.getUsersTicketActivitiesWithTicketId(this.myId, this.ticketId).subscribe((res: any) => {
                this.ticketActivities = res.data.sort((a, b) => {
                    return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
                });

            });
        }
        if (!this.firstLevelRestriction && !this.secondLevelRestriction) {
            this.ticketActivityService.getListByTicketUserId(this.ticketId).subscribe((res: any) => {
                this.ticketActivities = res.data.sort((a, b) => {
                    return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
                });
                

            });
        }
        if (this.firstLevelRestrictionForEffort && !this.secondLevelRestrictionForEffort) {
            this.effortService.restrictedEffortsWithTicketId(this.myId, this.ticketId).subscribe((res: any) => {
                this.efforts =res.data.sort((a, b) => {
                    return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
                });
            });
        }
        if (this.secondLevelRestrictionForEffort) {
            this.effortService.getUsersEffortsWithTicketId(this.myId, this.ticketId).subscribe((res: any) => {
                this.efforts =res.data.sort((a, b) => {
                    return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
                });
            });
        }
        if (!this.firstLevelRestrictionForEffort && !this.secondLevelRestrictionForEffort) {
            this.effortService.getListByTicketId(this.ticketId).subscribe((res: any) => {
                this.efforts =res.data.sort((a, b) => {
                    return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
                });
            });
        }
    }
    noinvoices: any = []

    effortLoader(){
        if (this.firstLevelRestrictionForEffort && !this.secondLevelRestrictionForEffort) {
            this.effortService.restrictedEffortsWithTicketId(this.myId, this.ticketId).subscribe((res: any) => {
                this.efforts =res.data.sort((a, b) => {
                    return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
                });
            });
        }
        if (this.secondLevelRestrictionForEffort) {
            this.effortService.getUsersEffortsWithTicketId(this.myId, this.ticketId).subscribe((res: any) => {
                this.efforts =res.data.sort((a, b) => {
                    return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
                });
            });
        }
        if (!this.firstLevelRestrictionForEffort && !this.secondLevelRestrictionForEffort) {
            this.effortService.getListByTicketId(this.ticketId).subscribe((res: any) => {
                this.efforts =res.data.sort((a, b) => {
                    return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
                });
            });
        }
    }

    noinvoicepicker = () => {
        this.noinvoices = this.ticketActivities.filter(p => p.billTypeId == 4)
    }
    getTicketUserTypeList() {
        this.ticketUserTypeService.getListByTicketId(this.ticketId).subscribe((res: any) => {
            this.ticketUserTypesByTicketId = res.data;
            for (let item = 0; item < this.ticketUserTypesByTicketId.length; item++) {
                if (this.myId == this.ticketUserTypesByTicketId[item].userId) {
                    this.followerInformation = true;
                    this.ticketUserType = this.ticketUserTypesByTicketId[item];
                    break;
                }
                else {
                    this.followerInformation = false;
                }
            }
        });
    }


    follow() {
        if (!this.isButtonDisabled) {
            this.isButtonDisabled = true;
        }

        let control = 0;

        for (let item = 0; item < this.ticketUserTypesByTicketId.length; item++) {
            if (this.ticketUserTypesByTicketId[item].userId == this.myId) {
                control = 1;
                break;
            }
        }

        if (control == 0) {
            this.ticketFollow = {
                id: 0,
                ticketId: this.ticketId,
                userId: this.myId,
                ticketUserTypeId: 9,

                createdBy: this.myId,
                updatedBy: 0,
                deletedBy: 0,
            }

            this.ticketUserService.follow(this.ticketFollow).subscribe((res: any) => {

            }, (err: any) => {
                this.errorService.errorHandler(err);
            }, () => {
                this.ngOnInit();
            });
        }

        else {
            this.informationService.warning("Zaten takip ediyorsunuz");
        }

        this.debounceTimeout = setTimeout(() => {
            this.isButtonDisabled = false;
        }, 1000);

    }

    ngOnDestroy() {
        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
        }
    }

    unFollow(ticketUserType: TicketUserType) {
        let flag = 0;

        if (this.ticketUserTypesByTicketId.length == 1) {
            flag = 2;
        }
        if (flag == 0) {
            this.ticketUserService.getTicketUserById(ticketUserType.ticketUserId).subscribe((item: any) => {
                item.data.deletedBy = this.myId;
                item.data.updatedBy = 0;

                this.ticketUserService.delete(item.data).subscribe((res: any) => {
                    window.location.reload();
                }, (err: any) => {
                    this.errorService.errorHandler(err);
                }, () => {
                    window.location.reload();
                });
            });
        }
        if (flag == 1) {
            this.informationService.warning("Bu kullanıcı zaten eklenmiş");
        }
        if (flag == 2) {
            this.informationService.warning("Talep Üzerinde Başka Kullanıcı Yok Silinemez!");
        }
    }

    list() {
        this.ticketMessageService.getList(this.ticketId).subscribe((res: any) => {
            this.ticketMessages = res.data;
            this.newTicketMessages = Object.values(this.ticketMessages.reduce((acc, user) => {
                if (!acc[user.id]) {
                    acc[user.id] = [];
                }
                acc[user.id].push(user);
                return acc;
            }, {}));
            this.informationService.list("Talep Detayları Başarılı Bir Şekilde Listelendi")
        }, (err: any) => {
            this.errorService.errorHandler(err)
        });

    }

    add(message: string,addedTicketId:number) {
        let flag = 0;

        if (message != null) {
            if (message.length > 4000) {
                this.informationService.warning("Mesajınız karakter sınırının üzerindedir!");
                flag = 1;
            }
        }


        if (flag == 0) {

            let formData = new FormData();
            formData.append("ticketId", addedTicketId.toString());
            formData.append("userId", this.myId.toString());
            formData.append("text", message);
            for (let i = 0; i < this.uploader.queue.length; i++) {
                formData.append('documents', this.uploader.queue[i]._file)
            }

            for (let i = 0; i < this.userList.length; i++) {
                this.ticketMessageService.sendMessage(addedTicketId, this.userList[i].id).subscribe((res: any) => {
                });
            }

            this.informationService.add("Mesajınız gönderiliyor...");
            this.ticketMessageService.add(formData).subscribe((res: any) => {
                this.informationService.add(res.message);
            }, (err) => {
                this.errorService.errorHandler(550);
            }, () => {
                setTimeout(() => {
                    this.getPermissions();
                    this.message = undefined;
                    this.uploader.clearQueue();
                }, 1000);
            });
        }
    }

    editorCreated(quillEditor: any) {
        this.quillEditor = quillEditor;
        // Attach text-change event listener
        this.quillEditor.on('text-change', () => {
            this.detectMentionDeletion();
        });
    }


    detectMentionDeletion() {
        // Get the current content of the editor
        const currContent = this.quillEditor.root.innerHTML;
        // Check if there was previous content
        if (this.prevContent !== null) {
            // Extract mentions from previous content
            const prevMentions = this.extractMentions(this.prevContent);
            const currMentions = this.extractMentions(currContent);
            // Find deleted mentions
            const deletedMentions = prevMentions.filter(mention => !currMentions.includes(mention));
            // Log deleted mentions
            if (deletedMentions.length > 0) {
                deletedMentions.forEach((deletedMention: any) => {
                    this.userList = this.userList.filter(x => x.id != Number.parseInt(deletedMention))
                })
                // Perform your operation when mention(s) are deleted
            }
        }
        // Update the previous content to the current content
        this.prevContent = currContent;
    }

    // Function to extract mentions from the editor content
    extractMentions(content) {
        // Regular expression to match mentions
        const mentionRegex = /<span class="mention" data-index="(.*?)" data-denotation-char="@" data-id="(.*?)" data-value="(.*?)">(.*?)<\/span>/g;
        // Extract mention values
        const mentions = [];
        let match;
        while ((match = mentionRegex.exec(content)) !== null) {
            mentions.push(match[2]);
        }
        return mentions;
    }
    getDocuments(event: any) {
        this.documents.push(this.uploader.queue);
    }

    downloadImage(name: string) {

        this.ticketMessageService.downloadDocument(this.ticketId, name).subscribe((data: any) => {
            const blob = new Blob([data.body as BlobPart], { type: data.body?.type });

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = name;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);


        }, (error: any) => {
        });
    }
    delete(id: number) {
        this.ticketMessageService.delete(id).subscribe((res: any) => {
            this.informationService.delete(res.message)
        }, (err) => {
            this.errorService.errorHandler(err);
        }, () => {
            setTimeout(() => {
                this.list();
            }, 1000);
        });
    }
    // -- Benden önce yapan arkadaşlar form kullanmadıkları için bu yöntemi kullanmak zorunda kaldım değiştirmek istemedim. Saygılarımla
    update(subject: string, expectedTime: number, partnerTicketNo: string) {
        let updatedTicket: Ticket = new Ticket();
        this.noinvoicepicker()
        if (this.noinvoices.length > 0 && this.ticket.billTypeId != 4) {
            this.informationService.warning("Talebin Aktiviteleri Faturalanmadığı İçin İşlem Gerçekleştirilemez")
            return
        }
        updatedTicket = {
            id: this.ticketId,
            trackingNumber: this.ticket.trackingNumber,
            departmentId: this.ticket.departmentId,
            priorityId: this.ticket.priorityId,
            subject: subject,
            statusId: this.ticket.statusId,
            departmentName: "",
            typeId: this.ticket.typeId,
            typeName: "",
            billTypeId: this.ticket.billTypeId,
            billTypeName: "",
            expectedTime: expectedTime,
            answerTypeId: this.ticket.answerTypeId,
            answerTypeName: "",
            partnerId: 0,
            partnerName: "",
            partnerTicketNo: partnerTicketNo,
            projectId: this.ticket.projectId,
            projectName: "",
            moduleId: this.ticket.moduleId,
            moduleName: "",

            createdBy: this.ticket.createdBy,
            createdDate: this.ticket.createdDate,
            createdByUserName: "",
            updatedBy: this.myId,
            deletedBy: 0,
        }
        this.ticketService.update(updatedTicket).subscribe((res: any) => {
            this.informationService.update(res.message)
            this.closeModal();
        }, (err) => {
            this.errorService.errorHandler(err);
        }, () => {
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        });
    }

    getTicketActivity(id: number) {
        this.ticketActivityService.get(id).subscribe((res: any) => {
            this.ticketActivity = res.data;
        }, (err) => {
            this.errorService.errorHandler(err);
        })
    }
    ticketActivityUser: User = new User();
    ticketActivityModule: Module = new Module();
    ticketActivityLocationType: LocationType = new LocationType();
    ticketActivityPartner: Partner = new Partner();

    getTicketActivityforPreview(id: number) {
        this.ticketActivityService.get(id).subscribe((res: any) => {
            this.ticketActivity = res.data;
            this.userService.get(this.ticketActivity.userId).subscribe((res: any) => {
                this.ticketActivity.userName = res.data.firstName + " " + res.data.lastName;
            });
            this.moduleService.get(this.ticketActivity.moduleId).subscribe((res: any) => {
                this.ticketActivity.moduleName = res.data.name;
            });
            this.locationTypes.forEach((item: LocationType) => {
                if (item.id == this.ticketActivity.locationTypeId) {
                    this.ticketActivity.locationTypeName = item.name;
                }
            });
            this.ticketActivity.partnerName = res.data.title;
            this.billTypes.forEach((item: BillType) => {
                if (item.id == this.ticket.billTypeId) {
                    this.ticketActivity.ticketBillTypeName = item.name;
                }
            });
        }, (err) => {
            this.errorService.errorHandler(err);
        })
    }


    addActivity(startDate: string, description: string, time: number) {
        let today;

        if (startDate == this.today) {
            today = new Date();

        } else {
            today = new Date(startDate);
        }

        today.setHours(today.getHours() + 3);

        if(this.userRole != 'Admin' && this.userRole!='Consultant'){
            this.ticketActivityUserId = this.myId
        }
        this.ticketActivity = {
            id: 0,
            startDate: today,
            time: time,
            userId: this.ticketActivityUserId,
            description: description,
            endDate: new Date(),
            userName: "",
            locationTypeId: this.locationType.id,
            locationTypeName: "",
            ticketId: this.ticketId,
            billTypeId: 0,
            trackingNumber: "",
            ticketStatusId: 0,
            ticketStatusName: "",
            ticketBillTypeId: 0,
            ticketBillTypeName: "",
            ticketSubject: "",
            approvementId: 0,
            isApprovement: true,
            moduleId: this.module.id,
            moduleName: "",
            partnerId: this.ticketDetail.partnerId,
            partnerName: "",
            partnerTicketNo: "",
            createdBy: this.myId,
            updatedBy: 0,
            deletedBy: 0,
        }
        this.ticketActivityService.add(this.ticketActivity).subscribe((res: any) => {
            this.informationService.add(res.message);
        }, (err) => {
            this.errorService.errorHandler(err);
        }, () => {
            setTimeout(() => {
                this.ngOnInit();
            }, 100);
        });
    }

    updateActivity() {
        this.ticketActivity.approvementId = 0;
        this.ticketActivity.updatedBy = this.myId;
        this.ticketActivity.deletedBy = 0;

        this.ticketActivityService.update(this.ticketActivity).subscribe((res: any) => {
            this.closeModal();

            this.informationService.update(res.message);
        }, (err) => {
            this.errorService.errorHandler(err);
        }, () => {
            setTimeout(() => {
                this.ngOnInit();
            }, 1000);
        });
    }

    deleteTicketActivity(id: number) {
        this.ticketActivityService.get(id).subscribe((item: any) => {
            item.data.deletedBy = this.myId;
            item.data.updatedBy = 0;
            item.data.approvementId = 0;
            this.ticketActivityService.delete(item.data).subscribe((res: any) => {
                this.informationService.delete(res.message);
            }, (err) => {
                this.errorService.errorHandler(err);
            }, () => {
                setTimeout(() => {
                    this.ngOnInit();
                }, 1000);
            });
        });
    }

    getTicketUserType(id: number) {
        this.ticketUserService.get(this.ticketId, id).subscribe((res: any) => {
            this.ticketDetail = res.data;
            this.ticketUsersId = res.data.userId;
            this.ticketUsersTypeId = res.data.typeId;
        })
    }

    addTicketUserType() {
        let flag = 0;

        let ticketUser: TicketUser = new TicketUser();

        for (let i = 0; i < this.ticketUserTypesByTicketId.length; i++) {

            if (this.ticketUserTypesByTicketId[i].userId == this.user.id) {
                flag = 1;
            }
        }
        if (flag == 0) {
            if (this.ticket.statusId == 1) {
                this.ticket.statusId = 2
                this.ticket.updatedBy = this.myId;
                this.ticket.deletedBy = 0;
                this.ticketService.update(this.ticket).subscribe((res: any) => {
                })
            }
            ticketUser = {
                id: 0,
                ticketId: this.ticketId,
                userId: this.user.id,
                typeId: 0,
                ticketUserTypeId: this.ticketUserType.id,
                typeName: "",
                userFirstName: "",
                userLastName: "",
                trackingNumber: "",
                subject: "",
                priorityId: 0,
                statusId: 0,
                departmentId: 0,
                departmentName: "",
                partnerId: 0,
                partnerName: "",
                partnerTicketNo: "",
                information: "",
                projectId: 0,
                projectName: "",
                ticketTypeId: 0,
                ticketTypeName: "",
                expectedTime: 0,
                moduleId: 0,
                moduleName: "",

                createdBy: this.myId,
                createdDate: new Date(),
                createdByUserName: "",
                updatedDate: new Date(),
                updatedBy: 0,
                deletedBy: 0,
            }

            if (this.ticketUserType.id == 6) {
                this.ticketUserService.add(ticketUser).subscribe((res: any) => {
                    this.informationService.add(res.message);
                }, (err) => {
                    this.errorService.errorHandler(err);
                }, () => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                });
            }

            else {
                this.ticketUserService.add(ticketUser).subscribe((res: any) => {
                    this.informationService.add(res.message);
                }, (err) => {
                    this.errorService.errorHandler(err);
                }, () => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                });
            }
        }
        else {
            this.informationService.warning("Bu kullanıcı zaten eklenmiş");
        }
    }

    updateTicketUserType() {
        let flag = 0;
        let index;
        let sorumluCount = 0;

        if (this.ticketDetail.typeId == 6) {
            flag = 0;
        }
        if (this.ticketDetail.typeId == 9) {
            for (let i = 0; i < this.ticketUserTypesByTicketId.length; i++) {
                if (this.ticketUserTypesByTicketId[i].name == "Sorumlu" || this.ticketUserTypesByTicketId[i].userId == this.user.id) {
                    flag = 1;
                    index = i;
                }
                if (this.ticketUserTypesByTicketId[i].name == "Sorumlu") {
                    sorumluCount++;
                }
            }

            if (sorumluCount > 1) {
                flag = 0;
            }
            else if (sorumluCount == 1 && this.ticketUsersTypeId == this.ticketDetail.typeId && this.ticketUsersId == this.ticketDetail.userId) {
                flag = 0;
            }
            else if (sorumluCount == 1 && this.ticketUsersTypeId == this.ticketDetail.typeId && this.ticketUsersId != this.ticketDetail.userId) {
                flag = 0;
            }
        }

        if (flag == 1) {
            this.informationService.warning("Talep İçerisinde En Az Bir Sorumlu Bulunması Gerekiyor");
        }
        for (let i = 0; i < this.ticketUserTypesByTicketId.length; i++) {
            if (this.ticketDetail.userId != this.ticketUsersId && this.ticketUserTypesByTicketId[i].userId == this.user.id) {
                this.informationService.warning("Bu Kullanıcı Zaten Eklenmiş");
                flag = 1;
            }
        }
        if (flag == 0) {

            this.ticketDetail.ticketUserTypeId = this.ticketDetail.typeId;
            this.ticketDetail.updatedBy = this.myId;
            this.ticketDetail.deletedBy = 0;
            this.ticketUserService.update(this.ticketDetail).subscribe((res: any) => {
                this.informationService.update(res.message);
            }, (err) => {
                this.errorService.errorHandler(err);
            }, () => {
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            });
        }
    }

    updatePartnerInfo(information: string) {
        this.partnerServcie.get(this.ticketDetail.partnerId).subscribe((res: any) => {
            this.partner = res.data;
            this.partner.information = information;
            this.partner.updatedBy = this.myId;
            this.partner.deletedBy = 0;
            this.partnerServcie.update(this.partner).subscribe((res: any) => {
                this.informationService.update(res.message);
            }, (err) => {
                this.errorService.errorHandler(err);
            }, () => {
                setTimeout(() => {
                    this.ngOnInit();
                }, 1000);
            });
        })

    }

    deleteTicketUserType(id: number, userId: number, name: string) {
        let flag = 0;

        if (this.userId == userId && this.ticketUserTypesByTicketId.length == 1) {
            this.informationService.warning("Talep Üzerinde Başka Kullanıcı Yok Silinemez!");
        }

        else {
            if (name == 'Takipçi') {
                this.ticketUserService.getTicketUserById(id).subscribe((item: any) => {
                    item.data.deletedBy = this.myId;
                    item.data.updatedBy = 0;

                    this.ticketUserService.delete(item.data).subscribe((res: any) => {
                        this.informationService.delete(res.message);
                    }, (err) => {
                        this.errorService.errorHandler(err);
                    }, () => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    });
                });
            }
            else {
                for (let item = 0; item < this.ticketUserTypesByTicketId.length; item++) {
                    if (this.ticketUserTypesByTicketId[item].name == 'Sorumlu') {
                        flag += 1;
                    }
                }
                if (flag == 1) {
                    this.informationService.warning("Talepte En Az Bir Tane Sorumlu Olmalı");
                }
                else {
                    this.ticketUserService.getTicketUserById(id).subscribe((item: any) => {

                        item.data.deletedBy = this.myId;
                        item.data.updatedBy = 0;

                        this.ticketUserService.delete(item.data).subscribe((res: any) => {
                            this.informationService.delete(res.message);
                        }, (err) => {
                            this.errorService.errorHandler(err);
                        }, () => {
                            setTimeout(() => {
                                window.location.reload();
                            }, 1000);
                        });
                    });
                }
            }
        }
    }
    applySecurityKod(){
        let securityKodDto : SecurityKodDto = new SecurityKodDto()
        securityKodDto.securityKod = this.securityKod;
        securityKodDto.userId = this.myId
        securityKodDto.ticketId = this.ticketId
        this.ticketLinkService.ApplyRequest(securityKodDto).subscribe(res=>{
            this.isSafely = res.success
            if(res.success){
                this.informationService.get(res.message)
            }
            else{
                this.informationService.warning(res.message)
            }
        },err=>{
            this.isSafely = false
        })
    }
    linkRequest() {

        this.ticketLinkService.LogTicketConnectionRequest(this.myId).subscribe((res:any)=>{

        })

        if(this.userRole=='Admin'){
            this.isSafely = true;
        }
        else if(this.permissionUsers.some(user=>user.permissionId==15)){
            this.isSafely = true;
        }
        else{
            let ticketLink: TicketLink = new TicketLink();
            ticketLink.ticketId = this.ticketId
            ticketLink.userId = this.myId
            ticketLink.securityValue = ""
            ticketLink.id = 0
            ticketLink.createdDate = new Date()
    
            if(!this.isSafely){
                this.ticketLinkService.LinkRequest(ticketLink).subscribe(res => {
                    if (res.success) {
                        this.informationService.get(res.message)
                    }
                    else {
                        this.informationService.warning(res.message)
                    }
                }, err => {
                    this.isSafely = false
                })
            }
        }

    }

    getEffort(id: number) {
        this.effortService.get(id).subscribe((res: any) => {
            this.effort = res.data;
            this.originalEffort = {...res.data};
        })
    }

    getEffortByPartner(id: number) {
        this.effortService.get(id).subscribe((res: any) => {
            this.efforts = res.data;
        });
    }
    openEfortModal() {
        let currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + 3);
        this.startDateEffortControl = new FormControl(currentDate.toISOString().slice(0, 16));

        this.user.id = this.myId;
        this.effort = new Effort();
        this.originalEffort = new Effort();
    }
    addEffort(time: number, description: string, createdDate: string) {
        let today;
        let flag = 0;
        if (createdDate == this.today) {
            today = new Date();
        }
        else {
            today = new Date(createdDate);
        }

        const date = new Date(createdDate);
        today = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() + 3, date.getMinutes(), 0, 0);
        if (time == null || time == 0) {
            flag = 1;
            this.informationService.warning("Efor Süresi Giriniz");
        }

        if (flag == 0) {
            this.effort = {
                id: 0,
                ticketId: this.ticketId,
                userId: this.user.id,
                time: time,
                description: description ? description : " ",
                statusId: 2,
                startDate: today,
                createdDate: new Date(),
                partnerUserId: null,
                partnerUserName: "",
                userName: "",
                createdBy: this.myId,
                approvalDate:null,
                updatedBy: 0,
                deletedBy: 0,
            };
            this.effortService.add(this.effort).subscribe((res: any) => {
                this.informationService.add(res.message);
            }, (err) => {
                this.errorService.errorHandler(err);
            }, () => {
                setTimeout(() => {
                    this.effortLoader();
                }, 150);
            });
        }

    }

    updateEffort() {
        this.effort.updatedBy = this.myId;
        this.effort.deletedBy = 0;

        if(this.effort.statusId == 1 || this.effort.statusId == 3){
            this.effort.partnerUserId = this.myId;
        }

        if (this.effort.time == null) {
            this.informationService.warning("Efor Süresi Giriniz");
            return;
        }
        if (this.effort.description == null) {
            this.informationService.warning("Açıklama Alanını Doldurunuz.");
            return;
        }
        this.effortService.update(this.effort).subscribe((res: any) => {
            this.informationService.update(res.message);
            this.closeModal();

        }, (err) => {
            this.errorService.errorHandler(err);
        }, () => {
            setTimeout(() => {
                this.effortLoader();
            }, 150);
        });
    }


    updateStatus() {
        if (this.userRole == "Customer" || this.userRole == "CustomerUser") {
            this.effort.partnerUserId = this.myId;
            this.effort.updatedBy = this.myId;
            this.effort.deletedBy = 0;

            this.effortService.updateStatus(this.effort).subscribe(
                (res: any) => {
                    this.informationService.update(res.message);
                },
                (err) => {
                    this.errorService.errorHandler(err);
                },
                () => {
                    setTimeout(() => {
                        this.ngOnInit();
                    }, 1000);
                }
            );
        }
    }

    deleteEffort(id: number) {
        this.effortService.get(id).subscribe((item: any) => {
            item.data.deletedBy = this.myId;
            item.data.updatedBy = 0;
            this.effortService.delete(item.data).subscribe((res: any) => {
                this.informationService.delete(res.message);
            }, (err) => {
                this.errorService.errorHandler(err);
            }, () => {
                setTimeout(() => {
                    this.effortLoader();
                }, 1000);
            });
        });
    }





    getInitials(name: string) {
        const userName = name;
        const firstInitial = userName.charAt(0);
        const lastInitial = userName.lastIndexOf(' ') > -1
            ? userName.substring(userName.lastIndexOf(' ') + 1, userName.lastIndexOf(' ') + 2)
            : '';

        return firstInitial + lastInitial;
    }
    //content = '';
    // mentionConfig = {
    //   source: (searchTerm, renderList, mentionChar) => {
    //     const matches = [];
    //     for (let i = 0; i < this.users.length; i++) {
    //       const user = this.users[i];
    //       if (user.firstName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
    //         matches.push(user);
    //       }
    //     }
    //     renderList(matches, mentionChar);
    //   },
    //   itemRenderer: (item) => {
    //     return `<div class="mention-item">${item.name}</div>`;
    //   }
    // };
    // quillModules = {
    //   mention: {
    //     allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    //     mentionDenotationChars: ['@'],
    //     source: this.mentionConfig.source,
    //     renderItem: this.mentionConfig.itemRenderer,
    //     mentionClassName: 'ql-mention',
    //     onSelect: (item, insertItem) => {
    //       insertItem(item);
    //     }
    //   }
    // };

    checkTicketStatus(){
        console.log(this.ticket);
        if(this.ticket.statusId == 4){
            var formModal = new window.bootstrap.Modal(document.getElementById('addConnectedTicketToNewTicket'))
            formModal.show();
  
        }
        else{
            this.add(this.message,this.ticketId);
        }
    }

    addNewTicketToCompletedTicket(){
        var addNewTicket = this.ticket;
        addNewTicket.id = 0;
        addNewTicket.updatedBy = 0;
        addNewTicket.deletedBy = 0;
        addNewTicket.createdBy = this.myId;
        addNewTicket.createdDate = new Date(); 
        addNewTicket.createdByUserName = "";
        addNewTicket.trackingNumber = "00000000"
        addNewTicket.statusId = 1;
        addNewTicket.subject = addNewTicket.subject + " - Devam";
        
        this.ticketService.add(addNewTicket).subscribe((res:any)=>{
            let ticketId = res.data.id;
            this.add(this.message,ticketId);

            this.ticketConnection.ticketId = ticketId;
            this.ticketConnection.createdBy = this.myId;
            this.ticketConnection.connectedTicketId = this.ticketId;

            this.ticketConnectionService.add(this.ticketConnection).subscribe((res: any) => {
                this.informationService.add(res.message)
                this.ticketId = ticketId;

                setTimeout(() => {
                    localStorage.setItem("NewlyAdded","true");
                    this.router.navigateByUrl("/home/details/" + ticketId).then(() => {
                        window.location.reload();
                      });
                  }, 1500);
            })
    
        })

    }


    exportExcel() {
        let element = document.getElementById("excel-table");
        let title = "Aktivite Bilgileri";
        this.helperService.exportExcel(element, title);
    }

    exportExcelTicketUserType() {
        let element = document.getElementById("excel-table-ticketusertype");
        let title = "Takipçi Listesi";
        this.helperService.exportExcel(element, title);
    }

    exportExcelEffort() {
        let element = document.getElementById("excel-table-effort");
        let title = "Efor Bilgileri";
        this.helperService.exportExcel(element, title);
    }
    isModalOpen = false;

    openModal() {
        this.isModalOpen = true;
    }
    // addTag() {
    //   let message = this.message;
    //   if (message[message.length - 5] == "@") {
    //     this.isModalOpen = true;
    //     this.mentionUser.id = 0;
    //     this.userbutton.nativeElement.click();
    //   }
    // }

    mentionUserChange() {
        let findUser = new User();

        if (this.mentionUser.id == 0) {
            return;
        }
        let newTaggedUser = { id: this.mentionUser.id };
        this.taggedUsers.push(newTaggedUser);

        this.userService.get(this.mentionUser.id).subscribe((res: any) => {
            findUser = res.data;
            let message = this.message;

            const lastIndex = message.lastIndexOf("@");
            const username = message.substring(0, lastIndex) + '<strong style="color:purple; ">' + findUser.firstName + " " + findUser.lastName + '</strong>&nbsp;<p></p> ' + message.substring(lastIndex + 1);
            this.message = username;

            this.isModalOpen = false; // Set isModalOpen to false to indicate that the modal is closed
        });
    }


    closeModal() {
        this.isModalOpen = false; // Modal'ın kapatıldığını işaretlemek için isModalOpen'ı false yap
    }

    // showPreviewMessage(documentUrl: string) {
    //   this.showPreviewImage = true;
    //   this.previewImageSrc = '../../../../assets/images/img/' + documentUrl;
    //   this.previewDocumentUrl = documentUrl;
    // }

    // hidePreviewMessage() {
    //   this.showPreviewImage = false;
    //   this.previewImageSrc = '';
    //   this.previewDocumentUrl = '';
    // }


    selectedFileUrl: string;


    previewImage(item: any) {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            this.selectedFileUrl = event.target.result;
        };
        reader.readAsDataURL(item.file);
    }

    showPreview(item: any) {
        this.hoveredItem = item;
    }

    hidePreview() {
        this.hoveredItem = null;
    }


}
