export class UserList{
    id:number;
    userName:string;
    firstName:string;
    title:string;
    lastName:string;
    password:string;
    mailAddress:string;
    phoneNumber:string;
    genderId:number;
    roleId:number;
    typeId:number;
    partnerId:number;
    imageURL:string;
    vacationCount:number;

    partnerName:string;
    discord?: string;
    gitlab?: string;
    mobileVersion?:string;
    birthdayDate?: string;
    
    createdBy:number;
    updatedBy:number;
    deletedBy:number;

    loginDate:string;

    isActive:boolean;
}