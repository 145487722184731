<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="basic-vertical-layouts">
            <div class="row match-height">
                <div class="col-md-2"></div>
                <div class="col-md-12 col-12">
                    <core-card-snippet>
                        <h4 class="card-title">Kullanıcı Güncelle</h4>

                        <div class="card-body">
                            <form class="form form-vertical" #UserForm="ngForm">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Ad</label>
                                            <input type="text" [(ngModel)]="user.firstName" required id="name-vertical"
                                                class="form-control" name="txtFirstName" placeholder="Kullanıcının Adı"
                                                #txtFirstName="ngModel" [ngModelOptions]="{standalone: true}" />

                                            <small class="text-danger"
                                                *ngIf="txtFirstName.invalid&& txtFirstName.touched">Kullanıcının Adı boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Soyad</label>
                                            <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="user.lastName" required id="name-vertical"
                                                class="form-control" name="txtLastName" placeholder="Kullanıcın Soyadı"
                                                #txtLastName="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtLastName.invalid&& txtLastName.touched">Kullanıcının Soyadı
                                                boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Kullanıcının Başlığı</label>
                                            <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="user.title" required id="name-vertical"
                                                class="form-control" name="txtTitle" placeholder="Kullanıcının Başlığı"
                                                #txtTitle="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtTitle.invalid&& txtTitle.touched">Kullanıcının Başlığı boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Kullanıcının Adı</label>
                                            <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="user.userName" required id="name-vertical"
                                                class="form-control" name="txtName" placeholder="Kullanıcı Adı"
                                                #txtName="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtName.invalid&& txtName.touched">Kullanıcı Adı boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Kullanıcının Mail Adresi</label>
                                            <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="user.mailAddress" required
                                                id="name-vertical" class="form-control" name="txtMailAddress"
                                                placeholder="Mail Adresi" #txtMailAddress="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtMailAddress.invalid&& txtMailAddress.touched">Mail Adresi boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Doğum Günü Seçiniz</label>
                                            <input type="date" [(ngModel)]="user.birthdayDate" class="form-control" name="txtStartDate"
                                            >
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Kullanıcının Gitlab Adresi</label>
                                            <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="user.gitlab"
                                                id="name-vertical" class="form-control" name="txtMailAddress"
                                                placeholder="Gitlab Adresi" #txtGitlabAddress="ngModel" />

                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Kullanıcının Discord Adresi</label>
                                            <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="user.discord"
                                                id="name-vertical" class="form-control" name="txtMailAddress"
                                                placeholder="Discord Adresi" #txtDiscordAddress="ngModel" />
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Kullanıcın Telefon Numarası</label>
                                            <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="user.phoneNumber" required
                                                id="name-vertical" class="form-control" name="txtPhoneNumber"
                                                placeholder="Telefon Numarası" #txtPhoneNumber="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtPhoneNumber.invalid&& txtPhoneNumber.touched">Telefon Numarası
                                                boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Kullanıcın Cinsiyeti</label>
                                            <select class="form-control" ngModel required name="slcGender"
                                                [(ngModel)]="user.genderId" #slcGender="ngModel" [ngModelOptions]="{standalone: true}">
                                                <option *ngFor="let item of genders" value="{{item.id}}">{{item.name}}
                                                </option>
                                            </select>
                                            <small class="text-danger"
                                                *ngIf="slcGender.invalid&& slcGender.touched">Cinsiyet bilgisi boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Kullanıcı Tipi</label>
                                            <select class="form-control" required name="slcUserType"
                                                [(ngModel)]="user.typeId" #slcUserType="ngModel" [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="changeUserType()">
                                                <option *ngFor="let item of userTypes" value="{{item.id}}">{{item.name}}
                                                </option>
                                            </select>
                                            <small class="text-danger"
                                                *ngIf="slcUserType.invalid&& slcUserType.touched">Kullanıcı Tipi boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="user.typeId==2">
                                        <div class="form-group">
                                            <label for="name-vertical">Tedarikçi Seçiniz</label>
                                            <select class="form-control" [ngModelOptions]="{standalone: true}" ngModel required name="slcSupplier"
                                                [(ngModel)]="user.partnerId" #slcSupplier="ngModel">
                                                <option value="0" selected>Bir Tedarikçi Seçiniz</option>
                                                <option *ngFor="let item of suppliers" value="{{item.id}}">{{item.name}}
                                                </option>
                                            </select>
                                            <small class="text-danger"
                                                *ngIf="slcSupplier.invalid&& slcSupplier.touched">Tedarikçi bilgisi boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Bu Yıl için İzin Günü Sayısı</label>
                                            <input type="number" [ngModelOptions]="{standalone: true}" [(ngModel)]="user.vacationCount" class="form-control"
                                                placeholder="Bu Yıl için İzin Günü Sayısı" />
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Kullanıcının Şifresi</label>
                                            <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="user.password" id="name-vertical"
                                                class="form-control" name="txtPassword" placeholder="Kullanıcı Şifresi"
                                                #txtPassword="ngModel" />
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button type="submit" *ngIf="UserForm.valid" rippleEffect
                                            class="btn btn-success mr-1" [disabled]="!UserForm.valid"
                                            (click)="update()">Kaydet</button>

                                        <button type="submit" *ngIf="!UserForm.valid" rippleEffect
                                            class="btn btn-success mr-1" disabled>Kaydet</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </core-card-snippet>

                    <core-card-snippet>

                        <div class="card-body">
                            <ul ngbNav #nav="ngbNav" class="nav nav-pills">
                                <li ngbNavItem>
                                    <a ngbNavLink>Kullanıcı Yetkilerinin Listesi</a>
                                    <ng-template ngbNavContent>
                                        <div class="row">
                                            <div class="col-md-2 col-12">
                                                <button rippleEffect class="btn btn-primary ml-1"
                                                    title="Yeni Yetki Ekle" data-bs-toggle="modal"
                                                    data-bs-target="#adduseroperationclaim"><i
                                                        data-feather="plus"></i></button>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center m-1">
                                                <label class="d-flex align-items-center">Göster
                                                    <select class="form-control mx-25"
                                                        [(ngModel)]="basicSelectedOption" [ngModelOptions]="{standalone: true}">
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </label>
                                            </div>
                                        </div>
                                        <ngx-datatable [rows]="userOperationClaims" [rowHeight]="58"
                                            class="bootstrap core-bootstrap" [limit]="10" [headerHeight]="40"
                                            [footerHeight]="50" [scrollbarH]="true" [limit]="basicSelectedOption">
                                            <ngx-datatable-column [width]="200" [sortable]="false"
                                                [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                                                <ng-template ngx-datatable-header-template let-value="value"
                                                    let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input"
                                                            [checked]="allRowsSelected"
                                                            (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                                                        <label class="custom-control-label"
                                                            for="headerChkbxRef"></label>
                                                    </div>
                                                </ng-template>
                                                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex"
                                                    let-value="value" let-isSelected="isSelected"
                                                    let-onCheckboxChangeFn="onCheckboxChangeFn">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input"
                                                            [checked]="isSelected" (change)="onCheckboxChangeFn($event)"
                                                            id="rowChkbxRef{{ rowIndex }}" />
                                                        <label class="custom-control-label"
                                                            for="rowChkbxRef{{rowIndex}}"></label>
                                                    </div>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Kullanıcı" prop="userName" [width]="400">
                                                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                                    {{row.userName}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Yetkileri" prop="operationClaimTitle"
                                                [width]="400">
                                                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                                    <span *ngIf="row.operationClaimTitle=='Consultant'">Danışman
                                                        Yönetici</span>
                                                    <span *ngIf="row.operationClaimTitle=='ConsultantUser'">Danışman
                                                        Kullanıcı</span>
                                                    <span *ngIf="row.operationClaimTitle=='Admin'">Admin</span>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
                                                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                                    <div class="d-flex align-items-center">
                                                        <div ngbDropdown container="body">
                                                            <a ngbDropdownToggle href="javascript:void(0);"
                                                                class="hide-arrow" id="dropdownBrowserState"
                                                                data-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">
                                                                <i data-feather="more-vertical"
                                                                    class="text-primary cursor-pointer mr-50"></i>
                                                            </a>
                                                            <div ngbDropdownMenu class="dropdown-menu-right"
                                                                aria-labelledby="dropdownBrowserState">
                                                                <button ngbDropdownItem data-bs-toggle="modal"
                                                                    data-bs-target="#updateuseroperationclaim"
                                                                    (click)="getUserOperationClaim(row.id)"><i
                                                                        data-feather="edit-2"
                                                                        class="mr-50"></i><span>Güncelle</span></button>
                                                                <button ngbDropdownItem data-bs-toggle="modal"
                                                                    data-bs-target="#deleteuseroperationclaim"
                                                                    (click)="deleteUserOperationClaimId=row.id">
                                                                    <i data-feather="trash"
                                                                        class="mr-50"></i><span>Sil</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ngx-datatable-column>
                                        </ngx-datatable>
                                        <div class="modal fade " id="adduseroperationclaim" data-bs-backdrop="static"
                                            tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Yeni Yetki
                                                            Ekle</h1>
                                                        <button type="button" class="btn btn-secondary"
                                                            data-bs-dismiss="modal"><i data-feather="x-circle"
                                                                class="mr-50"></i>Çıkış</button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div class="form-group">
                                                            <label for="name-vertical">Yetkiyi Seçiniz</label>
                                                            <select class="form-control" name="slcOperationClaim"
                                                                [(ngModel)]="operationClaim.id"
                                                                #slcOperationClaim="ngModel" [ngModelOptions]="{standalone: true}">
                                                                <option value="6">Danışman Yönetici</option>
                                                                <option value="18">Danışman Kullanıcı</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-success"
                                                            data-bs-dismiss="modal" (click)="addUserOperationClaim()"><i
                                                                data-feather="check" class="mr-50"
                                                                data-bs-dismiss="modal"></i>Kaydet</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal fade " id="updateuseroperationclaim" data-bs-backdrop="static"
                                            tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Yetki
                                                            Güncelle</h1>
                                                        <button type="button" class="btn btn-secondary"
                                                            data-bs-dismiss="modal"><i data-feather="x-circle"
                                                                class="mr-50"></i>Çıkış</button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div class="form-group">
                                                            <label for="name-vertical">Yetkiyi Seçiniz</label>
                                                            <select class="form-control" name="slcOperationClaim"
                                                                [(ngModel)]="userOperationClaim.operationClaimId"
                                                                #slcOperationClaim="ngModel" [ngModelOptions]="{standalone: true}">
                                                                <option value="6">Danışman Yönetici</option>
                                                                <option value="18">Danışman Kullanıcı</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-success"
                                                            data-bs-dismiss="modal"
                                                            (click)="updateUserOperationClaim()"><i data-feather="check"
                                                                class="mr-50"
                                                                data-bs-dismiss="modal"></i>Kaydet</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal fade " id="deleteuseroperationclaim"
                                            aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Yetki Sil
                                                        </h1>
                                                        <button type="button" class="btn btn-secondary"
                                                            data-bs-dismiss="modal"><i data-feather="x-circle"
                                                                class="mr-50"></i>Çıkış</button>
                                                    </div>
                                                    <div class="modal-body">
                                                        Kaydı Silmek İstediğinize Emin Misiniz?
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-danger"
                                                            data-bs-dismiss="modal"
                                                            (click)="deleteUserOperationClaim(deleteUserOperationClaimId)"><i
                                                                data-feather="trash" class="mr-50"
                                                                data-bs-dismiss="modal"></i>Sil</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>
                                <li ngbNavItem>
                                    <a ngbNavLink>Atanan İzinlerin Listesi</a>
                                    <ng-template ngbNavContent>
                                        <table class="table table-bordered table-hover" style="text-align: center;">
                                            <tbody>
                                                <tr>
                                                    <td colspan="2">Talepler Hakkında</td>
                                                </tr>
                                                <tr>
                                                    <td *ngIf="user.typeId==2">
                                                        <input type="checkbox" [checked]="cantSeeAllTickets"
                                                            (change)="addPermissionUser()" [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="cantSeeAllTickets" class="custom-control-input"
                                                            id="allTickets" name="allTickets">
                                                        <label class="custom-control-label" for="allTickets">Şirketinin
                                                            Bütün Taleplerini
                                                            Görebilir</label>
                                                    </td>
                                                    <td>
                                                        <input type="checkbox" [checked]="canSeeMyTickets" [ngModelOptions]="{standalone: true}"
                                                            (change)="addPermissionUser()" [(ngModel)]="canSeeMyTickets"
                                                            class="custom-control-input" id="myTickets"
                                                            name="myTickets">
                                                        <label class="custom-control-label" for="myTickets">Sadece
                                                            Kendi Taleplerini Görebilir</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">Aktiviteler Hakkında</td>
                                                </tr>
                                                <tr>
                                                    <td *ngIf="user.typeId==2">
                                                        <input type="checkbox" [checked]="cantSeeAllActivities"
                                                            (change)="addPermissionUser()" [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="cantSeeAllActivities"
                                                            class="custom-control-input" id="allActivities"
                                                            name="allActivities">
                                                        <label class="custom-control-label"
                                                            for="allActivities">Şirketinin Bütün
                                                            Aktiviteleri
                                                            Görebilir</label>

                                                    </td>
                                                    <td>
                                                        <input type="checkbox" [checked]="canSeeMyActivities"
                                                            (change)="addPermissionUser()" [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="canSeeMyActivities"
                                                            class="custom-control-input" id="myActivities"
                                                            name="myActivities">
                                                        <label class="custom-control-label" for="myActivities">Sadece
                                                            Kendi
                                                            Aktivitelerini Görebilir</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">Eforlar Hakkında</td>
                                                </tr>
                                                <tr>
                                                    <td *ngIf="user.typeId==2">
                                                        <input type="checkbox" [checked]="cantSeeAllEfforts"
                                                            (change)="addPermissionUser()" [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="cantSeeAllEfforts" class="custom-control-input"
                                                            id="allEfforts" name="allEfforts">
                                                        <label class="custom-control-label" for="allEfforts">Şirketinin
                                                            Bütün
                                                            Eforları Görebilir</label>

                                                    </td>
                                                    <td>
                                                        <input type="checkbox" [checked]="canSeeMyEfforts"
                                                            (change)="addPermissionUser()" [ngModelOptions]="{standalone: true}" [(ngModel)]="canSeeMyEfforts"
                                                            class="custom-control-input" id="myEfforts"
                                                            name="myEfforts">
                                                        <label class="custom-control-label" for="myEfforts">Sadece
                                                            Kendi Eforlarını Görebilir</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">
                                                        Talep Bağlantıları Hakkında
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input type="checkbox" [checked]="canSeeTicketConnectionInfo" [ngModelOptions]="{standalone: true}"
                                                            (change)="addPermissionUser()" [(ngModel)]="canSeeTicketConnectionInfo"
                                                            class="custom-control-input" id="myTicketInfos"
                                                            name="myTicketInfos">
                                                        <label class="custom-control-label" for="myTicketInfos">Talep Bağlantılarını 
                                                            Görüntüleyebilir</label>
                                                    </td>
                                                    <td>

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">Carilerin Listesi Hakkında</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input type="checkbox" [checked]="cantSeeAllPartners"
                                                            (change)="addPermissionUser()" [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="cantSeeAllPartners"
                                                            class="custom-control-input" id="customers"
                                                            name="customers">
                                                        <label class="custom-control-label" for="customers">Carilerin
                                                            Listesini
                                                            Göremez</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">Masrafların Listesi Hakkında </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <input type="checkbox" [checked]="cantSeeAllExpenses"
                                                            (change)="addPermissionUser()" [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="cantSeeAllExpenses"
                                                            class="custom-control-input" id="expenses" name="expenses">
                                                        <label class="custom-control-label" for="expenses">Masrafların
                                                            Listesini
                                                            Göremez</label>
                                                    </td>
                                                </tr>
                                                <td colspan="2">Hedef Kartları Hakkında</td>
                                                <tr>
                                                    <td>
                                                        <input type="checkbox" [checked]="cantSeeAllGoals"
                                                            (change)="addPermissionUser()" [ngModelOptions]="{standalone: true}" [(ngModel)]="cantSeeAllGoals"
                                                            class="custom-control-input" id="targetCards"
                                                            name="targetCards">
                                                        <label class="custom-control-label" for="targetCards">Hedef
                                                            Kartlarını
                                                            Göremez</label>
                                                    </td>
                                                </tr>
                                                <td colspan="2">Aktivite Bildirimleri Hakkında</td>
                                                <tr>
                                                    <td>
                                                        <input type="checkbox" [checked]="exemptFromNotifications"
                                                            (change)="addPermissionUser()" [ngModelOptions]="{standalone: true}" [(ngModel)]="exemptFromNotifications"
                                                            class="custom-control-input" id="exemptFromNotifications"
                                                            name="targetCards">
                                                        <label class="custom-control-label" for="exemptFromNotifications">Aktivite Bildirimlerinden Muaf</label>
                                                    </td>
                                                </tr>
                                                <td colspan="2">Satınalma Talepleri Hakkında</td>
                                                <tr>
                                                    <td>
                                                        <input type="checkbox" [checked]="cantSeePurchaseEnquiries"
                                                            (change)="addPermissionUser()" [ngModelOptions]="{standalone: true}" [(ngModel)]="cantSeePurchaseEnquiries"
                                                            class="custom-control-input" id="cantSeePurchaseEnquiries"
                                                            name="targetCards">
                                                        <label class="custom-control-label" for="cantSeePurchaseEnquiries">Satınalma Taleplerini Göremez</label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </ng-template>
                                </li>
                                <li ngbNavItem>
                                    <a ngbNavLink>Projelerin Listesi</a>
                                    <ng-template ngbNavContent>
                                        <div class="row my-1">
                                            <div class="col-md-2 col-12">
                                                <button rippleEffect class="btn btn-primary ml-1"
                                                    title="Yeni Yetki Ekle" data-bs-toggle="modal"
                                                    data-bs-target="#addUserProjectExpensePermission"><i
                                                        data-feather="plus"></i></button>
                                            </div>
                                        </div>
                                        <table class="table table-hover table-stripped p-1" style="text-align: center;">
                                            <thead>
                                                <tr>
                                                    <th> Cari Adı</th>
                                                    <th> Proje Adı</th>
                                                    <th> İşlemler </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of projects">
                                                    <td>{{item.partnerName}}</td>
                                                    <td>{{item.title}}</td>
                                                    <div class="d-flex"
                                                        style="width: 100%;justify-content: center;margin-top: 2px;">
                                                        <button class="btn btn-danger" data-bs-toggle="modal"
                                                            data-bs-target="#deletePEPModal"
                                                            (click)="deletePEPid=item.id" title="sil">
                                                            <i data-feather="trash"></i>
                                                        </button>
                                                        <!-- <button class="btn btn-danger btn-sm" title="Sil"
                                                            (click)="deleteProjectExpensePermission(item.id)"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#deleteticketactivity"><i
                                                                data-feather="trash"></i></button> -->
                                                    </div>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </ng-template>
                                </li>
                                <li ngbNavItem>
                                    <a ngbNavLink>Acil Durum Kişileri Listesi</a>
                                    <ng-template ngbNavContent>
                                        <div class="row my-1">
                                            <div class="col-md-2 col-12">
                                                <button rippleEffect class="btn btn-primary ml-1" (click)="clearUserEmergencyContact()"
                                                    title="Yeni Acil Durum Kişisi Ekle" data-bs-toggle="modal"
                                                    data-bs-target="#addUserEmergencyContact"><i
                                                        data-feather="plus"></i></button>
                                            </div>
                                        </div>
                                        <table class="table table-hover table-stripped p-1" style="text-align: center;">
                                            <thead>
                                                <tr>
                                                    <th> Adı</th>
                                                    <th> Yakınlık Derecesi</th>
                                                    <th> Gsm </th>
                                                    <th> İşlemler</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of userEmergencyContacts">
                                                    <td>{{item.name}}</td>
                                                    <td>{{item.relation}}</td>
                                                    <td>{{item.gsmNo}}</td>
                                                    <td>
                                                        <div>
                                                            <button (click)="getUserEmergencyContact(item.id)" title="Güncelle" data-bs-toggle="modal" data-bs-target="#addUserEmergencyContact" class="btn btn-warning btn-sm mr-1 mb-1">
                                                                <i data-feather="edit-2"></i>
                                                            </button>                                          
                                                            <button (click)="getUserEmergencyContact(item.id)" title="Sil" data-bs-toggle="modal" data-bs-target="#deleteUserEmergencyContact" class="btn btn-danger btn-sm mb-1">
                                                                <i data-feather="trash"></i>
                                                            </button>
                                                        </div>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </ng-template>
                                </li>
                            </ul>
                            <div [ngbNavOutlet]="nav" class="mt-1"></div>
                        </div>

                    </core-card-snippet>
                </div>
            </div>
        </section>
    </div>
</div>


<div class="modal fade " id="addUserProjectExpensePermission" data-bs-backdrop="static"
tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Yeni Proje Yetkisi Ekle
                </h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="name-vertical">Cari Seçiniz</label>
                    <select class="form-control" name="slcParnerIdPEP"
                        [(ngModel)]="slcParnerIdPEP" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="changeProjectWithPartner()">

                        <option *ngFor="let item of partners" value="{{item.id}}">{{item.name}}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="name-vertical">Proje Seçiniz</label>
                    <select class="form-control" name="selectPRPId" [(ngModel)]="selectPRPId" [ngModelOptions]="{standalone: true}">
                        <option> Proje Seçiniz </option>
                        <option *ngFor="let item of PEPlist" [ngValue]="item.id"> {{item.title}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" data-bs-dismiss="modal"
                    (click)="addPEP()"><i data-feather="check" class="mr-50"
                        data-bs-dismiss="modal"></i>Kaydet</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade " id="deletePEPModal" data-bs-backdrop="static" tabindex="-1"
aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Yetki Sil
                </h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                Kaydı Silmek İstediğinize Emin Misiniz?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                    (click)="deleteProjectExpensePermission(deletePEPid)"><i
                        data-feather="trash" class="mr-50"
                        data-bs-dismiss="modal"></i>Sil</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade " id="addUserEmergencyContact" data-bs-backdrop="static"
tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Acil Durum Kişisi Ekle
                </h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
            </div>
            <form>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="name-vertical">Kişi Ad Soyadı</label>
                        <input type="text" [(ngModel)]="userEmergencyContact.name" required class="form-control"
                            name="txtEmergencyContactName" placeholder="Kişi Adı" #txtEmergencyContactName="ngModel" />

                        <small class="text-danger"
                            *ngIf="txtEmergencyContactName.invalid&& txtEmergencyContactName.touched">Ad/Soyad boş bırakılamaz.</small>
                    </div>
                    <div class="form-group">
                        <label for="name-vertical">Yakınlık Derecesi</label>
                        <input type="text" [(ngModel)]="userEmergencyContact.relation" required class="form-control"
                            name="txtEmergencyContactRelation" placeholder="Kişi Yakınlık Derecesi" #txtEmergencyContactRelation="ngModel" />

                        <small class="text-danger"
                            *ngIf="txtEmergencyContactRelation.invalid&& txtEmergencyContactRelation.touched">Kişi yakınlık derecesi boş bırakılamaz.</small>
                    </div>
                    <div class="form-group">
                        <label for="name-vertical">Telefon No</label>
                        <input type="text" [(ngModel)]="userEmergencyContact.gsmNo" required class="form-control"
                            name="txtEmergencyContactGsmNo" placeholder="Kişi Telefon Numarası" #txtEmergencyContactGsmNo="ngModel" />

                        <small class="text-danger"
                            *ngIf="txtEmergencyContactGsmNo.invalid&& txtEmergencyContactGsmNo.touched">Kişi telefon numarası boş bırakılamaz.</small>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success" data-bs-dismiss="modal"
                        (click)="saveUserEmergencyContact()"><i data-feather="check" class="mr-50"
                            data-bs-dismiss="modal"></i>Kaydet</button>
                </div>
            </form>
        </div>
    </div>
</div>


<div class="modal fade " id="deleteUserEmergencyContact" data-bs-backdrop="static" tabindex="-1"
aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Acil Durum Kişisi Sil
                </h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                Kaydı Silmek İstediğinize Emin Misiniz?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                    (click)="deleteUserEmergencyContact()"><i
                        data-feather="trash" class="mr-50"
                        data-bs-dismiss="modal"></i>Sil</button>
            </div>
        </div>
    </div>
</div>