import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ServerAccessTableItem } from '../models/server-access-table-item';

@Injectable({
  providedIn: 'root'
})
export class EvoServerAccessTableItemService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<ServerAccessTableItem[]>{
    return this.httpClient.get<ServerAccessTableItem[]>(this.baseApiUrl+'GetServerAccessTableItemList');
  }
  get (id: number):Observable <ServerAccessTableItem> {
    return this.httpClient.get<ServerAccessTableItem>(this.baseApiUrl+'GetServerAccessTableItem/'+id);
  }
  add(ServerAccessTableItem:ServerAccessTableItem):Observable<ServerAccessTableItem>{
    return this.httpClient.post<ServerAccessTableItem>(this.baseApiUrl+'AddServerAccessTableItem/',ServerAccessTableItem);
  }
  update(ServerAccessTableItem:ServerAccessTableItem):Observable<ServerAccessTableItem>{
    return this.httpClient.post<ServerAccessTableItem>(this.baseApiUrl+"UpdateServerAccessTableItem/",ServerAccessTableItem);
  }
  delete(ServerAccessTableItem:ServerAccessTableItem):Observable<ServerAccessTableItem>{
    return this.httpClient.post<ServerAccessTableItem>(this.baseApiUrl+"DeleteServerAccessTableItem",ServerAccessTableItem);
  }
}
