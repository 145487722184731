import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { ActivityInquiryDate } from 'app/evo/models/activity-inquiry-date';
import { AnnualTerm } from 'app/evo/models/annualterm';
import { AnnualTermList } from 'app/evo/models/annualtermlist';
import { Competence } from 'app/evo/models/competence';
import { CompetenceModules } from 'app/evo/models/competencemodules';
import { Module } from 'app/evo/models/module';
import { OperationClaim } from 'app/evo/models/operationclaim';
import { Server } from 'app/evo/models/server';
import { ServerList } from 'app/evo/models/serverlist.model';
import { User } from 'app/evo/models/user';
import { UserServerAccessPastTableList } from 'app/evo/models/user-server-access-past-table-list';
import { UserServerAccessTable } from 'app/evo/models/user-server-access-table';
import { UserServerAccessTableList } from 'app/evo/models/user-server-access-table-list';
import { EvoAnnualTermService } from 'app/evo/services/annualterm.service';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoCompetenceService } from 'app/evo/services/evo-competence.service';
import { EvoModuleService } from 'app/evo/services/evo-module.service';
import { EvoOperationClaimService } from 'app/evo/services/evo-operationclaim.service';
import { EvoServerAccessTableItemService } from 'app/evo/services/evo-server-access-table-item-service';
import { EvoServerControlService } from 'app/evo/services/evo-server-control.service';
import { EvoUserServerAccessTableService } from 'app/evo/services/evo-user-server-access-table.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';

const themes = {
  light: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css',
  dark: 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-dark-blue/theme.css'
};

@Component({
  selector: 'app-evo-user-server-access-table',
  templateUrl: './evo-user-server-access-table.component.html',
  styles: [
  ],
  providers:[ConfirmationService]
})
export class EvoUserServerAccessTableComponent implements OnInit,OnDestroy {

  constructor(
    private userServerAccessTableService:EvoUserServerAccessTableService,
    private serverAccessTableItemService:EvoServerAccessTableItemService,
    private userService:EvoUserService,
    private operationClaimService:EvoOperationClaimService,
    private errorService: ErrorService,
    private helperService: HelperService,
    private informationService: InformationService,
    private decodeService: DecodeService,
    private confirmationService:ConfirmationService,
    private router:Router,
    private cd:ChangeDetectorRef,
  ) { }

  userRole: string = "";
  userServerAccessTableList:UserServerAccessTableList[]=[];
  filteredUserServerAccessTableList:UserServerAccessTableList[]=[];

  userServerAccessTable:UserServerAccessTable = new UserServerAccessTable();
  pastUserServerAccessTableList:UserServerAccessPastTableList[] = [];

  basicSelectedOption:number=10;

  serverList:ServerList[]=[];
  userList:User[]=[];
  operationClaimList:OperationClaim[]=[];

  selectedUsers:any[]=[];

  private themeLink: HTMLLinkElement;
  private primengLink: HTMLLinkElement;
  private primeiconsLink: HTMLLinkElement;
  backgroundColor:string="default";
  action:string="Ekle";
  filterText:string="";

  ngOnDestroy(): void {
    this.unloadPrimeNGStyles();
  }

  ngOnInit(): void {
    this.loadPrimeNGStyles();
    this.applyThemeChanges();
    this.listenForThemeChanges();
    
    this.userRole = this.decodeService.getRole();

    if(this.userRole!='Admin' && this.userRole!='Consultant'){
        this.informationService.warning("Bu sayfayı görmek için yetkiniz bulunmamaktadır.")
        this.router.navigate(['/home']);
      }

    this.list();
    this.requiredData();

  }

  list() {
    this.userServerAccessTableService.getList().subscribe((res:any)=>{
        this.userServerAccessTableList = res.data;

        this.tableByFiltersId();
        this.informationService.list("Erişim Tablo Listesi Başarıyla Getirildi.");

    })

  }


  requiredData(){
    this.serverAccessTableItemService.getList().subscribe((res:any)=>{
      this.serverList = res.data;

    })

    this.userService.getActiveUserList().subscribe((res:any)=>{
      this.userList = res.data.map(item => {
        return {
          ...item,  // Spread operator to include all existing properties of the item
          fullName: item.firstName + " " + item.lastName // Adding the fullName property
        };
      });    
    })

    this.operationClaimService.getList().subscribe((res:any)=>{
      this.operationClaimList = res.data;
    })

    this.userServerAccessTableService.getPastList().subscribe((res:any)=>{
      this.pastUserServerAccessTableList = res.data;
    })
  }


  getPastData(){
    this.userServerAccessTableService.getPastList().subscribe((res:any)=>{
      this.pastUserServerAccessTableList = res.data;
    })
  }

  get(id: number) {
    this.userServerAccessTableService.get(id).subscribe((res:any)=>{
        this.userServerAccessTable = res.data;
    })
  }

  clear(){
    this.userServerAccessTable = new UserServerAccessTable();
  }

  saveUserServerAccessTable(){
    if(this.userServerAccessTable.id==0){
      this.addUserServerAccessTable();
    }
    else{
      this.updateUserServerAccessTable();
    }
  }

  addUserServerAccessTable(){
    this.userServerAccessTableService.add(this.userServerAccessTable).subscribe((res:any)=>{
        this.informationService.add("Erişim verisi eklendi.");
    },(err) => {
        this.errorService.errorHandler(err);
    },()=>{
        setTimeout(()=>{
          this.list();
          this.getPastData();
        },150);
    });

  }

  updateUserServerAccessTable(){
    this.userServerAccessTableService.update(this.userServerAccessTable).subscribe((res:any)=>{
        this.informationService.update("Erişim verisi güncellendi.");

    },(err)=>{
        this.errorService.errorHandler(err);
    },()=>{
        setTimeout(()=>{
          this.list();
          this.getPastData();

        },150);
    })
  }

  loadPrimeNGStyles() {
    const head = document.getElementsByTagName('head')[0];

    // PrimeNG theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/themes/lara-light-blue/theme.css';
    head.appendChild(this.themeLink);

    // PrimeNG core styles
    this.primengLink = document.createElement('link');
    this.primengLink.rel = 'stylesheet';
    this.primengLink.href = 'https://cdn.jsdelivr.net/npm/primeng/resources/primeng.min.css';
    head.appendChild(this.primengLink);

    // PrimeIcons
    this.primeiconsLink = document.createElement('link');
    this.primeiconsLink.rel = 'stylesheet';
    this.primeiconsLink.href = 'https://cdn.jsdelivr.net/npm/primeicons/primeicons.css';
    head.appendChild(this.primeiconsLink);
  }

  unloadPrimeNGStyles() {
    if (this.themeLink) {
      this.themeLink.remove();
    }
    if (this.primengLink) {
      this.primengLink.remove();
    }
    if (this.primeiconsLink) {
      this.primeiconsLink.remove();
    }
  }

  turkishOperationNames(key:string){
    var dictionary: Map<string, string> = new Map([
      ['add', 'Yeni'],
      ['update', 'Değişiklik'],
      ['delete', 'İptal']
    ]);
  
    return dictionary.get(key);
  }

  delete() {

    this.userServerAccessTableService.delete(this.userServerAccessTable).subscribe((res:any)=>{
      this.informationService.delete("Erişim verisi silindi.");
    },(err) => {
        this.errorService.errorHandler(err);
    },()=>{
        setTimeout(()=>{
          this.list()
          this.getPastData();
        },150);
    });
  }

  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "F-60 Kullanıcı Erişim Formu";
    this.helperService.exportExcel(element, title);
  }


  applyThemeChanges() {
    const backgroundColor = localStorage.getItem("backgroundColor");
  
    // If a theme is already applied, remove it first
    if (this.themeLink) {
      this.themeLink.remove();
    }
  
    // Create a new link element and load the appropriate theme
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = backgroundColor === 'dark' ? themes.dark : themes.light;
    document.head.appendChild(this.themeLink);
}

tableByFiltersId() {
  

  let filteredItems = this.userServerAccessTableList as any;

  if (this.selectedUsers.length > 0) {
    filteredItems = filteredItems.filter(item => this.selectedUsers.includes(item.userId));
  }

  this.filteredUserServerAccessTableList = filteredItems;

}

listenForThemeChanges() {
    window.addEventListener('localStorageChange', () => {
      const backgroundColor = localStorage.getItem('backgroundColor');
      console.log('Background color changed:', backgroundColor);
      this.backgroundColor = backgroundColor;
      // Trigger Angular's change detection to update the view
      this.cd.detectChanges();
      this.applyThemeChanges(); // Apply the new theme instantly
    });
  }

}
