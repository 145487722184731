import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { DocumentForm } from '../models/document-form';
import { DocumentFormApprovalOperation } from '../models/document-form-approval-operation';

@Injectable({
  providedIn: 'root'
})
export class EvoDocumentFormService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getPendingApprovalListByUserId():Observable<DocumentForm[]>{
    return this.httpClient.get<DocumentForm[]>(this.baseApiUrl+'GetDocumentFormPendingApprovalListByUserId');
  }
  getList():Observable<DocumentForm[]>{
    return this.httpClient.get<DocumentForm[]>(this.baseApiUrl+'GetDocumentFormList');
  }

  getPastList():Observable<DocumentForm[]>{
    return this.httpClient.get<DocumentForm[]>(this.baseApiUrl+"GetPastDocumentFormApprovalTableList");
  }
  get (id: number):Observable <DocumentForm> {
    return this.httpClient.get<DocumentForm>(this.baseApiUrl+'GetDocumentForm/'+id);
  }
  add(department:DocumentForm):Observable<DocumentForm>{
    return this.httpClient.post<DocumentForm>(this.baseApiUrl+'AddDocumentForm/',department);
  }
  update(department:DocumentForm):Observable<DocumentForm>{
    return this.httpClient.post<DocumentForm>(this.baseApiUrl+"UpdateDocumentForm/",department);
  }
  delete(department:DocumentForm):Observable<DocumentForm>{
    return this.httpClient.post<DocumentForm>(this.baseApiUrl+"DeleteDocumentForm/",department);
  }
  sendDocumentFormForApproval(department:DocumentForm):Observable<DocumentForm>{
    return this.httpClient.post<DocumentForm>(this.baseApiUrl+"SendDocumentFormApprovalRequest/",department);
  }

  changeDocumentFormApprovalStatus(department:DocumentFormApprovalOperation):Observable<DocumentFormApprovalOperation>{
    return this.httpClient.post<DocumentFormApprovalOperation>(this.baseApiUrl+"ChangeDocumentFormApproval/",department);
  }

  changeDocumentFormAdminApprovalRequirementStatus(department:DocumentForm):Observable<DocumentForm>{
    return this.httpClient.post<DocumentForm>(this.baseApiUrl+"ChangeDocumentFormAdminApprovalRequirementStatus/",department);
  }

}
